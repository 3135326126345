import { useEffect } from "react";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { useForm } from "react-hook-form";
import { useReducer } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { message, notification } from "antd";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useNavigate } from "react-router-dom";

const AddBulkStudents = () => {
  const AuthState = useAuthState();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddBulkStudent": {
        return {
          ...state,
          submitAddBulkStudent: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddBulkStudent: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const [AddBulkStudentSuccess, AddBulkStudentErrors, AddBulkStudentLoading] =
    useAxios(
      process.env.REACT_APP_ADD_BULK_STUDENTS_API,
      "POST",
      state.submitAddBulkStudent.flag,
      state.submitAddBulkStudent.dependency,
      state.submitAddBulkStudent.data,
      false
    );

  useEffect(() => {
    if (AddBulkStudentSuccess) {
      message.success(AddBulkStudentSuccess?.message);
      navigate(-1);
    }
  }, [AddBulkStudentSuccess]);

  useEffect(() => {
    if (AddBulkStudentErrors) {
      api.error({
        message: "Errors",
        duration: null,
        description: AddBulkStudentErrors?.response?.data?.data?.map(
          (record, index) => (
            <div className="border-b-2 border-secondary pb-4 mb-4" key={index}>
              <p className="font-bold">{record.title}</p>
              <ul className="ml-2">
                {record.errors?.map((error, i) => (
                  <li key={i}>
                    {i + 1}: {error}
                  </li>
                ))}
              </ul>
            </div>
          )
        ),
      });
    }
  }, [AddBulkStudentErrors, api]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    dispatch({
      type: "setSubmitAddBulkStudent",
      payload: {
        flag: "SubmitAddBulkStudent",
        dependency: !state.submitAddBulkStudent.dependency,
        data: { excel_file: data.excel_file[0] },
      },
    });
  };

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      {contextHolder}
      <HelmetTags title="Add Bulk Students | Mr.Ahmed Harouny" />

      <div className="w-full flex justify-between items-center gap-5 mb-7">
        <h2 className=" text-start text-2xl font-bold">Add Bulk Students</h2>
        <a
          href="https://api.sciencetoonz.com/uploads/assets/addstudents.xlsx"
          className=" rounded-lg border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-bg transition-all duration-300 hover:bg-bg hover:text-secondary active:scale-90 "
        >
          Download Templete
        </a>
      </div>

      <form
        encType="multipart/form-data"
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-3/4 mx-auto flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50 my-6"
      >
        {/**  Proexcel_file Picture  */}
        <div className="w-full flex flex-col items-center">
          <input
            id="excel_file"
            className="signin-inputs  w-full"
            type="file"
            accept=".xlsx"
            name="excel_file"
            {...register("excel_file", {
              required: true,
              accept: ".xlsx",
              validate: (value) => !(value[0]?.size > 50000000),
            })}
          />

          {errors.excel_file && (
            <p className="pt-4 text-xs text-red-500">
              {errors.excel_file.type === "required" &&
                "This field is required."}
              {errors.excel_file.type === "validate" &&
                "Max Image Size is 50MB."}
            </p>
          )}
          {
            //!-------Editing server errors -----

            AddBulkStudentErrors?.response?.data?.errors &&
              AddBulkStudentErrors?.response?.data?.errors?.excel_file && (
                <p className="pt-4 text-xs text-red-500">
                  {AddBulkStudentErrors?.response?.data.errors.excel_file[0]}
                </p>
              )
          }
        </div>

        {/** Submit Button */}

        <button
          disabled={
            !isValid ||
            (state.submitAddBulkStudent.flag && AddBulkStudentLoading)
          }
          className=" submit mt-6 w-full "
          type="submit"
        >
          {state.submitAddBulkStudent.flag && AddBulkStudentLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            "Add Bulk Studnts"
          )}
        </button>
      </form>
    </section>
  );
};

export default AddBulkStudents;
