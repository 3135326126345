import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";
import HelmetTags from "../../../../MainComponents/HelmetTags";

const AddQuestionBankUnit = ({ edit }) => {
  const [values, setValues] = useState(null);
  const { courseID, unitID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditSession": {
        return {
          ...state,
          submitAddEditSession: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditSession: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  // change edit url
  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_UNTIS_API
    : process.env.REACT_APP_ADD_QUESTION_UNIT_API;

  //!--------- add edit Course -------
  const [UnitAddEditSuccess, SessionAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditSession.flag,
    state.submitAddEditSession.dependency,
    state.submitAddEditSession.data,
    true
  );

  //!--------- get the Course info for editing -------

  const [UnitInfo, UnitInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_GET_ALL_UNTIS_API}/${unitID}`,
    "GET",
    unitID,
    unitID
  );

  useEffect(() => {
    if (UnitInfo) {
      let temp = UnitInfo.data;
      setValues({
        ...temp,
      });
    }
  }, [UnitInfo]);

  useEffect(() => {
    //!---add actions ----

    if (UnitAddEditSuccess) {
      navigate(-1);
      reset();
    }
  }, [UnitAddEditSuccess]);

  const navigate = useNavigate();

  const defaultValues = UnitInfo?.data;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
    values: defaultValues,
  });

  const onSubmit = (data) => {
    const finalData = {
      ...data,
      id: unitID,
      visibility: data.visibility === true ? 1 : 0,
      course_id: courseID,
    };

    dispatch({
      type: "setSubmitAddEditSession",
      payload: {
        flag: "AddEditSession",
        dependency: !state.submitAddEditSession.dependency,
        data: finalData,
      },
    });
  };
  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Add Unit | Mr.Ahmed Harouny"></HelmetTags>{" "}
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-10 text-center text-2xl font-semibold">
          {edit ? "Edit Unit" : "Add Unit"}
        </h2>

        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          method="post"
          className="flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
        >
          {/** Name filed */}

          <div className="name flex flex-col items-start justify-center  gap-2  w-full">
            <label htmlFor="name">Name</label>
            <input
              className="signin-inputs"
              type="text"
              id="name"
              placeholder="Name"
              name="name"
              autoComplete="on"
              {...register("name", {
                required: true,
                //pattern: /^[A-Za-z]+$/,
                maxLength: 80,
                minLength: 3,
              })}
            />
            {errors.name && (
              <p className=" pt-2 text-xs text-red-500  ">
                {errors.name.type === "required" && "This field is required."}
                {errors.name.type === "pattern" &&
                  "Only English letters are allowed."}
                {errors.name.type === "maxLength" &&
                  "Allowed Name max length is 80."}
                {errors.name.type === "minLength" &&
                  "Please enter at least 3 letters."}
              </p>
            )}

            {
              //!-------server errors -----

              SessionAddEditErrors &&
                SessionAddEditErrors?.response?.data?.errors?.name && (
                  <p className=" pt-2 text-xs text-red-500  ">
                    {SessionAddEditErrors?.response?.data?.errors?.name[0]}
                  </p>
                )
            }
          </div>

          {/** submit */}

          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditSession.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditSession.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>{edit ? "Confirm Editing The Unit" : "Add The Unit"}</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default AddQuestionBankUnit;
