const QuestionModalAnswer = ({
  collapse,
  onCloseCollapse,
  activeQuestionId,
  question,
}) => {
  const correctAnswer = question?.correct_answer;
  const studentAnswer = question?.student_answer;

  if (activeQuestionId === null) {
    return null;
  }

  return (
    <div
      className={`w-full single-question bg-red-200 px-[30px] pb-[40px] rounded-3xl`}
    >
      <div className="current-question flex flex-row">
        {`${activeQuestionId + 1}.`}
        <div
          dangerouslySetInnerHTML={{
            __html: question?.question,
          }}
        />
      </div>
      <div className="input-container response-question-input flex flex-col items-end"></div>
      <div className="response-question-answers">
        {question?.answers?.map((answer, i) => (
          <div
            key={i}
            className={`${
              correctAnswer !== studentAnswer &&
              answer?.order === studentAnswer &&
              "response-selected"
            }
            ${
              studentAnswer === correctAnswer &&
              answer?.order === correctAnswer &&
              "bg-lime-500 border-lime-500"
            }
            ${
              answer?.order === correctAnswer && "bg-lime-500 border-lime-500"
            } `}
          >
            <span>{answer?.order}</span>
            <p dangerouslySetInnerHTML={{ __html: answer?.answer }} />
          </div>
        ))}
      </div>

      {question?.hint && (
        <>
          <div className="duration-300 ease-in-out mt-8">
            <hr />
            <div
              dangerouslySetInnerHTML={{
                __html: question?.hint,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default QuestionModalAnswer;
