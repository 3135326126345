import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useMemo, useState } from "react";

const Question = ({ state, dispatch, homework, exam }) => {
  const [showExplanation, setShowExplanation] = useState(false);
  const { ID, courseID } = useParams();

  const updateAnswerPreviousHomework =
    process.env.REACT_APP_STUDENT_PREVIOUS_HOMEWORK_UPDATE_API;

  const checkApi = homework
    ? process.env.REACT_APP_STUDENT_HOMEWORK_UPDATE_API
    : exam
    ? process.env.REACT_APP_STUDENT_TRIAL_EXAM_UPDATE_API
    : process.env.REACT_APP_STUDENT_QUIZZ_UPDATE_API;

  const api = courseID ? updateAnswerPreviousHomework : checkApi;

  const handleSelectAnswer = (answer, questionId, n) => {
    dispatch({
      type: "setAnsweredQuestions",
      payload: { id: questionId, order: answer, n },
    });
    async function fetchData() {
      try {
        let res = await axios.post(api, {
          id: ID,
          question_id: questionId,
          order: answer,
          n,
          course_id: state.quizInfo.course_id,
        });
      } catch (error) {
        console.error("Error:", error);
        toast.error(error.response.data.message);
      }
    }
    fetchData();

    dispatch({
      type: "setIsSelectedAnswer",
      payload: { questionId, answer, n },
    });
  };

  const handleShowExplanation = () => {
    setShowExplanation((show) => !show);
  };

  return (
    <div className="container max-w-full">
      <div className="current-question  flex-col !items-start">
        <p className="text-black">{`Question ${
          state.currentQuestionIndex + 1
        }`}</p>

        <div
          className="w-full"
          dangerouslySetInnerHTML={{
            __html: state.questions[state.currentQuestionIndex]?.question,
          }}
        />
      </div>

      <div className="current-question-degree flex justify-end text-black">
        <p> {state.questions[state.currentQuestionIndex]?.degree} Degree</p>
      </div>

      <div className="current-question-answers ">
        {state.questions[state.currentQuestionIndex]?.answers.map(
          (answer, i) => (
            <div
              key={i}
              className={`!border-black ${
                state.questions[state.currentQuestionIndex].selected_answer ===
                  answer.order && "selected"
              }`}
              onClick={() =>
                handleSelectAnswer(
                  answer.order,
                  state.questions[state.currentQuestionIndex].id,
                  state.questions[state.currentQuestionIndex].n
                )
              }
            >
              <span className="text-black !border-black">{answer.order}</span>
              <p
                dangerouslySetInnerHTML={{ __html: answer.answer }}
                className="text-black"
              />
            </div>
          )
        )}
      </div>

      {/* {state.questions[state.currentQuestionIndex]?.hint && (
        <div className="explanation-wrapper  flex flex-col items-center justify-start gap-[20px]">
          <button
            onClick={handleShowExplanation}
            className="explanation-btn mt-[28px] rounded-md border-2 border-secondary p-[4px] text-center font-bold duration-200 hover:scale-110"
          >
            Explanation
          </button>
          <div
            className={` ${
              showExplanation ? "" : "h-0"
            } duration-300 ease-in-out`}
          >
            <div
              className={` ${
                showExplanation ? "hide-explanation-text" : "explanation-text"
              } h-[110px] origin-top  overflow-y-auto px-[16px] duration-300 md:text-center`}
              dangerouslySetInnerHTML={{
                __html: state.questions[state.currentQuestionIndex]?.hint,
              }}
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Question;
