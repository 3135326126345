import "./SASS/styles.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { MainLoader, NotFound, Layout } from "./MainComponents";

import {
  AdminLayout,
  Students,
  AddStudent,
  AddBulkStudents,
  EditStudent,
  DeletedStudents,
  AdminCourse,
  AdminCourses,
  AdminAddEditCourses,
  AddEditMaterial,
  AddEditVideo,
  AddEditChooseQuestion,
  AddEditEssayQuestion,
  AdminQuizHomework,
  QuestionBank,
  AddEditCategory,
  Assistants,
  Analytics,
  QuestionBankCategory,
  AddEditUnit,
  AddEditLeason,
  AdminProfile,
  AdminClasses,
  AdminCorrection,
  QuestionBankSubCategory,
  QuestionBankExams,
  QuestionBankExamQuestion,
  QuestionBankAddExam,
  AddQuestionBankUnit,
  AddQuestionBankTitle,
  AddQuestionBankQuestionNumber,
  AddQuestionBankYear,
  AddQuestionBankLesson,
  QuestionBankShowDetails,
  QuestionBankExamAllCurrentQuestion,
  AdminCourseViewResults,
  AdminSubjects,
  AdminAddEditSubject,
  AdminViewCourse,
  AdminAddViewCourseStudent,
} from "./Pages/Admin";
import {
  SingleCourse,
  QuizNew,
  ResetPassword,
  SignIn,
  Profile,
  StudentCourses,
  StudentAllCourses,
} from "./Pages/Student";
import ProtectedAdminRoutes from "./MainComponents/ProtectionComponents/ProtectedAdminRoutes.jsx";
import AddEditAssistant from "./Pages/Admin/Dashboard/Assistants/AddEditAssistant";
import { useAuthState } from "./MainComponents/GlobalContext";
import ModalAnswer from "./Pages/modalAnswer/ModalAnswer";
import ViewStudents from "./Pages/Admin/Dashboard/Students/ViewStudents.jsx";

import PrivacyPolicyPage from "./Pages/LandingPage/PrivacyPolicyPage.jsx";
import LandingPage from "./Pages/LandingPage/LandingPage";
import AboutUsPage from "./Pages/LandingPage/AboutUsPage.jsx";
import ErrorBoundary from "./MainComponents/ErrorBoundary.jsx";

function App() {
  const AuthState = useAuthState();

  const router = createBrowserRouter([
    //!--------Landing Page Layout--------
    {
      path: "/",
      element: (
        <ProtectedAdminRoutes
          redirectPath={`${
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
              ? "/admin/content"
              : "/my-courses"
          }`}
          isAllowed={!AuthState.userData}
        >
          <LandingPage />
        </ProtectedAdminRoutes>
      ),
    },
    {
      path: "/about-us",
      element: <AboutUsPage />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicyPage />,
    },
    //!--------Student Layout--------
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath="/signin"
          isAllowed={AuthState.userData?.student_token}
        >
          <Layout />
        </ProtectedAdminRoutes>
      ),
      children: [
        {
          path: "/my-courses",
          element: <StudentCourses />,
        },
        {
          path: "/home/modal-answer/:contentID",
          element: <ModalAnswer />,
        },

        {
          path: "/courses/:courseID",
          element: <SingleCourse />,
        },

        {
          path: "/all-courses",
          element: <StudentAllCourses />,
        },

        //! -------Profile pages --------
        {
          path: "/home/profile/",
          element: <Profile />,
        },

        //! -------Quiz page--------

        {
          path: "/home/quiz/:ID",
          element: <QuizNew />,
        },
        {
          path: "/home/homework/:ID",
          element: <QuizNew homework />,
        },
        {
          path: "/home/previous-homework/:courseID",
          element: <QuizNew homework />,
        },
      ],
    },

    //! -------Admin Layout--------

    {
      element: (
        <ProtectedAdminRoutes
          redirectPath="/not-found"
          isAllowed={
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
          }
        >
          <AdminLayout />{" "}
        </ProtectedAdminRoutes>
      ),
      children: [
        //! ------- Whatsapp Routes -------
        // {
        //   element: (
        //     <ProtectedAdminRoutes
        //       redirectPath="/not-found"
        //       isAllowed={
        //         AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        //         AuthState.userData?.admin?.permissions?.some((permission) =>
        //           permission.name.startsWith("send_whatsapp")
        //         )
        //       }
        //     />
        //   ),
        //   children: [
        //     {
        //       path: "/admin/whatsapp",
        //       element: <Whatsapp />,
        //     },
        //   ],
        // },

        //! -------Students Routes--------
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students",
              element: <Students />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students/add-student",
              element: <AddStudent />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students/add-bulk-students",
              element: <AddBulkStudents />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students/edit-student/:studentID",
              element: <EditStudent />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students/view-student/:studentID",
              element: <ViewStudents />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("request_accept")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students/deleted",
              element: <DeletedStudents />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("request_accept")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/profile",
              element: <AdminProfile />,
            },
          ],
        },
        //!----- Question Bank Routes -------------

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("questionbank")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank",
              element: <QuestionBank />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith(
                    "questionbank_viewquestionbycategory"
                  )
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank/sub-catoegory/:categoryID",
              element: <QuestionBankSubCategory />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith(
                    "questionbank_viewquestionbycategory"
                  )
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank/sub-catoegory/:categoryID/add",
              element: <AddEditCategory subCategory />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith(
                    "questionbank_viewquestionbycategory"
                  )
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank/:categoryID/edit",
              element: <AddEditCategory edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith(
                    "questionbank_viewquestionbycategory"
                  )
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank/sub-category/:categoryID/:subCategoryID",
              element: <QuestionBankCategory />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("questionbank_addcategory")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank/add-category",
              element: <AddEditCategory />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("questionbank_addquestion")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank/:categoryID/add-essay-question",
              element: <AddEditEssayQuestion />,
            },
            {
              path: "/admin/question-bank/add-choose-question",
              element: <AddEditChooseQuestion />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("questionbank_exams")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank/exams",
              element: <QuestionBankExams />,
            },
            {
              path: "/admin/question-bank/:examID/exam-questions",
              element: <QuestionBankExamQuestion />,
            },
            {
              path: "/admin/question-bank/:examID/add-fixed-question",
              element: <AddEditChooseQuestion exam />,
            },
            {
              path: "/admin/question-bank/:examID/all-current-questions",
              element: <QuestionBankExamAllCurrentQuestion />,
            },

            {
              path: "/admin/question-bank/add-exam",
              element: <QuestionBankAddExam />,
            },
            {
              path: "/admin/question-bank/edit-exam/:examID",
              element: <QuestionBankAddExam edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("questionbank_addcategory")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank/add-choose-question/add-unit",
              element: <AddQuestionBankUnit />,
            },
            {
              path: "/admin/question-bank/add-choose-question/add-title",
              element: <AddQuestionBankTitle />,
            },
            {
              path: "/admin/question-bank/add-choose-question/edit-title/:titleID",
              element: <AddQuestionBankTitle edit />,
            },
            {
              path: "/admin/question-bank/add-choose-question/add-question-number",
              element: <AddQuestionBankQuestionNumber />,
            },
            {
              path: "/admin/question-bank/add-choose-question/add-year",
              element: <AddQuestionBankYear />,
            },
            {
              path: "/admin/question-bank/add-choose-question/add-lesson",
              element: <AddQuestionBankLesson />,
            },
            {
              path: "/admin/question-bank/add-choose-question/edit-lesson/:lessonID",
              element: <AddQuestionBankLesson edit />,
            },
            {
              path: "/admin/question-bank/all-units",
              element: <QuestionBankShowDetails type="units" />,
            },
            {
              path: "/admin/question-bank/all-lessons",
              element: <QuestionBankShowDetails type="lessons" />,
            },
            {
              path: "/admin/question-bank/all-titles",
              element: <QuestionBankShowDetails type="titles" />,
            },
            {
              path: "/admin/question-bank/all-years",
              element: <QuestionBankShowDetails type="years" />,
            },
            {
              path: "/admin/question-bank/all-question-number",
              element: <QuestionBankShowDetails type="question-number" />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("questionbank_editquestion")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank/edit-essay-question/:questionID",
              element: <AddEditEssayQuestion edit />,
            },
            {
              path: "/admin/question-bank/edit-choose-question/:questionID",
              element: <AddEditChooseQuestion edit />,
            },
          ],
        },
        //!----- Courses Routes -------------
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("content_courses_view")
                )
              }
            />
          ),
          errorElement: <ErrorBoundary />,
          children: [
            {
              path: "/admin/content",
              element: <AdminCourses />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("content_courses_view")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/content/:courseID",
              element: <AdminCourse />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("content_courses_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/content/add-course",
              element: <AdminAddEditCourses />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("content_courses_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/content/edit-course/:courseID",
              element: <AdminAddEditCourses edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/content/view-course/:courseID",
              element: <AdminViewCourse />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/content/view-course/:courseID/add-student",
              element: <AdminAddViewCourseStudent />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("content_courses_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/content/:courseID/add-unit",
              element: <AddEditUnit />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("content_courses_view")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/content/view-resutls/:ID",
              element: <AdminCourseViewResults />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("content_courses_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/content/:courseID/edit-unit/:unitID",
              element: <AddEditUnit edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("content_courses_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/content/add-leason/:unitID",
              element: <AddEditLeason />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("content_courses_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/content/edit-leason/:unitID/:leasonID",
              element: <AddEditLeason edit />,
            },
          ],
        },

        //!----- Sessions Routes -------------
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("content_courses_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/content/attachment/:materialID",
              element: <AddEditMaterial />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("content_courses_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/content/video/:videoID",
              element: <AddEditVideo />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("content_courses_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/content/quiz/:ID",
              element: <AdminQuizHomework />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("content_courses_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/content/homework/:ID",
              element: <AdminQuizHomework homework />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("questionbank_editquestion")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/content/homework/:homeworkID/edit-essay-question/:questionID",
              element: <AddEditEssayQuestion edit />,
            },
            {
              path: "/admin/content/homework/:homeworkID/edit-choose-question/:questionID",
              element: <AddEditChooseQuestion edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("questionbank_editquestion")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/content/quiz/:quizID/edit-essay-question/:questionID",
              element: <AddEditEssayQuestion edit />,
            },
            {
              path: "/admin/content/quiz/:quizID/edit-choose-question/:questionID",
              element: <AddEditChooseQuestion edit />,
            },
          ],
        },

        // students courses
        // {
        //   element: (
        //     <ProtectedAdminRoutes
        //       redirectPath="/not-found"
        //       isAllowed={
        //         AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        //         AuthState.userData?.admin?.permissions?.some((permission) =>
        //           permission.name.startsWith("courses_view")
        //         )
        //       }
        //     />
        //   ),
        //   children: [
        //     {
        //       path: "/admin/student-courses",
        //       element: <AdminStudentCourses />,
        //     },
        //   ],
        // },
        // {
        //   element: (
        //     <ProtectedAdminRoutes
        //       redirectPath="/not-found"
        //       isAllowed={
        //         AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        //         AuthState.userData?.admin?.permissions?.some((permission) =>
        //           permission.name.startsWith("courses_add")
        //         )
        //       }
        //     />
        //   ),
        //   children: [
        //     {
        //       path: "/admin/student-courses/add",
        //       element: <AdminStudentAddCourses />,
        //     },
        //   ],
        // },

        //!------ Subjects Route ---
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/subjects",
              element: <AdminSubjects />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/subjects/add-subject",
              element: <AdminAddEditSubject />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/content/edit-subject/:subjectID",
              element: <AdminAddEditSubject edit />,
            },
          ],
        },

        //!---- Assistants route ----
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin"
              }
            />
          ),
          children: [
            {
              path: "/admin/assistants",
              element: <Assistants />,
            },
            {
              path: "/admin/assistants/add-assistant",
              element: <AddEditAssistant />,
            },
            {
              path: "/admin/assistants/edit-assistant/:assistantID",
              element: <AddEditAssistant edit />,
            },
          ],
        },
        //!------- classes routes
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              // isAllowed={AuthState.userData?.admin?.permissions?.some(
              //   (permission) => permission.name.startsWith("class")
              // )}
              isAllowed={true}
            />
          ),
          children: [
            {
              path: "/admin/classes",
              element: <AdminClasses />,
            },
          ],
        },
        //!------- AdminCorrection routes
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name !== "superadmin"
              }
            />
          ),
          children: [
            {
              path: "/admin/correction",
              element: <AdminCorrection />,
            },
          ],
        },
        //!---- Analytics route ----
        // {
        //   element: (
        //     <ProtectedAdminRoutes
        //       redirectPath="/not-found"
        //       isAllowed={
        //         AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        //         AuthState.userData?.admin?.permissions?.some((permission) =>
        //           permission.name.startsWith("analytics")
        //         )
        //       }
        //     />
        //   ),
        //   children: [
        //     {
        //       path: "/admin/analytics",
        //       element: <Analytics />,
        //     },
        //   ],
        // },
      ],
    },
    //!SignIn-routes
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath={`${
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
              ? "/admin/content"
              : "/my-courses"
          }`}
          isAllowed={!AuthState.userData}
        />
      ),
      children: [
        //!SignIn
        {
          path: "/signin",
          element: <SignIn />,
        },
        {
          path: "/admin/signin",
          element: <SignIn admin />,
        },

        //! -------reset password page--------
        {
          path: "/reset_password",
          element: <ResetPassword />,
        },
      ],
    },

    //!NotFound
    {
      path: "/not-found",
      element: <NotFound />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<MainLoader />} />;
}

export default App;
