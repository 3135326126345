import Register from "../../../Student/Register/Register.jsx";

function AddStudent() {
  return (
    <section className="dark:bg-dark dark:text-light h-fit w-full overflow-y-auto px-10 p-10 sm:px-4 flex flex-col items-start amd-hero:items-center justify-start ">
      <h2 className="mb-4 text-start sm:text-center text-2xl font-bold">
        Add Student
      </h2>

      <div className=" h-fit w-full flex flex-col items-center justify-start my-4 ">
        <Register />
      </div>
    </section>
  );
}

export default AddStudent;
