import { Link } from "react-router-dom";

function Hero() {
  return (
    <section className="pb-6 pt-12 text-fontBlack md:text-center">
      <div className="gap-x-12 items-center justify-between overflow-hidden flex lg:flex-col lg:gap-y-6 md:px-8">
        <div className="pl-14 md:pl-0 flex-none space-y-5 px-4 sm:max-w-lg md:px-0 max-w-xl">
          <h1 className="text-gray-800 font-extrabold text-4xl">
            Welcome To sciencetoonz
          </h1>
          <p>
            Our core specialty is to provide engaging, comprehensive and
            adequate materials for IGCSE students in science subjects
          </p>
          <div className="items-center gap-x-3 flex md:justify-center">
            <Link
              to="/signin"
              className="block py-2 px-4 text-center text-white font-medium bg-[#bd13ec] duration-150 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg shadow-lg hover:shadow-none"
            >
              Let's get started
            </Link>
          </div>
        </div>
        <div className="flex-none md:mt-0 max-w-xl">
          <img
            src="../assets/heroImage.jpg"
            className=" rounded-tl-[108px] h-[450px] object-cover"
            alt=""
          />
        </div>
      </div>
    </section>
  );
}

export default Hero;
