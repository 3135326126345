//!visibility is available in edit only not in add
import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AddEditLeason({ edit }) {
  const [values, setValues] = useState(null);
  const { unitID, leasonID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditLeason": {
        return {
          ...state,
          submitAddEditLeason: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditLeason: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_LEASON_API
    : process.env.REACT_APP_ADMIN_ADD_LEASON_API;

  //!--------- add edit Course -------
  const [LeasonAddEditSuccess, LeasonAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditLeason.flag,
    state.submitAddEditLeason.dependency,
    state.submitAddEditLeason.data,
    true
  );

  //!--------- get the Course info for editing -------

  const [UnitInfo, LeasonInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_GET_LEASON_API}/${leasonID}`,
    "GET",
    leasonID,
    leasonID
  );

  useEffect(() => {
    if (UnitInfo) {
      let temp = UnitInfo.data;
      delete temp.img;
      setValues({ ...temp, visibility: temp.visibility === 1 ? true : false });
    }
  }, [UnitInfo]);

  useEffect(() => {
    //!---add actions ----

    if (LeasonAddEditSuccess) {
      navigate(-1);
      reset();
    }
  }, [LeasonAddEditSuccess]);

  const navigate = useNavigate();

  const defaultValues = UnitInfo?.data;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
    values: defaultValues,
  });

  const onSubmit = (data) => {
    const finalData = unitID
      ? {
          ...data,
          id: edit ? leasonID : unitID,
          visibility: data.visibility ? 1 : 0,
          description: data?.description || null,
        }
      : {
          ...data,
          id: edit ? leasonID : unitID,
          visibility: data.visibility ? 1 : 0,
          description: data.description || null,
        };

    dispatch({
      type: "setSubmitAddEditLeason",
      payload: {
        flag: "AddEditSession",
        dependency: !state.submitAddEditLeason.dependency,
        data: finalData,
      },
    });
  };

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Courses | Mr.Ahmed Harouny"></HelmetTags>{" "}
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-10 text-center text-2xl font-semibold">
          {edit ? "Edit Leason" : "Add Leason"}
        </h2>

        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          method="post"
          className="flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
        >
          {/** Name filed */}

          <div className="name flex flex-col items-start justify-center  gap-2  w-full">
            <label htmlFor="name">Name</label>
            <input
              className="signin-inputs"
              type="text"
              id="name"
              placeholder="Name"
              name="name"
              autoComplete="on"
              {...register("name", {
                required: true,
                //pattern: /^[A-Za-z]+$/,
                maxLength: 80,
                minLength: 3,
              })}
            />
            {errors.name && (
              <p className=" pt-2 text-xs text-red-500  ">
                {errors.name.type === "required" && "This field is required."}
                {errors.name.type === "pattern" &&
                  "Only English letters are allowed."}
                {errors.name.type === "maxLength" &&
                  "Allowed Name max length is 80."}
                {errors.name.type === "minLength" &&
                  "Please enter at least 3 letters."}
              </p>
            )}

            {
              //!-------server errors -----

              LeasonAddEditErrors &&
                LeasonAddEditErrors?.response?.data?.errors?.name && (
                  <p className=" pt-2 text-xs text-red-500  ">
                    {LeasonAddEditErrors?.response?.data?.errors?.name[0]}
                  </p>
                )
            }
          </div>

          {edit && (
            <div className=" visibility flex w-1/2 flex-col items-start justify-center gap-2 md:w-full  h-full">
              <div className="flex w-full items-center gap-10">
                <label className="visibility-switch">
                  <input
                    id="visibility"
                    name="visibility"
                    {...register("visibility", {
                      required: false,
                    })}
                    type="checkbox"
                  />
                  <span className="visibility-slider">
                    <svg
                      className="slider-icon"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="m4 16.5 8 8 16-16"></path>
                    </svg>
                  </span>
                </label>
                <div>
                  <label
                    className="w-full cursor-pointer truncate"
                    htmlFor="visibility"
                  >
                    Visibility
                  </label>
                </div>
              </div>

              {errors.visibility && (
                <p className="pt-2 text-xs text-red-500 ">
                  {errors.visibility.type === "required" &&
                    "This field is required."}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                LeasonAddEditErrors &&
                  LeasonAddEditErrors?.response?.data?.errors?.visibility && (
                    <p className=" pt-2 text-xs text-red-500  ">
                      {
                        LeasonAddEditErrors?.response?.data?.errors
                          ?.visibility[0]
                      }
                    </p>
                  )
              }
            </div>
          )}
          {/** submit */}

          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditLeason.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditLeason.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>{edit ? "Confirm Editing The Leason" : "Add The Leason"}</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditLeason;
