import { useState, useEffect } from "react";
import useAxios from "../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../MainComponents/HelmetTags";

import Loader from "../../../MainComponents/Loader.jsx";

import StudentCoursesCard from "./components/StudentCoursesCard.jsx";

function StudentAllCourses({ teacher }) {
  const [coursesData, setCoursesData] = useState([]);
  const [name, setName] = useState("all");
  const [allCoursesData, allCoursesErrors, loading] = useAxios(
    process.env.REACT_APP_STUDENT_ALL_COURSES_API,
    "GET",
    "GET"
  );

  const handelSetFilter = (name) => {
    if (name === "all") {
      setCoursesData(allCoursesData?.data.all_courses);
    } else {
      setCoursesData(
        allCoursesData.data.all_courses.filter(
          (course) => course.subject === name
        )
      );
    }
  };

  useEffect(() => {
    setCoursesData(allCoursesData?.data.all_courses);
  }, [allCoursesData?.data]);

  if (loading) return <Loader />;

  return (
    <section className="h-auto width">
      <HelmetTags title={`All Courses | Mr.Ahmed Harouny`} />

      <div
        className={`filters-container bg-light-gray mt-10 flex justify-center flex-wrap `}
      >
        <div
          onClick={() => {
            handelSetFilter("all");
            setName("all");
          }}
          className={`${
            name === "all" && "bg-green"
          } w-1/5 capitalize py-4 flex justify-center items-center h-full cursor-pointer z-10 font-semibold md:h-16 md:min-h-[64px] md:w-20 `}
        >
          All
        </div>
        {allCoursesData?.data.all_subjects.map((subject) => (
          <div
            key={subject.key}
            onClick={() => {
              handelSetFilter(subject.name);
              setName(subject.name);
            }}
            className={`${
              name === subject.name && "bg-green"
            } w-1/5 capitalize py-4 flex justify-center items-center h-full cursor-pointer z-10 font-semibold md:h-16 md:min-h-[64px] md:w-20`}
          >
            {subject.name}
          </div>
        ))}
      </div>

      <h1 className="course-name text-center text-3xl font-bold mt-12 capitalize">
        {name} Courses
      </h1>

      <div
        className={`courses grid-auto-fit width ${
          teacher && "sss:justify-items-start"
        } mt-10 `}
      >
        {coursesData?.map((course, index) => (
          <StudentCoursesCard
            key={index}
            course={course}
            teacher={teacher}
            allCourses
          />
        ))}
        {coursesData?.length === 0 && <p>There are No Courses Yet.</p>}
      </div>
    </section>
  );
}

export default StudentAllCourses;
