import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

function AdminAddEditCourses({ edit }) {
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);
  const { courseID } = useParams();
  const [values, setValues] = useState(null);

  const navigate = useNavigate();

  const [GetCourseData, GetCourseDataErrors, GetCourseDataLoading] = useAxios(
    process.env.REACT_APP_ADMIN_SUBJECTS_TAB_API,
    "GET",
    "GET",
    "",
    ""
  );

  const [GetCourseInfo, GetCourseInfoErrors, GetCourseInfoLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_GET_STUDENT_COURSE_INFO_API}/${courseID}`,
    "GET",
    "GET",
    "",
    ""
  );

  useEffect(() => {
    if (GetCourseInfo) setValues(GetCourseInfo?.data);
  }, [GetCourseInfo]);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  const [AddCourseSuccess, AddCourseErrors, AddCoureLoading] = useAxios(
    edit
      ? process.env.REACT_APP_ADMIN_EDIT_STUDENT_COURSE_API
      : process.env.REACT_APP_ADMIN_ADD_STUDENT_COURSE_API,
    "POST",
    formFlag,
    formDependency,
    formValues
  );

  const onSubmit = (formData) => {
    const finalData = edit
      ? {
          ...formData,
          id: courseID,
          visibility: formData?.visibility ? 1 : 0,
          img: formData?.img[0] || null,
        }
      : {
          ...formData,
          visibility: formData?.visibility ? 1 : 0,
          img: formData?.img[0] || null,
        };
    setFormValues(finalData);
    setFormFlag("EditAssistantname");
    setFormDependency(true);
  };

  useEffect(() => {
    if (AddCourseSuccess) {
      toast.success("Created Successfully");
      setFormFlag("");
      setFormDependency(false);
      reset();
      navigate(-1);
    }
  }, [AddCourseSuccess, navigate, reset]);

  useEffect(() => {
    if (AddCourseErrors) {
      toast.error(AddCourseErrors.message);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [AddCourseErrors]);

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      <HelmetTags
        title={`${edit ? "Edit " : "Add "} Course | Mr.Ahmed Harouny`}
      />
      <div className="w-full flex justify-between items-center gap-5 mb-7">
        <h2 className=" text-start text-2xl font-bold ">
          {edit ? "Edit " : "Add "} Course
        </h2>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-center items-start  bg-white p-16  rounded-3xl shadow-lg shadow-blue/50 w-3/4 my-6 mx-auto gap-6"
      >
        {/** Name  */}

        <div className="w-full">
          <div className="relative flex flex-col justify-center items-start gap-2 ">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              className="signin-inputs w-full"
              type="name"
              placeholder="name"
              name="name"
              autoComplete="on"
              {...register("name", {
                required: true,
                maxLength: 255,
                minLength: 3,
              })}
            />
          </div>

          {errors.name && (
            <p className="text-red-500 text-xs pt-4">
              {errors.name.type === "required" && "This field is required."}
              {errors.name.type === "maxLength" &&
                "name Max length is 25 char."}
              {errors.name.type === "minLength" && "name Min length is 6 char."}
            </p>
          )}
        </div>

        <div className="flex w-full flex-col items-start justify-center gap-2">
          <label htmlFor="subject_id">Subject</label>
          <select
            name="subject_id"
            id="subject_id"
            defaultValue=""
            {...register("subject_id", { required: true })}
          >
            <option value="" disabled>
              Select Subject
            </option>
            {GetCourseData?.data.map((subject) => (
              <option value={subject.key}>{subject.name}</option>
            ))}
          </select>

          {errors.subject_id && (
            <p className="text-xs text-red-500 ">
              {errors.subject_id.value === "required" &&
                "This field is required."}
            </p>
          )}
          {
            //!-------server errors -----

            AddCourseErrors &&
              AddCourseErrors?.response?.data?.errors?.subject_id && (
                <p className="w-full text-end text-xs text-red-500  ">
                  {AddCourseErrors?.response?.data?.errors?.subject_id[0]}
                </p>
              )
          }
        </div>

        {edit && (
          <div className=" visibility flex  flex-col items-start justify-center gap-1 ">
            <div className="flex w-full  items-center justify-center gap-5 ">
              <label className="visibility-switch w-full ">
                <input
                  className="w-full"
                  id="visibility"
                  name="visibility"
                  type="checkbox"
                  // checked={state.editFormVisibility === 1}
                  {...register("visibility")}
                />
                <span className="visibility-slider w-full">
                  <svg
                    className="slider-icon"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path fill="none" d="m4 16.5 8 8 16-16"></path>
                  </svg>
                </span>
              </label>
              <div className="sm:hidden">
                <label
                  className="!w-fit cursor-pointer truncate"
                  htmlFor="visibility"
                >
                  Visibility{" "}
                </label>
              </div>
            </div>

            {
              //!-------server errors -----

              AddCourseErrors &&
                AddCourseErrors?.response?.data?.errors?.visibility && (
                  <p className=" text-xs text-red-500  z-20">
                    {AddCourseErrors?.response?.data?.errors?.visibility[0]}
                  </p>
                )
            }
          </div>
        )}

        <div className="relative flex flex-col justify-center items-start gap-2 w-full ">
          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            id="description"
            className="w-full"
            rows="5"
            {...register("description", {
              required: false,
              maxLength: 400,
              minLength: 3,
            })}
          ></textarea>
        </div>

        <div className="flex w-full flex-col items-start gap-2">
          <label className="w-full text-start " htmlFor="img">
            Course Image
          </label>

          <input
            id="img"
            className="signin-inputs w-full"
            type="file"
            accept="image/jpg, image/jpeg, image/png, image/webp"
            name="img"
            {...register("img", {
              required: edit ? false : true,
              validate: (value) => !(value[0]?.size > 5000000),
            })}
          />

          {errors.img && (
            <p className="mt-2 text-xs text-red-500">
              {errors.img.type === "required" && " Add a profile picture"}
              {errors.img.type === "validate" && "max size is 5 mb"}
            </p>
          )}
          {
            //!-------server errors -----
            AddCourseErrors && AddCourseErrors?.response?.data?.errors?.img && (
              <p className="text-xs text-red-500  ">
                {AddCourseErrors?.response?.data?.errors?.img[0]}
              </p>
            )
          }
        </div>

        <button disabled={AddCoureLoading} className="submit">
          {AddCoureLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            `${edit ? "Edit " : "Add "} Course`
          )}
        </button>
      </form>
    </section>
  );
}

export default AdminAddEditCourses;
