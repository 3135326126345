import { Link, useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthState } from "../../../../MainComponents/GlobalContext";

function AdminViewCourse() {
  const AuthState = useAuthState();
  const { courseID } = useParams();
  const table = useTable("viewCourse", "", "", "", "", courseID);

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title="View Course | Mr.Ahmed Harouny"></HelmetTags>{" "}
      <div className="w-full flex justify-between items-center gap-5 mb-7">
        <h2 className="mb-7 text-2xl font-bold">View Course</h2>{" "}
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("")
          )) && (
          <Link
            to={`/admin/content/view-course/${courseID}/add-student`}
            className="text-white mb-8 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add Student
          </Link>
        )}
      </div>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default AdminViewCourse;
