import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Select } from "antd";
import useAxios from "../../../../../MainComponents/Hooks/useAxios.jsx";
import AddEditChooseQuestion from "../../QuestionBank/AddEditChooseQuestion.jsx";
import AddEditEssayQuestion from "../../QuestionBank/AddEditEssayQuestion.jsx";
import QuestionBankCategory from "../../QuestionBank/QuestionBankCategory.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import AddSetsForm from "./AddSetsForm.jsx";
import SetsTable from "./SetsTable.jsx";
import HelmetTags from "../../../../../MainComponents/HelmetTags.jsx";
import { useAuthState } from "../../../../../MainComponents/GlobalContext.jsx";
import Loader from "../../../../../MainComponents/Loader.jsx";

function AdminQuizHomework({ homework, exam }) {
  const AuthState = useAuthState();
  const { ID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditInfo": {
        return {
          ...state,
          submitAddEditInfo: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditInfo: {
      flag: "",
      dependency: false,
      data: {},
    },
    info: "",
  });
  const [values, setValues] = useState({});
  const [refetch, setRefetch] = useState(false);
  const [questionType, setQuestionType] = useState("choose");
  const [selectedExams, setSelectedExams] = useState([]);

  //!--------- get quiz / homework info for checking if there is a quiz or a homework has set previously  -------

  let infoApi = exam
    ? `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_ALL_INFO_API}${ID}}`
    : `${process.env.REACT_APP_ADMIN_CONTENT_INFO_API}${ID}`;
  const [getInfoSuccess, getInfoErrors, getInfoLoading] = useAxios(
    infoApi,
    "GET",
    "GET",
    refetch
  );

  // get all exams
  const [AllExamsData, AllExamsDataErrors, AllExamsLoading] = useAxios(
    process.env.REACT_APP_GET_ALL_EXAMS_QUESTION_BANK_API,
    "GET",
    "GET",
    ""
  );

  useEffect(() => {
    if (getInfoSuccess?.is_found === 1) {
      if (homework) {
        // setQuestionType(
        //   getInfoSuccess?.data?.Questions[0]?.is_essay === 1
        //     ? "essay"
        //     : "choose"
        // );
        const { title, v_model, success_rate, type } =
          getInfoSuccess?.data?.Homework_info[0];
        setValues({
          title,
          v_model: v_model === 1 ? true : false,
          success_rate,
          type,
        });
      } else {
        // setQuestionType(
        //   getInfoSuccess?.data?.Questions[0]?.is_essay === 1
        //     ? "essay"
        //     : "choose"
        // );
        const { duration, v_model, success_rate, shuffle_answers } =
          getInfoSuccess?.data?.quiz_info[0];
        setValues({
          duration,
          v_model: v_model === 1 ? true : false,
          success_rate,
          shuffle_answers,
        });
      }
    }
  }, [getInfoSuccess, homework]);

  //!--------- add edit quiz / homework info  -------

  let addEditInfoApi =
    homework && getInfoSuccess?.is_found === 1
      ? process.env.REACT_APP_ADMIN_CONTENT_EDIT_HOMEWORK_API
      : homework && getInfoSuccess?.is_found === undefined
      ? process.env.REACT_APP_ADMIN_CONTENT_ADD_HOMEWORK_API
      : getInfoSuccess?.is_found === 1 && getInfoSuccess?.type === "quiz"
      ? process.env.REACT_APP_ADMIN_CONTENT_EDIT_QUIZ_API
      : getInfoSuccess?.is_found === 0 && getInfoSuccess?.type === "quiz"
      ? process.env.REACT_APP_ADMIN_CONTENT_ADD_QUIZ_API
      : "";

  const [AddEditInfoSuccess, AddEditInfoErrors, AddEditInfoLoading] = useAxios(
    addEditInfoApi,
    "POST",
    state.submitAddEditInfo.flag,
    state.submitAddEditInfo.dependency,
    state.submitAddEditInfo.data,
    true
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
    values,
  });

  useEffect(() => {
    //!---add actions ----

    if (AddEditInfoSuccess) {
      setRefetch(!refetch);
    }
  }, [AddEditInfoSuccess]);

  function onSubmitAddEditInfo(data) {
    if (homework) {
      const { title, v_model, success_rate, type } = data;
      let finalData =
        getInfoSuccess?.is_found === 1
          ? {
              homework_id: getInfoSuccess?.data?.Homework_info[0]?.key,
              title,
              // v_model: v_model === true ? 1 : 0,
              success_rate,
              type: 0,
            }
          : getInfoSuccess?.is_found === undefined
          ? { content_id: ID, ...data }
          : {};
      dispatch({
        type: "setSubmitAddEditInfo",
        payload: {
          flag: "Quiz",
          dependency: !state.submitAddEditInfo.dependency,
          data: finalData,
        },
      });
    } else {
      const { duration, v_model, success_rate, shuffle_answers } = data;
      let finalData =
        getInfoSuccess?.is_found === 1
          ? {
              quiz_id: getInfoSuccess?.data?.quiz_info[0]?.key,
              duration,
              // v_model: v_model === true ? 1 : 0,
              success_rate,
              shuffle_answers,
              exam: selectedExams,
            }
          : getInfoSuccess?.is_found === 0
          ? { content_id: Number(ID), exam: selectedExams, ...data }
          : {};
      dispatch({
        type: "setSubmitAddEditInfo",
        payload: {
          flag: "Quiz",
          dependency: !state.submitAddEditInfo.dependency,
          data: finalData,
        },
      });
    }
  }

  return (
    <section className="flex h-auto w-full flex-col items-end gap-16 overflow-y-auto px-10 py-16 dark:bg-dark dark:text-light sm:px-4">
      <HelmetTags title="Sessions | Mr.Ahmed Harouny"></HelmetTags>{" "}
      {AddEditInfoLoading || (getInfoLoading && <Loader />)}
      <div className="add-quiz-homework flex w-full flex-col items-center gap-14 md:items-center">
        {getInfoSuccess && !exam && (
          <h2 className="w-full text-center text-xl font-semibold">
            {getInfoSuccess?.is_found === 1 && homework
              ? "Edit Homework"
              : getInfoSuccess?.is_found === 1
              ? "Edit Quiz"
              : homework
              ? "Add Homework"
              : "Add Quiz"}
          </h2>
        )}
        {/**
         * //!--- add exam duration or homework name -------
         */}
        {!exam &&
          (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            (homework &&
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("lecture_homeworks_permissions")
              )) ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture_quizzes_permissions")
            )) && (
            <form
              onSubmit={handleSubmit(onSubmitAddEditInfo)}
              method="post"
              className="flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
            >
              <div className="flex flex-col w-full items-center justify-evenly gap-10 md:flex-col md:gap-10 ">
                {homework ? (
                  <>
                    <div className="title flex w-full md:w-full flex-col items-start  justify-center  gap-2">
                      <label htmlFor="title">Name</label>
                      <input
                        className="signin-inputs w-full     "
                        type="text"
                        id="title"
                        placeholder="Name"
                        title="title"
                        autoComplete="on"
                        {...register("title", {
                          required: true,
                          //pattern: /^[A-Za-z]+$/,
                          maxLength: 255,
                          minLength: 3,
                        })}
                      />
                      {errors.title && (
                        <p className="text-xs text-red-500 ">
                          {errors.title.type === "required" &&
                            "This field is required."}
                          {errors.title.type === "pattern" &&
                            "Only English Letters are Allowed."}
                          {errors.title.type === "maxLength" &&
                            "Allowed Name Max Length is 255."}
                          {errors.title.type === "minLength" &&
                            "Please Enter at Least 3 Letters."}
                        </p>
                      )}

                      {
                        //!-------server errors -----

                        AddEditInfoErrors &&
                          AddEditInfoErrors?.response?.data?.errors?.title && (
                            <p className="text-xs text-red-500">
                              {
                                AddEditInfoErrors?.response?.data?.errors
                                  ?.title[0]
                              }
                            </p>
                          )
                      }
                    </div>
                    {/* 
                    <div className="title flex w-full md:w-full flex-col items-start  justify-center  gap-2">
                      <label htmlFor="type">Type</label>
                      <select
                        name="type"
                        id="type"
                        {...register("type", { required: true })}
                      >
                        <option value="0">PDF</option>
                        <option value="1">Choose</option>
                      </select>

                      {errors.type && (
                        <p className="text-xs text-red-500 ">
                          {errors.type.type === "required" &&
                            "This field is required."}
                        </p>
                      )}
                      {
                        //!-------server errors -----

                        AddEditInfoErrors &&
                          AddEditInfoErrors?.response?.data?.errors?.type && (
                            <p className="w-full text-end text-xs text-red-500  ">
                              {
                                AddEditInfoErrors?.response?.data?.errors
                                  ?.type[0]
                              }
                            </p>
                          )
                      }
                    </div> */}
                  </>
                ) : (
                  <div className="flex w-full flex-col items-start justify-center gap-2 md:order-1 md:w-full">
                    <label htmlFor="exam">Exam</label>

                    {getInfoSuccess && AllExamsData?.data ? (
                      <Select
                        mode="multiple"
                        placeholder="Please Select Exam"
                        defaultValue={
                          getInfoSuccess?.data?.exams.length > 0
                            ? getInfoSuccess?.data?.exams
                            : []
                        }
                        onChange={(value) => {
                          setSelectedExams((currentExams) => [...value]);
                        }}
                        className="w-full"
                      >
                        {AllExamsData?.data?.map((exam) => (
                          <Select.Option key={exam.key} value={exam.key}>
                            {exam.name}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : null}

                    {errors.exam && (
                      <p examName="text-xs text-red-500 ">
                        {errors.exam.exam === "required" &&
                          "This field is required."}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      AddEditInfoErrors &&
                        AddEditInfoErrors?.response?.data?.errors?.exam && (
                          <p className="w-full text-end text-xs text-red-500  ">
                            {AddEditInfoErrors?.response?.data?.errors?.exam[0]}
                          </p>
                        )
                    }
                  </div>
                )}
                {/*                 
                <div
                  //!--success_rate--
                  className=" success_rate flex  w-full flex-col items-start justify-center gap-2 md:w-full"
                >
                  <label className="w-fit truncate" htmlFor="success_rate">
                    Success Rate
                  </label>
                  <input
                    className="signin-inputs w-full "
                    type="number"
                    min={1}
                    inputMode="numeric"
                    id="success_rate"
                    max={100}
                    placeholder="Success Rate"
                    name="success_rate"
                    autoComplete="on"
                    {...register("success_rate", {
                      valueAsNumber: true,
                      min: 1,
                      required: true,
                    })}
                  />

                  {errors.success_rate && (
                    <>
                      <p className="text-xs text-red-500">
                        {errors.success_rate?.type === "required" &&
                          "This field is required."}{" "}
                        {errors.success_rate?.type === "min" &&
                          "Minimum Number is one."}{" "}
                      </p>
                    </>
                  )}
                  {
                    //!-------server errors -----

                    AddEditInfoErrors &&
                      AddEditInfoErrors?.response?.data?.errors?.duration && (
                        <p
                          className="text-xs text-red-500
  "
                        >
                          {
                            AddEditInfoErrors?.response?.data?.errors
                              ?.duration[0]
                          }
                        </p>
                      )
                  }
                </div> */}
              </div>

              {/** submit */}

              <button
                disabled={
                  !isValid ||
                  (AddEditInfoLoading && state.submitAddEditInfo.flag)
                }
                className=" submit mt-6 w-full "
                type="submit"
              >
                {AddEditInfoLoading && state.submitAddEditInfo.flag ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <p>
                    {" "}
                    {getInfoSuccess?.is_found === 1 && homework
                      ? "Edit Homework"
                      : getInfoSuccess?.is_found === 1
                      ? "Edit Quiz"
                      : homework
                      ? "Add Homework"
                      : "Add Quiz"}
                  </p>
                )}
              </button>
            </form>
          )}
      </div>
      {getInfoSuccess?.is_found === 1 && homework ? (
        <>
          {/* {!getInfoSuccess?.data?.Questions[0]?.is_essay ? (
            <>
              <div className="category-questions flex w-full flex-col items-center gap-14 md:items-center ">
                <h2 className="text-center text-xl font-semibold">
                  Choose Questions from Categories
                </h2>

                <AddSetsForm
                  type={homework ? "homework" : exam ? "exam" : "quiz"}
                  id={ID}
                  setRefetch={setRefetch}
                  refetch={refetch}
                />
              </div>
              {getInfoSuccess?.data?.sets?.length > 0 && (
                <div className="category-questions flex w-full flex-col items-center gap-2 md:items-center ">
                  <h2 className="text-center text-xl font-semibold">
                    All Sets Information{" "}
                  </h2>
                  {getInfoSuccess?.data?.sets?.length > 0 && (
                    <SetsTable
                      id={
                        homework
                          ? ID
                          : exam
                          ? getInfoSuccess?.data?.quiz_info[0]?.key
                          : ID
                      }
                      tableFor={exam ? "examSets" : "sets"}
                    />
                  )}
                </div>
              )}
            </>
          ) : null} */}

          <AddEditEssayQuestion
            type={homework ? "homework" : exam ? "exam" : "quiz"}
            id={ID}
            setRefetch={setRefetch}
            refetch={refetch}
          />

          {getInfoSuccess?.data?.Questions?.length > 0 && (
            <div className="all-custom-questions w-full ">
              <h2 className="text-center text-xl font-semibold">
                Custom Questions
              </h2>
              <QuestionBankCategory
                type={homework ? "homework" : "quiz"}
                ID={ID}
                QuizQuestionsData={getInfoSuccess?.data?.Questions}
                setRefetchQuiz={setRefetch}
                refetchQuiz={refetch}
              />
            </div>
          )}
        </>
      ) : null}
    </section>
  );
}

export default AdminQuizHomework;
