import {
  faBookOpenReader,
  faChartLine,
  faChevronDown,
  faClipboardUser,
  faComment,
  faEye,
  faEyeSlash,
  faShieldHalved,
  faUserGraduate,
  faUserPlus,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import AssistantsInformations from "./AssistantsInformations";
import AssistantsChangePassword from "./AssistantsChangePassword";
import AssistantsChangeImage from "./AssistantsChangeImage";
import AssistatsChangePermissions from "./AssistatsChangePermissions";
import AddNewAssistant from "./AddNewAssistant";

function AddEditAssistant({ edit }) {
  const [values, setValues] = useState(null);
  const [filterActive, setFilterActive] = useState(1);
  const [left, setLeft] = useState("left-0");
  const { assistantID } = useParams();

  //!--------- get the assistant info for editing -------

  const [assistantInfo, assistantInfoErrors] = useAxios(
    `${process.env.REACT_APP_ASSISTANT_INFO_API}${assistantID}`,
    "GET",
    assistantID,
    assistantID
  );

  useEffect(() => {
    if (assistantInfo) {
      let temp = assistantInfo.data;
      // delete temp.profile_pic;
      setValues(temp);
    }
  }, [assistantInfo]);

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Assistants | Mr.Ahmed Harouny"></HelmetTags>

      <div className="form-container my-20 flex w-full flex-col items-center">
        {edit && (
          <>
            <div
              className={`filters flex justify-between  items-center w-1/2 md:w-full md:flex-col md:px-6 h-12 relative mb-14 transition-all duration-200 ease-in-out`}
            >
              <button
                onClick={() => setFilterActive(1)}
                className={`z-10 h-full w-1/4 text-base font-semibold md:w-full ${
                  filterActive === 1 && "rounded-md bg-green duration-300"
                } `}
              >
                Information
              </button>
              <button
                onClick={() => setFilterActive(2)}
                className={`z-10 h-full w-1/4 text-base font-semibold  md:w-full ${
                  filterActive === 2 && "rounded-md bg-green duration-300"
                } `}
              >
                Password
              </button>
              <button
                onClick={() => setFilterActive(3)}
                className={`z-10 h-full w-1/4 text-base font-semibold  md:w-full ${
                  filterActive === 3 && "rounded-md bg-green duration-300"
                } `}
              >
                Photo
              </button>
              <button
                onClick={() => setFilterActive(4)}
                className={`z-10 h-full w-1/4 text-base font-semibold md:w-full  ${
                  filterActive === 4 && "rounded-md bg-green duration-300"
                } `}
              >
                Permissions
              </button>
            </div>

            {filterActive === 1 && <AssistantsInformations values={values} />}

            {filterActive === 2 && <AssistantsChangePassword />}

            {filterActive === 3 && (
              <AssistantsChangeImage imgSrc={assistantInfo?.data.profile_pic} />
            )}
            {filterActive === 4 && <AssistatsChangePermissions />}
          </>
        )}

        {!edit && <AddNewAssistant />}
      </div>
    </section>
  );
}

export default AddEditAssistant;
