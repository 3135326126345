import {
  faFacebookF,
  faInstagram,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCopyright,
  faEnvelope,
  faLocation,
  faMap,
  faMapLocation,
  faMapMarked,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer
      id="About"
      className="bg-blue flex-col gap-10 items-center  justify-center mt-16 pt-16  px-16 "
    >
      <div className=" flex justify-between gap-10  md:flex-col ">
        <div className="flex flex-col gap-4 items-start w-1/4 md:w-full md:items-center flex-1">
          <a href="#top">
            <img
              className="h-[55px] w-auto cursor-pointer "
              src="/assets/logo.png"
              alt="logo"
            />
          </a>
        </div>

        <div className="flex flex-col gap-3 flex-1">
          <h3 className="text-white font-bold text-xl w-full border-b-2 border-white ">
            Contact Us
          </h3>
          <a
            href="tel:+971557783814"
            className="flex items-center gap-2 text-white"
          >
            <FontAwesomeIcon icon={faPhone} />
            +971557783814
          </a>
          <a
            href="mailto:elharoni@hotmail.com"
            className="flex items-center gap-2 text-white"
          >
            <FontAwesomeIcon icon={faEnvelope} />
            elharoni@hotmail.com
          </a>
        </div>

        <div className="flex flex-col gap-3 flex-1">
          <h3 className="text-white font-bold text-xl w-full border-b-2 border-white ">
            Location
          </h3>
          <p className="flex gap-2 text-white">
            <FontAwesomeIcon icon={faMapMarked} />
            SPC Free Zone, E311, Sheikh Mohammed Bin Zayed Rd, <br /> Al Zahia,
            Sharjah, U.A.E.
          </p>
        </div>
        <div className="flex flex-col gap-3 flex-1">
          <h3 className="text-white font-bold text-xl w-full border-b-2 border-white ">
            Links
          </h3>

          <Link
            to="/privacy-policy"
            className="flex items-center gap-2 text-white underline"
          >
            Privacy Policy
          </Link>
        </div>
      </div>

      <p className="text-center p-6 mt-8 text-white border-t-[4px] border-white">
        Created By{" "}
        <a
          className="font-bold "
          target="_blank"
          href="https://api.whatsapp.com/send?phone=201124265941"
          rel="noreferrer"
        >
          Omar Fathy{" "}
        </a>
        | <FontAwesomeIcon icon={faCopyright} /> 2024 All Rights Reserved.
      </p>
    </footer>
  );
}

export default Footer;
