import { useAuthState } from "../../../../MainComponents/GlobalContext";
import HelmetTags from "../../../../MainComponents/HelmetTags";

import useTable from "../../../../MainComponents/Hooks/useTable";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

function AdminSubjects() {
  const AuthState = useAuthState();
  const table = useTable("subjects", "", "");

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      <HelmetTags title="Subjects | Mr.Ahmed Harouny" />
      <h2 className=" text-start text-2xl font-bold  mb-7">Subjects</h2>

      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("")
        )) && (
        <Link
          to="/admin/subjects/add-subject"
          className="text-white mb-8 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
        >
          <FontAwesomeIcon className="mr-4" icon={faPlus} />
          Add Subject
        </Link>
      )}

      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default AdminSubjects;
