import { Link, useNavigate } from "react-router-dom";
import HelmetTags from "../../../MainComponents/HelmetTags.jsx";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthDispatch } from "../../../MainComponents/GlobalContext.jsx";
import axios from "axios";
import { toast } from "react-hot-toast";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

export default function SignIn({ admin }) {
  const AuthDispatch = useAuthDispatch();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState(null);
  const [signedInSuccess, setSignedInSuccess] = useState(null);

  useEffect(() => {
    if (signedInSuccess) {
      navigate(`${admin ? "/admin/content" : "/my-courses"}`, {
        replace: true,
      });
    }
  }, [signedInSuccess]);

  const navigate = useNavigate();
  const [hide, setHide] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    setSubmitLoading(true);
    async function postData() {
      try {
        let res = await axios.post(
          admin
            ? process.env.REACT_APP_ADMIN_LOGIN_API
            : process.env.REACT_APP_LOGIN_API,

          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        let userData = await res.data;

        setSubmitLoading(false);
        localStorage.setItem("userData", JSON.stringify(userData));
        AuthDispatch({ type: "setUserData", payload: userData });
        toast.success(userData.message);
        reset();
        setSignedInSuccess(true);
      } catch (error) {
        setSubmitLoading(false);
        if (error.response && error.response.data) {
          setServerErrors(error.response.data.error);
          toast.error(error.response.data.error);
        } else {
          setServerErrors("An error occurred");
          toast.error("An error occurred");
        }
      }
    }
    postData();
  };
  return (
    <section className="flex h-screen flex-col items-center justify-center gap-12 md:gap-6">
      <HelmetTags title="Sign In | Mr.Ahmed Harouny" index>
        <link rel="canonical" href="https://our-matrix.com/signin" />
      </HelmetTags>
      <div className="flex flex-col items-center justify-center gap-4">
        <img
          className="h-[55px] w-auto cursor-pointer "
          src="https://api.sciencetoonz.com/uploads/ST%20Transparent.png"
          alt="logo"
          onClick={() => navigate("/")}
        />
        <h1 className="text-2xl font-bold">Sign in</h1>
        <p className="text-base opacity-80">
          Welcome back! Please enter your information.
        </p>
      </div>
      <form
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        className="mx-auto flex w-[450px] xs:w-72 flex-col  items-start justify-center  rounded-3xl bg-white p-16 shadow-lg shadow-blue/50"
      >
        {/**
         * //!Username filed
         *
         */}
        {admin ? (
          <div className=" flex w-full flex-col items-start justify-center gap-2">
            <label htmlFor="username"> Username</label>
            <input
              className="signin-inputs max-w-60"
              type="text"
              id="username"
              placeholder="Username"
              name="username"
              autoComplete="on"
              {...register("username", {
                required: true,
                pattern: /^[A-Za-z0-9]*$/,
                maxLength: 40,
                minLength: 2,
              })}
            />
            {errors.username && (
              <p className="pt-2 text-xs text-red-500">
                {errors.username.type === "required" &&
                  "This field is required."}
                {errors.username.type === "pattern" &&
                  " Only letters and numbers are allowed."}
                {errors.username.type === "maxLength" &&
                  "Allowed username Max Length is 40."}
                {errors.username.type === "minLength" &&
                  "Please enter at least 3 letters."}
              </p>
            )}
          </div>
        ) : (
          <div className=" flex w-full flex-col items-start justify-center gap-2">
            <label htmlFor="email"> Email</label>

            <input
              className="signin-inputs  w-full "
              type="text"
              id="email"
              placeholder="Email"
              name="email"
              autoComplete="on"
              {...register("email", {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
            />
            {errors.email && (
              <p className="pt-2 text-xs text-red-500">
                {errors.email.type === "required" && "This field is required."}
                {errors.email.type === "pattern" && " Invalid Email Address."}
              </p>
            )}
          </div>
        )}
        {/**
         * //!Password filed
         *  */}

        <div className="relative mt-10 mb-2 flex w-full flex-col items-start justify-center gap-2">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            className="signin-inputs max-w-60"
            type={`${hide ? "text" : "password"}`}
            placeholder="Password"
            autoComplete="on"
            name="password"
            {...register("password", {
              required: true,
              maxLength: 25,
              minLength: 6,
            })}
          />
          {hide ? (
            <FontAwesomeIcon
              onClick={() => setHide(!hide)}
              className="absolute right-2 bottom-2 h-4 w-4 cursor-pointer"
              icon={faEye}
            />
          ) : (
            <FontAwesomeIcon
              onClick={() => setHide(!hide)}
              className="absolute right-2 bottom-2 h-4 w-4 cursor-pointer"
              icon={faEyeSlash}
            />
          )}
        </div>
        {errors.password && (
          <p className="pb-4 text-xs text-red-500">
            {errors.password.type === "required" && "This field is required."}
            {errors.password.type === "maxLength" &&
              "Password Max length is 25 char."}
            {errors.password.type === "minLength" &&
              "Password Min length is 6 char."}
          </p>
        )}
        {
          //!--- server errors --------
          serverErrors && (
            <p className="pb-4 text-xs text-red-500">{serverErrors}</p>
          )
        }
        <Link className="text-start text-xs  underline " to="/reset_password">
          Forgot password?
        </Link>
        <button
          disabled={!isValid || submitLoading}
          className=" submit max-w-60 mt-10 "
          type="submit"
        >
          {submitLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            "Sign In"
          )}
        </button>
      </form>
    </section>
  );
}
