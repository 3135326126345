import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const QuestionBankSubCategory = () => {
  const { categoryID } = useParams();
  const table = useTable(
    "QuestionBankSubCategories",
    "",
    "",
    "",
    "",
    categoryID
  );
  const AuthState = useAuthState();

  return (
    <section className="h-full w-full overflow-y-auto px-10 py-10 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Question Bank Sub Category | Mr. Ahmed Harouny"></HelmetTags>{" "}
      <div className="mb-20 flex flex-col items-start gap-10">
        <h2 className="w-full text-start text-3xl font-bold">Sub Categories</h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("questionbank_addcategory")
          )) && (
          <Link
            to={`/admin/question-bank/sub-catoegory/${categoryID}/add`}
            className="text-white ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add New Sub category{" "}
          </Link>
        )}
      </div>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
};

export default QuestionBankSubCategory;
