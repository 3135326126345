import { Link, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import QuizTimer from "./QuizTimer";

const QuizHeader = ({
  state,
  dispatch,
  quizErrors,
  onFinishQuiz,
  quizLoading,
  exam,
  quizData,
}) => {
  const { ID } = useParams();

  return (
    <div>
      <header
        className={`w-full  ${
          state.isShowAnswers ? "h-[80px]" : "h-[128px]"
        } fixed inset-0 z-50 flex items-center bg-secondary text-[#fff] shadow-md shadow-secondary/40`}
      >
        <nav
          className={`nav-container width flex h-fit items-center gap-[20px] ${
            state.isShowAnswers ? "flex-row justify-between" : "flex-col"
          }`}
        >
          <div className="header-above  h-[32px] w-fit">
            <h4 className="exam-title font-bold">{state?.quizInfo?.title}</h4>
          </div>

          <div
            className={`header-underneath  flex w-full items-center justify-between gap-[20px] ${
              state.isShowAnswers && "pointer-events-none hidden"
            }`}
          >
            {state.isShowAnswers ||
            (state?.quizInfo?.duration &&
              quizData?.choosequestions.length > 0) ? (
              <QuizTimer
                duration={state.quizInfo.duration}
                state={state}
                onFinishQuiz={onFinishQuiz}
              />
            ) : null}
            <div className="flex w-1/2 justify-end md:w-fit">
              {!quizErrors && !quizLoading && (
                <div
                  onClick={() => dispatch({ type: "setCollapse" })}
                  className="collapse-btn hover:bg-   top-[96px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-accent text-[#fff] shadow-md  shadow-black/40 duration-300 hover:scale-110 hover:text-[#fff]"
                >
                  <FontAwesomeIcon
                    className={`transition-all duration-300 ease-in-out hover:scale-110 ${
                      state.collapse && "rotate-180"
                    }`}
                    icon={faChevronRight}
                  />
                </div>
              )}
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default QuizHeader;
