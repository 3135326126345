import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faSpinner } from "@fortawesome/free-solid-svg-icons";

const QuizFooter = ({ state, dispatch, onFinishQuiz }) => {
  return (
    <footer className="amd-hero:gap-[16px]  mt-[40px]  flex  w-full  items-center justify-between gap-[32px] rounded-lg bg-secondary p-[20px] ">
      <div className="numbering flex w-[160px]  justify-center gap-[4px] rounded-md bg-[#fff] p-[8px]  text-secondary md:w-[64px]">
        <span className="font-bold md:hidden">Question</span>
        <span className="current-question-number">
          {state.currentQuestionIndex + 1}
        </span>
        <span className="font-bold">From</span>
        <span className="questions-count">{state.questions.length}</span>
      </div>

      <div className="control-buttons flex justify-center gap-[20px]">
        <button
          onClick={() =>
            dispatch({
              type: "setCurrentQuestionIndex",
              payload: state.currentQuestionIndex - 1,
            })
          }
          disabled={state.currentQuestionIndex === 0 ? true : false}
          className="prev-btn flex w-[96px] justify-center rounded-md border-2 border-[#fff] py-[4px] text-center font-bold  text-[#fff] duration-200 hover:scale-110 active:scale-90 md:w-[48px] border-light"
        >
          <span className="md:hidden">Previous</span>
          <FontAwesomeIcon
            className="hidden rotate-180 text-[20px] font-bold text-light md:block"
            icon={faChevronRight}
          />
        </button>

        <button
          onClick={() =>
            dispatch({
              type: "setCurrentQuestionIndex",
              payload: state.currentQuestionIndex + 1,
            })
          }
          disabled={
            state.currentQuestionIndex === state.questions.length - 1
              ? true
              : false
          }
          className="next-btn flex w-[96px] justify-center rounded-md border-2 border-[#fff] bg-[#fff] py-[4px]  text-center font-bold text-secondary duration-200 hover:scale-110 active:scale-90 md:w-[48px]"
        >
          <span className="md:hidden">Next</span>

          <FontAwesomeIcon
            className="hidden text-[20px] font-bold md:block"
            icon={faChevronRight}
          />
        </button>
      </div>

      <button
        onClick={onFinishQuiz}
        disabled={state.sumbitLoading}
        className="submit-btn rounded-md disabled:opacity-50 border-light bg-green px-[16px] py-[8px] font-bold text-light duration-200 hover:scale-110 active:scale-90 "
      >
        {state.sumbitLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          "send"
        )}
      </button>
    </footer>
  );
};

export default QuizFooter;
