import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import Loader from "../../../../MainComponents/Loader.jsx";
import { Popconfirm } from "antd";

function QuestionBankCategory({
  type,
  QuizQuestionsData,
  refetchQuiz,
  setRefetchQuiz,
  ID,
}) {
  const AuthState = useAuthState();
  const [deleteId, setDeleteId] = useState("");
  const allQuestions = useRef(null);
  const [reFetch, setReFetch] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const navigate = useNavigate();
  const { subCategoryID } = useParams();

  //!--------- get all Questions -------
  const [allQuestionsData, allQuestionsErrors, loading] = useAxios(
    `${process.env.REACT_APP_CATEGORY_QUESTIONS_API}${subCategoryID}`,
    "GET",
    !type,
    reFetch
  );
  //!--------- delete Question -------

  const [deleteQuestionSuccess] = useAxios(
    process.env.REACT_APP_DELETE_QUESTION_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );

  useEffect(() => {
    if ((QuizQuestionsData?.length > 0 || allQuestionsData) && allQuestions) {
      QuizQuestionsData?.length > 0
        ? setFinalData(QuizQuestionsData)
        : setFinalData(allQuestionsData?.data);
    }
  }, [allQuestionsData, QuizQuestionsData, allQuestions]);

  useEffect(() => {
    //! refetch the data on success delete Question

    if (deleteQuestionSuccess) {
      type ? setRefetchQuiz(!refetchQuiz) : setReFetch(!reFetch);
      setDeleteId("");
    }
  }, [deleteQuestionSuccess]);

  function handleDelete(id) {
    setSubmitDelete({
      flag: "deleteQuestion",
      dependency: !submitDelete.dependency,
      data: { id },
    });
  }

  const handleNavigate = (question) => {
    if (question.is_essay) {
      if (type === "homework") {
        navigate(
          `/admin/content/homework/${ID}/edit-essay-question/${question.key}`,
          { state: { lectureQuestion: true } }
        );
      } else if (type === "quiz") {
        navigate(
          `/admin/content/quiz/${ID}/edit-essay-question/${question.key}`,
          {
            state: { lectureQuestion: true },
          }
        );
      } else {
        navigate(
          `/admin/question-bank/${subCategoryID}/edit-essay-question/${question.key}`
        );
      }
    } else {
      if (type === "homework") {
        navigate(
          `/admin/content/homework/${ID}/edit-choose-question/${question.key}`,
          { state: { lectureQuestion: true } }
        );
      } else if (type === "quiz") {
        navigate(
          `/admin/content/quiz/${ID}/edit-choose-question/${question.key}`,
          {
            state: { lectureQuestion: true },
          }
        );
      } else {
        navigate(
          `/admin/question-bank/${subCategoryID}/edit-choose-question/${question.key}`
        );
      }
    }
  };

  if (loading && !type) {
    return <Loader />;
  }

  return (
    <section
      className={`h-full w-full overflow-y-auto ${
        !type && "px-20"
      }  py-20 dark:bg-dark dark:text-light sm:px-10`}
    >
      {!type && (
        <HelmetTags title="Question Bank | Mr. Ahmed Harouny"></HelmetTags>
      )}

      {!type && (
        <h2 className="mb-16 w-full  text-4xl font-bold sm:text-center">
          Questions
        </h2>
      )}

      <div className="all-questions flex h-auto w-full flex-col gap-14 px-10 amd:px-0">
        {finalData?.map((question, index) => (
          <div
            key={index}
            className="singleQuestionWrapper rounded-3xl bg-white"
          >
            <div className="optionsQuestionWrapper">
              <div className="questionOptions">
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff" //okButtonProps={{ loading: loading }}
                  onConfirm={() => handleDelete(question.key)}
                  title="Delete The Question?"
                >
                  <button className="deleteQuestionBtn">Delete</button>
                </Popconfirm>
                {/* <button
                  onClick={() => handleNavigate(question)}
                  className="editQuestionBtn"
                >
                  Edit
                </button> */}
              </div>
              <div className="categoryQuestionWrapper">
                {question.question ? (
                  <p
                    className="essayTitleWrapper"
                    dangerouslySetInnerHTML={{
                      __html: question.question,
                    }}
                  />
                ) : (
                  <a href={question.url} target="_blank" rel="noreferrer">
                    {question.name}
                  </a>
                )}
              </div>
            </div>
            {question.is_essay ? (
              ""
            ) : (
              <div className="categoryAnswersWrapper">
                <div
                  className={`categorySingleAnswer answer1Wrapper ${
                    question.correct_answer === "answer1" &&
                    "correctSingleAnswer"
                  }`}
                >
                  <p>Answer 1</p>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: question.answer1,
                    }}
                  />
                </div>
                <div
                  className={`categorySingleAnswer answer2Wrapper ${
                    question.correct_answer === "answer2" &&
                    "correctSingleAnswer"
                  }`}
                >
                  <p>Answer 2</p>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: question.answer2,
                    }}
                  />
                </div>
                <div
                  className={`categorySingleAnswer answer3Wrapper ${
                    question.correct_answer === "answer3" &&
                    "correctSingleAnswer"
                  }`}
                >
                  <p>Answer 3</p>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: question.answer3,
                    }}
                  />
                </div>
                <div
                  className={`categorySingleAnswer answer4Wrapper ${
                    question.correct_answer === "answer4" &&
                    "correctSingleAnswer"
                  }`}
                >
                  <p>Answer 4</p>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: question.answer4,
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
        {!type &&
          allQuestionsErrors &&
          typeof allQuestionsErrors === "string" && (
            <p className="w-full text-center">{allQuestionsErrors}</p>
          )}
      </div>
    </section>
  );
}

export default QuestionBankCategory;
