import { useState } from "react";
import {
  faFilePdf,
  faFilePen,
  faInfinity,
  faLock,
  faMobile,
  faSpinner,
  faVideo,
  faFileLines,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { useEffect, useReducer, useRef } from "react";
import { useForm } from "react-hook-form";
import HelmetTags from "../../../MainComponents/HelmetTags";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import Loader from "../../../MainComponents/Loader";
import SingleCourseCountDown from "./components/SingleCourseCountDown";
import SessionCollapse from "./components/SessionCollapse";
import { Popconfirm } from "antd";
import VdoCiperrPlayer from "./vdoCipherComponents/VdoCiperrPlayer";
import useVdocipher from "./vdoCipherComponents/hooks/useVdocipher";
import ReactPlayer from "react-player";

function SessionType({
  src,
  currentVideo,
  dispatch,
  type,
  views,
  otp,
  playbackInfo,
  videoContainerRef,
  setVideoRef,
  loadVideo,
}) {
  const handleOpenVideo = () => {
    dispatch({
      type: "setPlatform",
      payload: type.platform,
    });
    dispatch({
      type: "setCurrentVideo",
      payload: type.id,
    });
    dispatch({
      type: "setVideoId",
      payload: type.video_id,
    });
    dispatch({
      type: "setSrc",
      payload: src,
    });
    dispatch({
      type: "setViews",
      payload: views,
    });
    dispatch({
      type: "setTogglePlaylist",
    });
    dispatch({
      type: "setCurrentVideoCounter",
      payload: 0,
    });

    // vdociper logic
    videoContainerRef.current.innerHTML = "";

    videoContainerRef.current.classList.add("haveVideo");
    const video = loadVideo({
      otp,
      playbackInfo,
      container: videoContainerRef.current,
      configuration: {
        // autoplay: true
      },
    });
    setVideoRef(video);
    // console.log("--------------", type.OTP, playbackInfo);
  };

  return (
    <button
      onClick={
        type.type === "video"
          ? () => {
              handleOpenVideo();
            }
          : null
      }
      className={`hover:bg-secondary hover:text-white transition-all duration-100 ease-in-out px-7 border-y-bg border-y-2 type-video flex justify-between items-center w-full h-10 ${
        currentVideo === type.id &&
        type.type === "video" &&
        " bg-secondary text-white"
      } ${type.is_open === 0 ? "opacity-[.5] !cursor-not-allowed" : ""}  `}
      disabled={type.is_open === 0}
    >
      <div className={`icon-title flex justify-start items-center gap-5 `}>
        <FontAwesomeIcon
          icon={
            type.type === "quiz"
              ? faFilePen
              : type.type === "video"
              ? faVideo
              : type.type === "attachment"
              ? faFilePdf
              : type.type === "homework"
              ? faFileLines
              : ""
          }
        />
        <p>{type.name}</p>
      </div>
      {type.is_open === 0 ? (
        ""
      ) : (
        <>
          {" "}
          {type.type === "quiz" ? (
            <Link
              to={`${
                type.is_take === 1
                  ? `/home/modal-answer/${type.id}`
                  : `/home/quiz/${type.quiz_id}`
              }`}
              className="session-cta underline underline-offset-4"
            >
              {type.is_take === 1 ? "Modal Answer" : "Take Quiz"}
            </Link>
          ) : type.type === "video" ? (
            <button className="session-cta underline underline-offset-4">
              Watch Video
            </button>
          ) : type.type === "attachment" ? (
            <a
              href={`${type.url}`}
              target="_blank"
              rel="noreferrer"
              className="session-cta underline underline-offset-4"
            >
              Download Attachment
            </a>
          ) : type.type === "homework" ? (
            <Link
              to={`${
                type.is_take === 1
                  ? `/home/modal-answer/${type.id}`
                  : `/home/homework/${type.homework_id}`
              }`}
              className="session-cta underline underline-offset-4"
            >
              {type.is_take === 1 ? "Modal Answer" : "Take Homework"}
            </Link>
          ) : (
            ""
          )}{" "}
        </>
      )}
    </button>
  );
}

function SingleCourse() {
  const { courseID } = useParams();
  function reducer(state, action) {
    switch (action.type) {
      case "setCollapse": {
        return {
          ...state,
          collapse: action.payload,
        };
      }
      case "setSrc": {
        return {
          ...state,
          src: action.payload,
        };
      }
      case "setCurrentVideoCounter": {
        return {
          ...state,
          currentVideoCounter: action.payload,
        };
      }
      case "setOtp": {
        return {
          ...state,
          otp: action.payload,
        };
      }
      case "setVideoId": {
        return {
          ...state,
          video_id: action.payload,
        };
      }
      case "setPlayBackInfo": {
        return {
          ...state,
          playbackInfo: action.payload,
        };
      }
      case "setViews": {
        return {
          ...state,
          views: action.payload,
        };
      }
      case "setCurrentVideo": {
        return {
          ...state,
          currentVideo: action.payload,
        };
      }
      case "setBuyLecture": {
        return {
          ...state,
          buyLecture: action.payload,
        };
      }
      case "setBuyLectureByOffer": {
        return {
          ...state,
          buyLectureByOffer: action.payload,
        };
      }
      case "setBuyLectureByEnroll": {
        return {
          ...state,
          buyLectureByEnroll: action.payload,
        };
      }
      case "setTryLectureByChance": {
        return {
          ...state,
          tryLectureByChance: action.payload,
        };
      }
      case "setActivateFreeCourse": {
        return {
          ...state,
          activateFreeCourse: action.payload,
        };
      }
      case "setRefetch": {
        return {
          ...state,
          refetch: action.payload,
        };
      }
      case "setTogglePlaylist": {
        return {
          ...state,
          togglePlaylist: !state.togglePlaylist,
        };
      }

      case "setExpireAt": {
        return {
          ...state,
          expireAt: action.payload,
        };
      }
      case "setPlatform": {
        return {
          ...state,
          platform: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    collapse: false,
    src: "",
    currentVideoCounter: 0,
    otp: "",
    platform: "",
    playbackInfo: "",
    refetch: false,
    currentVideo: "",
    historyId: "",
    video_id: "",
    togglePlaylist: false,
  });

  const [videoRef, setVideoRef] = useState(null);
  const videoContainerRef = useRef();
  const { loadVideo, isAPIReady } = useVdocipher();

  //!---- get course info -------
  const [LectureInfoSuccess, LectureInfoErrors, LectureInfoLoading] = useAxios(
    `${process.env.REACT_APP_STUDENT_COURSE_INFO_API}/${courseID}`,
    "GET",
    "GET",
    state.refetch
  );

  //! ---- send current video player counter ----
  const [currentVideoCounterInfo, setSubmitDeleteLesson] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [updateCurrentVideoCounterSuccess, updateCurrentVideoCounterErrors] =
    useAxios(
      process.env.REACT_APP_UPDATE_VIDEO_DURATION_API,
      "POST",
      currentVideoCounterInfo.flag,
      currentVideoCounterInfo.dependency,
      currentVideoCounterInfo.data,
      false
    );

  const [playStatus, setPlayStatus] = useState(false);

  const handlePlayVideo = (event) => {
    setPlayStatus(true);
  };
  const handlePauseVideo = (event) => {
    setPlayStatus(false);
  };

  // useEffect(() => {
  //   let timeoutUpdateVideoCounter;
  //   let updatedCounter = state.currentVideoCounter;

  //   if (state.src) {
  //     timeoutUpdateVideoCounter = setTimeout(() => {
  //       console.log("inside 0000", updatedCounter);

  //       setSubmitDeleteLesson({
  //         flag: "updateCurrentVideoCounter",
  //         dependency: !currentVideoCounterInfo.dependency,
  //         data: {
  //           video_id: state.video_id,
  //           counter: updatedCounter,
  //           status: playStatus,
  //         },
  //       });
  //     }, 20000);
  //   }

  //   console.log("outside, ", state.currentVideoCounter);

  //   return () => {
  //     clearTimeout(timeoutUpdateVideoCounter);
  //   };
  // }, [
  //   state.src,
  //   currentVideoCounterInfo.dependency,
  //   state.currentVideo,
  //   state.video_id,
  //   playStatus,
  //   state.currentVideoCounter,
  // ]);

  const [globalVideoCounter, setGlobalVideoCounter] = useState(0);

  useEffect(() => {
    let interval;
    if (state.src) {
      interval = setInterval(() => {
        // update video counter when video play
        if (playStatus) {
          dispatch({
            type: "setCurrentVideoCounter",
            payload: state.currentVideoCounter + 1,
          });
        }

        // update global counter
        setGlobalVideoCounter((prev) => prev + 1);

        // send request every 60 seconds
        if (globalVideoCounter % 60 === 1) {
          setSubmitDeleteLesson({
            flag: "updateCurrentVideoCounter",
            dependency: !currentVideoCounterInfo.dependency,
            data: {
              video_id: state.video_id,
              counter: state.currentVideoCounter,
              status: playStatus,
            },
          });
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [
    playStatus,
    state.currentVideoCounter,
    dispatch,
    globalVideoCounter,
    state.video_id,
    currentVideoCounterInfo.dependency,
    state.src,
  ]);

  // reset all counters when change video
  useEffect(() => {
    dispatch({
      type: "setCurrentVideoCounter",
      payload: 0,
    });
    setGlobalVideoCounter(0);
  }, [state.src]);

  if (LectureInfoLoading) {
    return <Loader />;
  }

  if (LectureInfoErrors && typeof LectureInfoErrors === "string") {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>{LectureInfoErrors}</p>
      </div>
    );
  }

  return (
    <section className="min-h-screen mt-10">
      <HelmetTags title={`My Courses | Mr.Ahmed Harouny`} />
      {/*bottom  playlist - video */}
      <div className="video-playlist  min-h-screen flex  justify-between items-center gap-10 lg:gap-16 md:flex-col md:items-center md:justify-center  ">
        <div
          className={`playlist absolute transition-all duration-300   md:w-full flex items-end flex-col ${
            state.togglePlaylist
              ? "w-[60px] overflow-hidden pointer-events-none"
              : "w-[400px] "
          }`}
        >
          <div
            onClick={() => dispatch({ type: "setTogglePlaylist" })}
            className="toggle-playlist-wrapper pointer-events-auto bg-delete text-white text-xl cursor-pointer flex justify-center items-center mb-2 w-10 h-10 "
          >
            <FontAwesomeIcon
              className={`transition-all duration-300 ${
                state.togglePlaylist ? "rotate-180 " : "rotate-0"
              }`}
              icon={faChevronLeft}
            />
          </div>
          <div
            className={`course-header-content relative transition-all duration-300  ${
              state.togglePlaylist
                ? "w-0 pointer-events-none border-none"
                : "w-full border-2 border-delete "
            }   overflow-y-auto h-[70vh] md:h-[70vh]`}
          >
            <div className="course-header bg-delete text-white p-4 shadow-lg w-full ">
              <h2 className="course-title md:text-center text-xl font-bold">
                Course Content
              </h2>
              <p className="course-details md:text-center mt-3 ">
                {LectureInfoSuccess?.data.length} Units
              </p>
            </div>
            <div
              className={`playlist course-content w-full  rounded-  overflow-hidden bg-bg  transition-{height} duration-500 ease-in-out `}
            >
              {LectureInfoSuccess?.data?.map((unit, index) => (
                <div
                  key={index}
                  className={`session-collapsed collapse-session transition-{height} duration-700 ease-in-out w-full `}
                >
                  {/**
                   * //!---session-title-wrapper-border-y ----
                   */}
                  <SessionCollapse unit={unit} />
                  {/**
                   * //!---session-content-----
                   */}
                  <div className="session-content w-full  transition-all duration-700 ease-in-out h-fit py-4">
                    {unit.lessons?.map((lesson) => {
                      return (
                        <div
                          className={`session-collapsed transition-{height} duration-700 ease-in-out w-full `}
                        >
                          <div className="collapse-session w-[90%] m-auto">
                            <SessionCollapse unit={lesson} isLesson={true} />
                            <div className="session-content w-full  transition-all duration-700 ease-in-out h-fit py-4">
                              {lesson.content.map((type) => {
                                let src;
                                if (type.type === "video") {
                                  src =
                                    type.platform === "youtube"
                                      ? `https://www.youtube.com/embed/${type.vdo_id}`
                                      : type.platform === "vimeo"
                                      ? `https://player.vimeo.com/video/${type.vdo_id}`
                                      : `https://player.vdocipher.com/v2/?otp=${type.OTP}&playbackInfo=${type.playbackInfo}`;
                                }
                                return (
                                  <SessionType
                                    key={type.id}
                                    src={src}
                                    otp={type.OTP}
                                    playbackInfo={type.playbackInfo}
                                    currentVideo={state.currentVideo}
                                    dispatch={dispatch}
                                    type={type}
                                    views={type.views}
                                    setVideoRef={setVideoRef}
                                    videoContainerRef={videoContainerRef}
                                    loadVideo={loadVideo}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className={`${
            state.src ? "flex" : "hidden"
          } flex-col w-full items-center justify-center gap-3`}
        >
          <div className={`flex video  width justify-end`}>
            <div
              className={`video transition-all duration-300  ${
                state.togglePlaylist
                  ? "w-full justify-center"
                  : "w-3/4 justify-end"
              }  md:w-full flex  md:justify-center `}
            >
              <div className="iframe-wrapper aspect-video w-9/12 md:w-full overflow-hidden rounded-xl border-2">
                {/* <VdoCiperrPlayer
                  videoId={state.currentVideo}
                  platform={state.platform}
                  // views={state.views}
                  history_id={
                    LectureInfoSuccess?.data?.course_details?.history_id
                  }
                  videoRef={videoRef}
                  videoContainerRef={videoContainerRef}
                  isAPIReady={isAPIReady}
                  setVideoRef={setVideoRef}
                /> */}
                {state.platform !== "vdocipher" && (
                  // <iframe
                  //   src={state.src}
                  //   width="100%"
                  //   height="100%"
                  //   title="YouTube video player"
                  //   frameBorder="0"
                  //   //style={{ aspectRatio: "16 / 9" }}
                  //   allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  //   allowFullScreen
                  //   id="courseVideo"
                  // ></iframe>
                  <ReactPlayer
                    url={state.src}
                    onPlay={handlePlayVideo}
                    onPause={handlePauseVideo}
                    width="100%"
                    height="100%"
                    controls
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SingleCourse;
