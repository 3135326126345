import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";

function AddEditEssayQuestion({
  edit,
  type,
  id,
  lectureQuestion,
  refetch,
  setRefetch,
}) {
  const { categoryID, questionID } = useParams();
  const [question, setQuestion] = useState();
  const [difficulty, setDifficulty] = useState("");
  const [vdo_id, setVideoId] = useState("");
  const [platform, setPlatform] = useState("");
  const [hint, setHint] = useState("");
  const [degree, setDegree] = useState("");
  const [errors, setErrors] = useState("");

  const navigate = useNavigate();
  const [submitQuestion, setSubmitQuestion] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!  reset error messages if the input value has changed
  useEffect(() => {
    if (question !== "") {
      // setErrors("");
    } else if (degree !== "") {
      setErrors("");
    } else if (difficulty !== "") {
      // setErrors("");
    }
  }, [question, difficulty, degree]);

  //!---add edit question request
  let api = type
    ? process.env.REACT_APP_ADMIN_QUIZ_ADD_ESSAY_QUESTIONS_API
    : edit
    ? process.env.REACT_APP_EDIT_QUESTION_API
    : `${process.env.REACT_APP_ADD_ESSAY_QUESTION_API}${categoryID}`;

  const [addEditQuestionSuccess, addEditQuestionErrors, submitLoading] =
    useAxios(
      api,
      "POST",
      submitQuestion.flag,
      submitQuestion.dependency,
      submitQuestion.data,
      true
    );

  //!---get question data request
  let QuestionDataApi = lectureQuestion
    ? `${process.env.REACT_APP_QUIZ_HOMEWORK_QUESTION_INFO_API}${questionID}`
    : `${process.env.REACT_APP_QUESTION_INFO_API}${questionID}`;
  const [QuestionDataSuccess, QuestionErrors] = useAxios(
    QuestionDataApi,
    "GET",
    edit
  );

  useEffect(() => {
    if (QuestionDataSuccess) {
      // setDifficulty(QuestionDataSuccess.data?.difficulty);
      // setQuestion(QuestionDataSuccess.data?.question);
      // setHint(QuestionDataSuccess.data?.hint);
      setDegree(QuestionDataSuccess.data?.degree);
    }
  }, [QuestionDataSuccess]);

  const { register, handleSubmit, formState, watch } = useForm({
    mode: "onChange",
    values: QuestionDataSuccess?.data,
  });

  const onSubmit = (data) => {
    const finalData = type
      ? {
          ...data,
          question: data.question[0],
          content_id: id,
        }
      : {
          ...data,
          question: data.question[0],
          id: edit ? questionID : categoryID,
        };
    setSubmitQuestion({
      flag: "addEditQuestionRequest",
      dependency: !submitQuestion.dependency,
      data: finalData,
    });
  };
  useEffect(() => {
    //! reset the form on success submit

    if (addEditQuestionSuccess) {
      setQuestion("");
      setDifficulty("");
      setPlatform("");
      setVideoId("");
      // setHint("");
      setDegree("");
      setErrors("");
      type ? setRefetch(!refetch) : navigate(-1);
    }
  }, [addEditQuestionSuccess]);

  return (
    <section
      className={`flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light ${
        type ? "pt-10" : "py-20"
      }`}
    >
      {!type && (
        <HelmetTags title="Question Bank | Mr. Ahmed Harouny"></HelmetTags>
      )}
      <form
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        className="form-container px-8  flex flex-col items-center w-full"
      >
        <h2
          className={` text-center text-xl ${
            type ? "mb-14" : "mb-20 font-semibold"
          }`}
        >
          {edit
            ? "Please fill in the fields to edit the question"
            : "Please fill in the fields to add the question"}
        </h2>
        {/**
         * //!------question-----
         */}
        {/* <div className=" question mb-10 flex w-[900px] flex-col items-start gap-2 alg:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-xl font-semibold">Question</h2>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",
              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={question}
            onChange={(event, editor) => {
              const data = editor.getData();
              setQuestion(data);
            }}
          />
          {errors === "question" && (
            <p className="text-xs text-red-500 "> This field is required. </p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.question && (
                <p className="w-full text-end text-xs text-red-500  ">
                  {addEditQuestionErrors?.response?.data?.errors?.question[0]}
                </p>
              )
          }
        </div> */}
        {/**
         * //!------hint-----
         */}
        {/* <div className=" answer1 my-10 flex w-[900px] flex-col items-start gap-2 alg:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-xl font-semibold">Hint</h2>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",
              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={hint}
            onChange={(event, editor) => {
              const data = editor.getData();
              setHint(data);
            }}
          />
          {errors === "hint" && (
            <p className="text-xs text-red-500 ">Please fill in this field</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.hint && (
                <p className="text-xs text-red-500  ">
                  {addEditQuestionErrors?.response?.data?.errors?.hint[0]}
                </p>
              )
          }
        </div> */}

        {/** //!---Difficulty degree
         *
         *       */}
        <div className="flex w-full items-start justify-between gap-10 md:gap-6 md:flex-col mt-5">
          {/* <div
            className={`my-1 flex w-1/2 flex-col items-start justify-center gap-1  md:w-full `}
          >
            <label className="w-full truncate" htmlFor="difficulty">
              Difficulty{" "}
            </label>
            <select
              value={difficulty}
              onChange={(e) => setDifficulty(e.target.value)}
              name="difficulty"
              id="difficulty"
            >
              <option hidden disabled value="">
                Difficulty
              </option>{" "}
              <option value="beginner">Beginner</option>
              <option value="normal">Normal</option>
              <option value="medium">Medium</option>
              <option value="professional">Professional</option>
              <option value="expert">Expert</option>
            </select>

            {errors === "difficulty" && (
              <p className="text-xs text-red-500 "> This field is required. </p>
            )}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.difficulty && (
                  <p className="w-full text-end text-xs text-red-500  ">
                    {
                      addEditQuestionErrors?.response?.data?.errors
                        ?.difficulty[0]
                    }
                  </p>
                )
            }
          </div> */}
          <div className="flex w-full  flex-col items-start justify-center gap-2 md:w-full">
            <label className="w-full truncate" htmlFor="questions">
              Questions
            </label>
            <input
              id="questions"
              // hidden
              className="signin-inputs  w-full"
              type="file"
              accept=".pdf"
              name="question"
              {...register("question", {
                required: true,
                validate: (value) => !(value[0]?.size > 5000000),
              })}
            />

            {errors.questions && (
              <p className="pt-4 text-xs text-red-500">
                {errors.questions.type === "required" &&
                  "This field is required."}
                {errors.questions.type === "validate" &&
                  "Max Image Size is 5MB."}
              </p>
            )}
            {
              //!-------Editing server errors -----

              addEditQuestionErrors?.response?.data?.errors &&
                addEditQuestionErrors?.response?.data?.errors?.questions && (
                  <p className="pt-4 text-xs text-red-500">
                    {addEditQuestionErrors?.response?.data.errors.questions[0]}
                  </p>
                )
            }
          </div>
          <div className=" Degree flex w-full  flex-col items-start justify-center gap-2 md:w-full">
            <label className="w-full truncate" htmlFor="degree">
              Degree
            </label>
            <input
              className="signin-inputs w-full !h-[38px] "
              type="number"
              inputMode="numeric"
              min={1}
              id="degree"
              placeholder="000"
              name="level"
              autoComplete="on"
              {...register("degree", {
                required: true,
              })}
            />

            {errors === "degree" && (
              <p className="text-xs text-red-500">This field is required. </p>
            )}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.degree && (
                  <p className=" text-xs text-red-500  ">
                    {addEditQuestionErrors?.response?.data?.errors?.degree[0]}
                  </p>
                )
            }
          </div>
        </div>
        <button
          disabled={submitLoading && submitQuestion.flag}
          className=" submit mt-6 w-full "
          type="submit"
        >
          {submitLoading && submitQuestion.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <p> {edit ? "Edit Question" : "Add Question"}</p>
          )}
        </button>
      </form>
    </section>
  );
}

export default AddEditEssayQuestion;
