import React, { useEffect, useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import Loader from "../../../../MainComponents/Loader.jsx";
import { Popconfirm } from "antd";

function AdminCourses({ admin }) {
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const navigate = useNavigate();

  const [allCoursesData, allCoursesErrors, allCoursesloading] = useAxios(
    process.env.REACT_APP_ADMIN_GET_ALL_UNTIS_API,
    "GET",
    "GET",
    reFetch
  );

  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!--------- delete Course -------

  const [deleteCourseSuccess, deleteCourseErrors, deleteloading] = useAxios(
    process.env.REACT_APP_ADMIN_DELETE_STUDENT_COURSE_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  function handleDeleteCourse(id) {
    setSubmitDelete({
      flag: "deleteCourse",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  useEffect(() => {
    //! refetch the data on success delete
    if (deleteCourseSuccess) {
      setReFetch(!reFetch);
    }
  }, [deleteCourseSuccess]);

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title="Courses | Mr.Mohamed Salama"></HelmetTags>{" "}
      <div className="mb-20 flex flex-col items-start gap-10 amd-hero:items-center">
        <h2 className=" text-2xl font-bold">Courses</h2>
        <div className="flex w-full justify-start gap-7 md:justify-center items-center">
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("")
            )) && (
            <Link
              to="/admin/content/add-course"
              className=" rounded-lg border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-bg transition-all duration-300 hover:bg-bg hover:text-secondary   active:scale-90 "
            >
              Add Course <FontAwesomeIcon className="ml-2" icon={faPlus} />
            </Link>
          )}
        </div>
      </div>
      {
        <div className="courses w-full ">
          <div className={`courses  mt-10 grid-auto-fit w-full `}>
            {allCoursesData?.data?.length === 0 && (
              <p>There are No Courses Yet.</p>
            )}
            {allCoursesData?.data?.map((course, index) => (
              <div
                key={course.id}
                className={`single-course-container hover:bg-lemon bg-green duration-300 w-[350px] sm:w-[300px]   rounded-md p-4 shadow-lg`}
              >
                <div className="img-wrapper w-full rounded-md overflow-hidden">
                  <img
                    className="w-full h-40 object-cover cursor-pointer hover:saturate-150 duration-300 "
                    src={course.img}
                    alt={course.title}
                    onClick={() => navigate(`/admin/content/${course.id}`)}
                  />
                </div>

                <div className="details-wrapper mt-5">
                  <h2 className="title font-bold mt-5 mb-2   flex justify-between items-center">
                    <Link
                      to={`/admin/content/${course.id}`}
                      className="cursor-pointer w-3/5  truncate"
                    >
                      {course.name}
                    </Link>
                    <span className="font-normal"> {course.type}</span>
                  </h2>
                  <p className="description">{course.description}</p>

                  <div className="price-cta flex justify-between items-center mt-5 w-full">
                    <div className="admin-btns flex justify-end items-center gap-4  w-full">
                      {(AuthState.userData?.admin?.roles[0]?.name ===
                        "superadmin" ||
                        AuthState.userData?.admin?.permissions?.some(
                          (permission) => permission.name.startsWith("")
                        )) && (
                        <Link
                          to={`/admin/content/view-course/${course.id}`}
                          className="edit-btn   bg-edit text-bg w-16 py-[6px] font-semibold rounded-2xl text-center border-2 border-edit hover:bg-bg hover:text-edit transition-all duration-300  hover:scale-105 active:scale-90"
                        >
                          View
                        </Link>
                      )}

                      {(AuthState.userData?.admin?.roles[0]?.name ===
                        "superadmin" ||
                        AuthState.userData?.admin?.permissions?.some(
                          (permission) =>
                            permission.name.startsWith("lecture_edit")
                        )) && (
                        <Link
                          to={`/admin/content/edit-course/${course.id}`}
                          className="edit-btn   bg-edit text-bg w-16 py-[6px] font-semibold rounded-2xl text-center border-2 border-edit hover:bg-bg hover:text-edit transition-all duration-300  hover:scale-105 active:scale-90"
                        >
                          Edit
                        </Link>
                      )}
                      {(AuthState.userData?.admin?.roles[0]?.name ===
                        "superadmin" ||
                        AuthState.userData?.admin?.permissions?.some(
                          (permission) =>
                            permission.name.startsWith("lecture_delete")
                        )) && (
                        <Popconfirm
                          okText="Confirm"
                          okType="danger"
                          cancelText="Cancel"
                          color="#ffffff"
                          //okButtonProps={{ loading: loading }}
                          onConfirm={() => handleDeleteCourse(course.id)}
                          title="Are You Sure You Want to Delete The Course?"
                        >
                          <button className="delete-btn   bg-delete text-bg w-16 py-[6px] font-semibold rounded-2xl text-center border-2 border-delete hover:bg-bg hover:text-delete transition-all duration-300  hover:scale-105 active:scale-90">
                            Delete
                          </button>
                        </Popconfirm>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      }
      {allCoursesloading && <Loader />}
    </section>
  );
}

export default AdminCourses;
