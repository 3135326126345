import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";
import HelmetTags from "../../../../MainComponents/HelmetTags";

const AddQuestionBankQuestionNumber = ({ edit }) => {
  const [values, setValues] = useState(null);
  const { courseID, titleID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditSession": {
        return {
          ...state,
          submitAddEditSession: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditSession: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_UNTIS_API
    : process.env.REACT_APP_ADD_QUESTION_BANK_QUESTION_NUMBER_API;

  //!--------- add edit Course -------
  const [TitleAddEditSuccess, SessionAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditSession.flag,
    state.submitAddEditSession.dependency,
    state.submitAddEditSession.data,
    true
  );

  //!--------- get the Course info for editing -------

  const [TitleInfo, TitleInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_GET_ALL_UNTIS_API}/${titleID}`,
    "GET",
    titleID,
    titleID
  );

  useEffect(() => {
    if (TitleInfo) {
      let temp = TitleInfo.data;
      setValues({
        ...temp,
      });
    }
  }, [TitleInfo]);

  useEffect(() => {
    //!---add actions ----

    if (TitleAddEditSuccess) {
      navigate(-1);
      reset();
    }
  }, [TitleAddEditSuccess]);

  const navigate = useNavigate();

  const defaultValues = TitleInfo?.data;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
    values: defaultValues,
  });

  const onSubmit = (data) => {
    const finalData = {
      ...data,
      id: titleID,
      visibility: data.visibility === true ? 1 : 0,
      course_id: courseID,
    };

    dispatch({
      type: "setSubmitAddEditSession",
      payload: {
        flag: "AddEditSession",
        dependency: !state.submitAddEditSession.dependency,
        data: finalData,
      },
    });
  };
  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Add Title | Mr.Ahmed Harouny"></HelmetTags>{" "}
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-10 text-center text-2xl font-semibold">
          {edit ? "Edit Question Number" : "Add Question Number"}
        </h2>

        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          method="post"
          className="flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
        >
          {/** Name filed */}

          <div className="flex flex-col items-start justify-center  gap-2  w-full">
            <label htmlFor="qnumber">Question Number</label>
            <input
              className="signin-inputs"
              type="text"
              id="qnumber"
              placeholder="Question Number"
              name="qnumber"
              autoComplete="on"
              {...register("qnumber", {
                required: true,
                //pattern: /^[A-Za-z]+$/,
                maxLength: 80,
                minLength: 3,
              })}
            />
            {errors.qnumber && (
              <p className=" pt-2 text-xs text-red-500  ">
                {errors.qnumber.type === "required" &&
                  "This field is required."}
                {errors.qnumber.type === "pattern" &&
                  "Only English letters are allowed."}
                {errors.qnumber.type === "maxLength" &&
                  "Allowed Name max length is 80."}
                {errors.qnumber.type === "minLength" &&
                  "Please enter at least 3 letters."}
              </p>
            )}

            {
              //!-------server errors -----

              SessionAddEditErrors &&
                SessionAddEditErrors?.response?.data?.errors?.qnumber && (
                  <p className=" pt-2 text-xs text-red-500  ">
                    {SessionAddEditErrors?.response?.data?.errors?.qnumber[0]}
                  </p>
                )
            }
          </div>

          {/** submit */}
          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditSession.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditSession.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>
                {edit
                  ? "Confirm Editing The Question Number"
                  : "Add The Question Number"}
              </p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default AddQuestionBankQuestionNumber;
