import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useReducer } from "react";
import useTable from "../../../../MainComponents/Hooks/useTable";
import Register from "../../../Student/Register/Register.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faEye,
  faEyeSlash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";

function PasswordForm() {
  const [hide, setHide] = useState(false);
  const { studentID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitEditPassword": {
        return {
          ...state,
          submitEditPassword: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitEditPassword: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const [EditPasswordSuccess, EditPasswordErrors, PasswordLoading] = useAxios(
    process.env.REACT_APP_EDIT_STUDENT_PASSWORD_API,
    "POST",
    state.submitEditPassword.flag,
    state.submitEditPassword.dependency,
    state.submitEditPassword.data,
    true
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    dispatch({
      type: "setSubmitEditPassword",
      payload: {
        flag: "submitEditPassword",
        dependency: !state.submitEditPassword.dependency,
        data: { ...data, id: studentID },
      },
    });
  };
  return (
    <form
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50 mt-12"
    >
      <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-6">
        {/** new Password  */}

        <div className="w-1/2 md:w-full">
          <div className="relative flex flex-col items-start justify-center gap-2 ">
            <label htmlFor="password">New Password</label>
            <input
              id="password"
              className="signin-inputs w-full"
              type={`${hide ? "text" : "password"}`}
              placeholder="New Password"
              name="password"
              autoComplete="on"
              {...register("password", {
                required: true,
                maxLength: 25,
                minLength: 6,
              })}
            />
            {hide ? (
              <FontAwesomeIcon
                onClick={() => setHide(!hide)}
                className="absolute right-2 bottom-2 h-4 w-4 cursor-pointer"
                icon={faEye}
              />
            ) : (
              <FontAwesomeIcon
                onClick={() => setHide(!hide)}
                className="absolute right-2 bottom-2 h-4 w-4 cursor-pointer"
                icon={faEyeSlash}
              />
            )}
          </div>
          {errors.password && (
            <p className="pt-4 text-xs text-red-500">
              {errors.password.type === "required" && "This field is required."}
              {errors.password.type === "maxLength" &&
                "password Max length is 25 char."}
              {errors.password.type === "minLength" &&
                "Password Min length is 6 char."}
            </p>
          )}
          {
            //!-------Editing server errors -----

            EditPasswordErrors?.response?.data?.errors &&
              EditPasswordErrors?.response?.data?.errors?.password && (
                <p className="pt-4 text-xs text-red-500">
                  {EditPasswordErrors?.response?.data.errors.password[0]}
                </p>
              )
          }
        </div>

        {/** new Password Confirmation   */}

        <div className="w-1/2 md:w-full">
          <div className="relative flex flex-col items-start justify-center gap-2 ">
            <label htmlFor="password_confirmation">Confirm New Password</label>
            <input
              id="password_confirmation"
              className="signin-inputs w-full"
              type={`${hide ? "text" : "password"}`}
              placeholder="Confirm New Password"
              name="password_confirmation"
              autoComplete="on"
              {...register("password_confirmation", {
                required: true,
                maxLength: 25,
                minLength: 6,
                validate: (value) => value === getValues("password"),
              })}
            />
            {hide ? (
              <FontAwesomeIcon
                onClick={() => setHide(!hide)}
                className="absolute right-2 bottom-2 h-4 w-4 cursor-pointer"
                icon={faEye}
              />
            ) : (
              <FontAwesomeIcon
                onClick={() => setHide(!hide)}
                className="absolute right-2 bottom-2 h-4 w-4 cursor-pointer"
                icon={faEyeSlash}
              />
            )}
          </div>
          {errors.password_confirmation && (
            <p className="pt-4 text-xs text-red-500">
              {errors.password_confirmation.type === "required" &&
                "This field is required."}
              {errors.password_confirmation.type === "maxLength" &&
                "password_confirmation Max length is 25 char."}
              {errors.password_confirmation.type === "minLength" &&
                "Password Min length is 6 char."}
              {errors.password_confirmation.type === "validate" &&
                "Passwords don't match."}
            </p>
          )}
          {
            //!-------Editing server errors -----

            EditPasswordErrors?.response?.data?.errors &&
              EditPasswordErrors?.response?.data?.errors
                ?.password_confirmation && (
                <p className="pt-4 text-xs text-red-500">
                  {
                    EditPasswordErrors?.response?.data.errors
                      .password_confirmation[0]
                  }
                </p>
              )
          }
        </div>
      </div>
      {/** Submit Button */}

      <button
        disabled={
          !isValid || (state.submitEditPassword.flag && PasswordLoading)
        }
        className=" submit mt-6 w-full "
        type="submit"
      >
        {state.submitEditPassword.flag && PasswordLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          "Edit Student Password"
        )}
      </button>
    </form>
  );
}
function ImageForm({ values }) {
  const { studentID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitEditPhoto": {
        return {
          ...state,
          submitEditPhoto: action.payload,
        };
      }
      case "setUploadedImgSrc": {
        return {
          ...state,
          uploadedImgSrc: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitEditPhoto: {
      flag: "",
      dependency: false,
      data: {},
    },
    uploadedImgSrc: null,
  });

  const [EditPhotoSuccess, EditPhotoErrors, PhotoLoading] = useAxios(
    process.env.REACT_APP_EDIT_STUDENT_PHOTO_API,
    "POST",
    state.submitEditPhoto.flag,
    state.submitEditPhoto.dependency,
    state.submitEditPhoto.data,
    true
  );
  useEffect(() => {
    if (EditPhotoSuccess) {
      URL.revokeObjectURL(state.uploadedImgSrc);
    }
  }, [EditPhotoSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: "onChange",
    values,
  });
  const imgInput = watch("profile_pic");
  useEffect(() => {
    const subscription = watch((input) => {
      if (input.profile_pic[0]?.name?.length > 0) {
        let imageUrl = URL.createObjectURL(input.profile_pic[0]);
        dispatch({
          type: "setUploadedImgSrc",
          payload: imageUrl,
        });
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);
  const onSubmit = (data) => {
    dispatch({
      type: "setSubmitEditPhoto",
      payload: {
        flag: "SubmitEditPhoto",
        dependency: !state.submitEditPhoto.dependency,
        data: { profile_pic: data.profile_pic[0], id: studentID },
      },
    });
  };
  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-80 flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50 mt-12"
    >
      {/**  Profile Picture  */}
      <div className="w-full flex flex-col items-center">
        <div className="w-fit ">
          <label htmlFor="profile_pic">
            {" "}
            <div className="profile_pic h-28 aspect-square overflow-hidden rounded-full border-2 border-secondary p-1 cursor-pointer">
              <img
                className="h-full w-full rounded-full object-cover object-top"
                src={state.uploadedImgSrc || values?.profile_pic}
                alt="Student Avatar"
              />
            </div>{" "}
          </label>
        </div>
        <input
          id="profile_pic"
          hidden
          className="signin-inputs  w-full"
          type="file"
          accept="image/jpg, image/jpeg, image/png, image/webp"
          name="profile_pic"
          {...register("profile_pic", {
            required: true,
            validate: (value) => !(value[0]?.size > 5000000),
          })}
        />

        {errors.profile_pic && (
          <p className="pt-4 text-xs text-red-500">
            {errors.profile_pic.type === "required" &&
              "This field is required."}
            {errors.profile_pic.type === "validate" && "Max Image Size is 5MB."}
          </p>
        )}
        {
          //!-------Editing server errors -----

          EditPhotoErrors?.response?.data?.errors &&
            EditPhotoErrors?.response?.data?.errors?.profile_pic && (
              <p className="pt-4 text-xs text-red-500">
                {EditPhotoErrors?.response?.data.errors.profile_pic[0]}
              </p>
            )
        }
      </div>

      {/** Submit Button */}

      <button
        disabled={!isValid || (state.submitEditPhoto.flag && PhotoLoading)}
        className=" submit mt-6 w-full "
        type="submit"
      >
        {state.submitEditPhoto.flag && PhotoLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          "Edit Student Photo"
        )}
      </button>
    </form>
  );
}

function EditStudent() {
  const { studentID } = useParams();
  const navigate = useNavigate();

  const initialState = {
    showTable: false,
    left: "left-0",
    top: "top-0",
    toggleFilter: true,
  };
  const initialState2 = {
    editStudentForm: "info",
    left: "left-0",
    top: "top-0",
    toggleFilter: true,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "setShowTable": {
        return {
          ...state,
          showTable: action.payload,
        };
      }
      case "setLeft": {
        return {
          ...state,
          left: action.payload,
        };
      }

      case "setToggleFilter": {
        return {
          ...state,
          toggleFilter: !state.toggleFilter,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  function reducer2(state, action) {
    switch (action.type) {
      case "SetEditStudentForm": {
        return {
          ...state,
          editStudentForm: action.payload,
        };
      }
      case "setLeft": {
        return {
          ...state,
          left: action.payload,
        };
      }

      case "setToggleFilter": {
        return {
          ...state,
          toggleFilter: !state.toggleFilter,
        };
      }
      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state2, dispatch2] = useReducer(reducer2, initialState2);
  const [state, dispatch] = useReducer(reducer, initialState);
  const customApis = {
    api1: "https://students-api-g0pp.onrender.com/data?academiclevel=First",
    api2: "https://students-api-g0pp.onrender.com/data?academiclevel=Second",
  };

  const [tableDetails, setTableDetails] = useState({
    api: customApis.api1,
    tableFor: "codeHistory",
  });
  const table = useTable(tableDetails.api, tableDetails.tableFor);
  //!--------- get the student info for editing -------

  const [studentInfo, studentInfoErrors] = useAxios(
    `${process.env.REACT_APP_STUDENT_INFO_API}${studentID}`,
    "GET",
    studentID,
    studentID
  );

  useEffect(() => {
    if (studentInfo) {
    }
  }, [studentInfo]);

  return (
    <section className="dark:bg-dark dark:text-light h-fit w-full overflow-y-auto px-10 p-10 sm:px-4 flex flex-col items-start amd-hero:items-center justify-start ">
      <h2 className="mb-4 text-start sm:text-center text-2xl font-bold">
        Edit Student
      </h2>

      <div className=" h-fit w-full flex flex-col items-center justify-start my-8 mt-16 ">
        <div
          className={`filters-container rounded-md bg-light-gray w-80 amd-hero:w-72  flex justify-center overflow-hidden`}
        >
          <div
            className={`filters flex justify-between  items-center w-full h-12 relative  transition-all duration-200 ease-in-out `}
          >
            <div
              onClick={() => {
                dispatch2({ type: "setLeft", payload: "left-0" });
                dispatch2({
                  type: "SetEditStudentForm",
                  payload: "info",
                });
              }}
              className={`calculus  w-1/2  flex justify-center items-center h-full cursor-pointer z-10 font-semibold   transition-{height} duration-200 ease-in-out  ${
                state2.toggleFilter
                  ? "amd-hero:opacity-100 amd-hero:h-[calc(200px/4)]"
                  : "amd-hero:opacity-0 amd-hero:h-0 amd-hero:pointer-events-none"
              }`}
            >
              Information
            </div>
            <div
              onClick={() => {
                dispatch2({ type: "setLeft", payload: "left-1/2" });
                dispatch2({
                  type: "SetEditStudentForm",
                  payload: "password",
                });
              }}
              className={`calculus  w-1/2  flex justify-center items-center h-full cursor-pointer z-10 font-semibold   transition-{height} duration-200 ease-in-out  ${
                state2.toggleFilter
                  ? "amd-hero:opacity-100 amd-hero:h-[calc(200px/4)]"
                  : "amd-hero:opacity-0 amd-hero:h-0 amd-hero:pointer-events-none"
              }`}
            >
              Password
            </div>

            <div
              className={`selector-desktop  absolute w-1/2  h-full  ${state2.left}  bg-green duration-300 rounded-md   top-0`}
            ></div>
          </div>
        </div>
        {state2.editStudentForm === "info" ? (
          <Register edit studentInfo={studentInfo} />
        ) : state2.editStudentForm === "password" ? (
          <PasswordForm />
        ) : state2.editStudentForm === "img" ? (
          <ImageForm
            values={{
              profile_pic: studentInfo?.data?.studentinfo?.profile_pic,
            }}
          />
        ) : (
          ""
        )}
      </div>
    </section>
  );
}

export default EditStudent;
