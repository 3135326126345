import Hero from "./Components/Hero";
import HelmetTags from "../../MainComponents/HelmetTags.jsx";
import Navbar from "../../MainComponents/Navbar";
import Footer from "../../MainComponents/Footer";
import OurFeatures from "./Components/OurFeatures.jsx";

function LandingPage() {
  return (
    <>
      <HelmetTags title="Mr.Ahmed Harouny" index></HelmetTags>
      <Navbar />
      <main className="pt-20 w-full overflow-x-hidden">
        <Hero />
        <OurFeatures />
      </main>
      <Footer />
    </>
  );
}

export default LandingPage;
