import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const QuestionBankFilter = ({
  state,
  AddFilterSuccess,
  AddFilterErrors,
  AddFilterLoading,
  dispatch,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // get all categories
  const [AllCategoriesSuccess, AllCategoriesErrors, AllCategoriesLoading] =
    useAxios(
      process.env.REACT_APP_GET_ALL_QUESTION_BANK_CATEGORIES_API,
      "GET",
      "GET",
      ""
    );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onTouched",
  });

  useEffect(() => {
    const queryString = window.location.search.substring(1); // Remove the leading '?'
    const urlParams = new URLSearchParams(queryString);

    const queryObject = Object.fromEntries(urlParams);

    if (AllCategoriesSuccess) {
      for (const key in queryObject) {
        setValue(key, +queryObject[key]);
      }

      if (queryObject && urlParams.size !== 0) {
        onSubmit(queryObject);
      }
    }
  }, [AllCategoriesSuccess, setValue]);

  const unit = watch("unit_id");
  const lesson = watch("lesson_id");

  const onSubmit = (data) => {
    const finalData = {
      ...data,
    };

    const queryString = new URLSearchParams(data).toString();

    navigate(`${pathname}?${queryString}`);

    dispatch({
      type: "setSubmitAddEditSession",
      payload: {
        flag: "AddEditSession",
        dependency: !state.submitAddEditSession.dependency,
        data: finalData,
      },
    });
  };
  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
        method="post"
        className="flex w-full flex-col items-start  justify-center gap-4 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
      >
        {/** Name filed */}

        <div className="w-full flex items-center gap-5 md:flex-col">
          <div className="mb-5 flex w-1/2 md:w-full flex-col items-start justify-center gap-2  md:w-full">
            <label className="w-full truncate" htmlFor="year_id">
              Year and Variant
            </label>

            <select
              name="year_id"
              id="year_id"
              {...register("year_id", {
                required: false,
              })}
            >
              <option value="0">All</option>
              {AllCategoriesSuccess?.data.years.map((year) => (
                <option value={year.id} key={year.id}>
                  {year.year}
                </option>
              ))}
            </select>

            {errors === "year_id" && (
              <p className="text-xs text-red-500">This field is required. </p>
            )}
            {
              //!-------server errors -----

              AddFilterErrors &&
                AddFilterErrors?.response?.data?.errors?.year_id && (
                  <p className=" text-xs text-red-500  ">
                    {AddFilterErrors?.response?.data?.errors?.year_id[0]}
                  </p>
                )
            }
          </div>
          <div className="mb-5 flex w-1/2 md:w-full flex-col items-start justify-center gap-2  md:w-full">
            <label className="w-full truncate" htmlFor="qnumber_id">
              Question Number{" "}
            </label>
            <select
              name="qnumber_id"
              id="qnumber_id"
              {...register("qnumber_id", {
                required: false,
              })}
            >
              <option value="0">All</option>
              {AllCategoriesSuccess?.data.qnumbers.map((qnumber) => (
                <option value={qnumber.id} key={qnumber.id}>
                  {qnumber.qnumber}
                </option>
              ))}
            </select>

            {errors === "qnumber_id" && (
              <p className="text-xs text-red-500">This field is required. </p>
            )}
            {
              //!-------server errors -----

              AddFilterErrors &&
                AddFilterErrors?.response?.data?.errors?.qnumber_id && (
                  <p className=" text-xs text-red-500  ">
                    {AddFilterErrors?.response?.data?.errors?.qnumber_id[0]}
                  </p>
                )
            }
          </div>
        </div>

        <div className="w-full flex items-center gap-5 md:flex-col">
          <div
            className={`mb-5 flex w-1/2 md:w-full flex-col items-start justify-center gap-2  md:w-full `}
          >
            <label className="w-full truncate" htmlFor="unit">
              Units
            </label>
            <select
              name="unit_id"
              id="unit_id"
              {...register("unit_id", {
                required: false,
              })}
            >
              <option value="0">All</option>
              {AllCategoriesSuccess?.data.units.map((unit) => (
                <option value={unit.id} key={unit.id}>
                  {unit.name}
                </option>
              ))}
            </select>

            {errors === "unit_id" && (
              <p className="text-xs text-red-500">This field is required. </p>
            )}
            {
              //!-------server errors -----

              AddFilterErrors &&
                AddFilterErrors?.response?.data?.errors?.unit_id && (
                  <p className=" text-xs text-red-500  ">
                    {AddFilterErrors?.response?.data?.errors?.unit_id[0]}
                  </p>
                )
            }
          </div>
          <div className="mb-5 flex w-1/2 md:w-full flex-col items-start justify-center gap-2  md:w-full">
            <label className="w-full truncate" htmlFor="lesson">
              Lessons
            </label>
            <select
              name="lesson_id"
              id="lesson_id"
              {...register("lesson_id", {
                required: false,
              })}
            >
              <option value="0">All</option>
              {AllCategoriesSuccess?.data?.units
                ?.find((findUnit) => findUnit.id === +unit)
                ?.lessons?.map((lesson) => (
                  <option value={lesson.id} key={lesson.id}>
                    {lesson.name}
                  </option>
                ))}
            </select>

            {errors === "lesson_id" && (
              <p className="text-xs text-red-500">This field is required. </p>
            )}
            {
              //!-------server errors -----

              AddFilterErrors &&
                AddFilterErrors?.response?.data?.errors?.lesson_id && (
                  <p className=" text-xs text-red-500  ">
                    {AddFilterErrors?.response?.data?.errors?.lesson_id[0]}
                  </p>
                )
            }
          </div>
        </div>

        <div className="w-full flex items-center gap-5 md:flex-col">
          <div
            className={`mb-5 flex w-1/2 md:w-full flex-col items-start justify-center gap-2  md:w-full `}
          >
            <label className="w-full truncate" htmlFor="title">
              Titles
            </label>
            <select
              name="title_id"
              id="title"
              {...register("title_id", {
                required: false,
              })}
            >
              <option value="0">All</option>
              {AllCategoriesSuccess?.data?.units
                ?.find((findUnit) => findUnit.id === +unit)
                ?.lessons?.find((findLesson) => findLesson.id === +lesson)
                ?.titles.map((title) => (
                  <option value={title.id} key={title.id}>
                    {title.title}
                  </option>
                ))}
            </select>

            {errors === "title_id" && (
              <p className="text-xs text-red-500">This field is required. </p>
            )}
            {
              //!-------server errors -----

              AddFilterErrors &&
                AddFilterErrors?.response?.data?.errors?.title_id && (
                  <p className=" text-xs text-red-500  ">
                    {AddFilterErrors?.response?.data?.errors?.title_id[0]}
                  </p>
                )
            }
          </div>
          <div
            className={`mb-5 flex w-1/2 md:w-full flex-col items-start justify-center gap-2  md:w-full `}
          >
            {/* //!------difficulty ----- */}
            <label className="w-full truncate" htmlFor="difficulty">
              Difficulty{" "}
            </label>
            <select
              name="difficulty"
              id="difficulty"
              {...register("difficulty", {
                required: false,
              })}
            >
              <option value="0">All</option>
              <option value="beginner">Beginner</option>
              <option value="normal">Normal</option>
              <option value="medium">Medium</option>
              <option value="professional">Professional</option>
              <option value="expert">Expert</option>
            </select>

            {errors === "difficulty" && (
              <p className="text-xs text-red-500 ">Please fill in this field</p>
            )}
            {
              //!-------server errors -----

              AddFilterErrors &&
                AddFilterErrors?.response?.data?.errors?.difficulty && (
                  <p className="text-xs text-red-500  ">
                    {AddFilterErrors?.response?.data?.errors?.difficulty[0]}
                  </p>
                )
            }
          </div>
        </div>

        {/** submit */}

        <button
          disabled={
            !isValid || (AddFilterLoading && state.submitAddEditSession.flag)
          }
          className=" submit mt-6 w-full "
          type="submit"
        >
          {AddFilterLoading && state.submitAddEditSession.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <p> Search</p>
          )}
        </button>
      </form>
    </div>
  );
};
