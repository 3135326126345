import {
  faArrowRightFromBracket,
  faChartLine,
  faCheckCircle,
  faCircleChevronRight,
  faClipboardQuestion,
  faCommentAlt,
  faGraduationCap,
  faUser,
  faUserGear,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReducer } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import {
  useAuthDispatch,
  useAuthState,
} from "../../../MainComponents/GlobalContext";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { useMemo } from "react";
import axios from "axios";

function AdminLayout() {
  const AuthDispatch = useAuthDispatch();
  const AuthState = useAuthState();
  const navigate = useNavigate();
  const initialState = {
    collapse: false,
    showSidebar: false,
    refetch: false,
  };
  function reducer(state, action) {
    switch (action.type) {
      case "SetCollapse": {
        return {
          ...state,
          collapse: !state.collapse,
        };
      }
      case "SetRefetch": {
        return {
          ...state,
          refetch: !state.refetch,
        };
      }
      case "SetShowSidebar": {
        return {
          ...state,
          showSidebar: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  function handleSignOut() {
    dispatch({
      type: "SetShowSidebar",
      payload: false,
    });

    localStorage.removeItem("userData");
    AuthDispatch({ type: "setUserData", payload: null });
    navigate("/admin/signin");
  }

  const adminOrStuedntToken = JSON.parse(localStorage?.userData).admin_token
    ? JSON.parse(localStorage?.userData).admin_token
    : JSON.parse(localStorage?.userData).student_token;
  useMemo(() => {
    // set axios
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + adminOrStuedntToken;
  }, [adminOrStuedntToken]);

  return (
    <section className="dark:bg-dark flex h-screen max-h-screen w-full overflow-hidden bg-bg dark:text-blue md:pt-16">
      <section className="header-mobile-only fixed inset-0 top-0 z-50  hidden h-16 w-full items-center justify-between bg-secondary px-16 text-blue md:flex">
        <div className="logo h-fit">
          <img
            className="h-12 cursor-pointer transition duration-200 ease-in-out hover:scale-105"
            onClick={() => navigate("/admin/content")}
            src="https://api.sciencetoonz.com/uploads/ST%20Transparent.png"
            alt="logo-dash-bg"
          />
        </div>
        <div className="burger-dash bg">
          <input
            onChange={() =>
              dispatch({
                type: "SetShowSidebar",
                payload: !state.showSidebar,
              })
            }
            checked={state.showSidebar}
            type="checkbox"
            id="checkbox"
          />
          <label htmlFor="checkbox" className="toggle">
            <div className="bar bar--top"></div>
            <div className="bar bar--middle"></div>
            <div className="bar bar--bottom"></div>
          </label>
        </div>
      </section>

      {/**
       * //!dash menu fixed
       *
       */}
      <div
        className={`dash-menu-fixed  z-40 ${
          state.collapse ? "w-24" : "w-1/5 "
        } fixed left-0 top-0 
                    h-full w-1/5 transition-all duration-200 ease-in-out ${
                      state.showSidebar
                        ? "md:pointer-events-auto md:w-full md:translate-x-0 md:opacity-100 bg-bg"
                        : "md:pointer-events-none  md:-translate-x-20 md:opacity-0"
                    } border-r-2 border-r-blue md:pt-32`}
      >
        <div className="collapse-btn-wrapper relative w-full md:hidden ">
          <FontAwesomeIcon
            onClick={() =>
              dispatch({
                type: "SetCollapse",
              })
            }
            className={` absolute right-0 top-20 z-50 translate-x-1/2 cursor-pointer rounded-full bg-bg text-4xl transition duration-200 ease-in-out hover:scale-105 ${
              state.collapse && "rotate-180"
            }`}
            icon={faCircleChevronRight}
          />
        </div>
        <div className="dash-menu-container  flex  h-full w-full   flex-col items-center justify-start gap-7 overflow-y-auto  py-10 ">
          <div className="logo h-fit md:hidden">
            <img
              className={` ${
                state.collapse ? "h-14" : "h-20"
              }  cursor-pointer transition duration-200 ease-in-out hover:scale-105`}
              onClick={() => navigate("/admin/content")}
              src="https://api.sciencetoonz.com/uploads/ST%20Transparent.png"
              alt="logo-dash-bg"
            />
          </div>

          <ul className="dash-menu-items flex h-fit w-full  flex-col items-center  ">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("content_courses_view")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/content"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faGraduationCap} />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Content
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("content_courses_view")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/subjects"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faGraduationCap} />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Subjects
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {/* {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("courses_view")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/student-courses"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faGraduationCap} />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Courses
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )} */}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("student")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/students"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faUsers} />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Students{" "}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {/* {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("send_whatsapp")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/whatsapp"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <BsWhatsapp />
                      <span
                        className={` 
                        ${state.collapse && "hidden"}   alg:text-base lg:w-3/5`}
                      >
                        Whatsapp
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )} */}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("questionbank")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/question-bank"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon
                        className="w-5"
                        icon={faClipboardQuestion}
                      />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }  truncate alg:text-base lg:w-3/5`}
                      >
                        Question Bank
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("user")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/assistants"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faUserGear} />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Assistants{" "}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {/* {AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("class")
            ) && ( */}
            <li
              onClick={() =>
                dispatch({
                  type: "SetRefetch",
                })
              }
              className="w-full"
            >
              <NavLink
                onClick={() =>
                  dispatch({
                    type: "SetShowSidebar",
                    payload: false,
                  })
                }
                className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                to="/admin/classes"
              >
                <div
                  className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                    state.collapse && "w-fit"
                  }`}
                >
                  <div className="flex items-center justify-start gap-4 alg:gap-2">
                    <FontAwesomeIcon className="w-5" icon={faUserGear} />{" "}
                    <span
                      className={` ${
                        state.collapse && "hidden"
                      }   alg:text-base lg:w-3/5`}
                    >
                      Classes
                    </span>
                  </div>
                </div>
              </NavLink>
            </li>
            {/* )} */}

            {AuthState.userData?.admin?.roles[0]?.name !== "superadmin" &&
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("class")
              ) && (
                <li
                  onClick={() =>
                    dispatch({
                      type: "SetRefetch",
                    })
                  }
                  className="w-full"
                >
                  <NavLink
                    onClick={() =>
                      dispatch({
                        type: "SetShowSidebar",
                        payload: false,
                      })
                    }
                    className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                    to="/admin/correction"
                  >
                    <div
                      className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                        state.collapse && "w-fit"
                      }`}
                    >
                      <div className="flex items-center justify-start gap-4 alg:gap-2">
                        <FontAwesomeIcon className="w-5" icon={faCheckCircle} />{" "}
                        <span
                          className={` ${
                            state.collapse && "hidden"
                          }   alg:text-base lg:w-3/5`}
                        >
                          Correction
                        </span>
                      </div>
                    </div>
                  </NavLink>
                </li>
              )}

            {/* {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("analystics")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/analytics"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faChartLine} />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Analytics{" "}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )} */}

            {AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("ask")
            ) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/chat"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faCommentAlt} />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Inbox
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            <li
              onClick={() =>
                dispatch({
                  type: "SetRefetch",
                })
              }
              className="w-full"
            >
              <NavLink
                onClick={() =>
                  dispatch({
                    type: "SetShowSidebar",
                    payload: false,
                  })
                }
                className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                to="/admin/profile"
              >
                <div
                  className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                    state.collapse && "w-fit"
                  }`}
                >
                  <div className="flex items-center justify-start gap-4 alg:gap-2">
                    <FontAwesomeIcon className="w-5" icon={faUser} />{" "}
                    <span
                      className={` ${
                        state.collapse && "hidden"
                      }   alg:text-base lg:w-3/5`}
                    >
                      Profile
                    </span>
                  </div>
                </div>
              </NavLink>
            </li>
          </ul>

          <div className="w-full">
            <div
              className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3 cursor-pointer"
              onClick={() => handleSignOut()}
            >
              <div
                className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                  state.collapse && "w-fit"
                }`}
              >
                <div className="flex items-center justify-start gap-4 alg:gap-2">
                  <FontAwesomeIcon
                    className="w-5"
                    icon={faArrowRightFromBracket}
                  />{" "}
                  <span
                    className={` ${
                      state.collapse && "hidden"
                    }   alg:text-base lg:w-3/5`}
                  >
                    Logout{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/**
       * //!placeholder
       *
       */}
      <div
        className={`placeholder h-full ${
          state.collapse ? "w-24" : "w-1/5 min-w-[20%]"
        }  transition-all duration-200 ease-in-out md:w-0 md:min-w-[0%]`}
      ></div>
      {/**
       * //!outlet
       *
       */}
      <section className="outlet  h-full w-full overflow-y-auto  ">
        <Outlet dispatch="dddd" />
      </section>
    </section>
  );
}

export default AdminLayout;
