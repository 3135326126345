import { Helmet } from "react-helmet-async";
export default function HelmetTags({
  title,
  description = "Mr.Ahmed Harouny",
  index,
  children,
}) {
  return (
    <Helmet>
      {/* 
      //!---- Standard metadata tags ----
       */}
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* 
          //!---- Facebook tags ------
           */}
      {index && <meta property="og:title" content={title} />}
      {index && <meta property="og:description" content={description} />}
      {index && <meta property="og:type" content="website" />}
      {index && <meta property="og:url" content="https://our-matrix.com" />}
      {index && (
        <meta
          property="og:image"
          content="https://designcode.io/cloud/v2/twitter.jpg"
        />
      )}
      {/* 
          //!---- Twitter tags -----
           */}
      {index && <meta name="twitter:title" content={title} />}
      {index && <meta name="twitter:description" content={description} />}
      {index && <meta name="twitter:site" content="@motaz" />}
      {index && <meta name="twitter:creator" content="@motaz" />}
      {index && <meta name="twitter:card" content="summary_large_image" />}
      {index && (
        <meta
          name="twitter:image"
          content="https://designcode.io/cloud/v2/twitter.jpg"
        />
      )}
      {index ? "" : <meta name="robots" content="noindex, nofollow" />}
      {children}
    </Helmet>
  );
}
