import { useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";

function ViewStudents() {
  const { studentID } = useParams();
  const table = useTable("viewStudent", "", "", "", "", studentID);

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title="ViewStudents | Mr.Ahmed Harouny"></HelmetTags>{" "}
      <h2 className="mb-7 text-2xl font-bold">View Student</h2>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default ViewStudents;
