import { useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useReducer, useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const AdminCourseViewResults = () => {
  const { ID } = useParams();
  const [selectedItems, setSelectedItems] = useState([]);

  function reducer(state, action) {
    switch (action.type) {
      case "setretakExamData": {
        return {
          ...state,
          retakExamData: action.payload,
        };
      }
      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    retakExamData: { flag: "", dependency: false, data: {} },
  });

  const [RetakExamSuccess, RetakExamErrors, RetakExamLoading] = useAxios(
    process.env.REACT_APP_ADMIN_RETAKE_EXAM_API,
    "POST",
    state.retakExamData.flag,
    state.retakExamData.dependency,
    state.retakExamData.data,
    true
  );
  const handelSelectionItems = (records) => {
    setSelectedItems(
      records.map((record) => ({ id: record.key, type: record.type }))
    );
  };

  const handleRetackExam = () => {
    dispatch({
      type: "setretakExamData",
      payload: {
        flag: "RetakExam",
        dependency: !state.retakExamData.dependency,
        data: {
          selected: selectedItems,
        },
      },
    });
  };
  const table = useTable(
    "viewStudentExamResults",
    "",
    "",
    RetakExamLoading,
    "",
    ID,
    "",
    "",
    "",
    "",
    handelSelectionItems
  );

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full  overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title="View Results | Mr.Ahmed Rashsad"></HelmetTags>
      <h2 className=" text-start text-2xl font-bold mb-12">All Students</h2>
      <div className="flex items-center justify-end gap-2">
        <button
          disabled={selectedItems.length === 0 || RetakExamLoading}
          onClick={handleRetackExam}
          className="disabled:opacity-50 px-4 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
        >
          {RetakExamLoading ? (
            <FontAwesomeIcon spin icon={faSpinner} />
          ) : (
            "Retak"
          )}
        </button>
        {/* <button className="px-4 rounded-2xl border-2 border-accent border-accent py-1 text-center font-semibold text-accent duration-300 hover:bg-accent hover:text-bg active:scale-90">
          Retak All
        </button> */}
      </div>
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
};

export default AdminCourseViewResults;
