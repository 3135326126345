import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const QuestionBankShowDetails = ({ type }) => {
  const { categoryID } = useParams();
  const table = useTable(
    type === "units"
      ? "questionBankAllUnits"
      : type === "years"
      ? "questionBankAllYears"
      : type === "question-number"
      ? "questionBankAllQuestionNumbers"
      : type === "lessons"
      ? "questionBankAllLessons"
      : type === "titles"
      ? "questionBankAllTitles"
      : null,
    "",
    "",
    "",
    "",
    categoryID
  );
  const AuthState = useAuthState();

  return (
    <section className="h-full w-full overflow-y-auto px-10 py-10 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Question Bank Sub Category | Mr. Ahmed Harouny"></HelmetTags>{" "}
      <div className="mb-20 flex flex-col items-start gap-10">
        <h2 className="w-full text-start text-3xl font-bold">
          {type === "units" && "All Units"}
          {type === "lessons" && "All Lessons"}
          {type === "titles" && "All Titles"}
          {type === "years" && "All Year and Variant"}
          {type === "question-number" && "All Question Numbers"}
        </h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("questionbank_addcategory")
          )) && (
          <>
            {type === "units" && (
              <Link
                to={`/admin/question-bank/add-choose-question/add-unit`}
                className="text-white ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
              >
                <FontAwesomeIcon className="mr-4" icon={faPlus} />
                Add New Unit
              </Link>
            )}
            {type === "lessons" && (
              <Link
                to={`/admin/question-bank/add-choose-question/add-lesson`}
                className="text-white ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
              >
                <FontAwesomeIcon className="mr-4" icon={faPlus} />
                Add New Lesson
              </Link>
            )}
            {type === "titles" && (
              <Link
                to={`/admin/question-bank/add-choose-question/add-title`}
                className="text-white ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
              >
                <FontAwesomeIcon className="mr-4" icon={faPlus} />
                Add New Title
              </Link>
            )}
            {type === "years" && (
              <Link
                to={`/admin/question-bank/add-choose-question/add-year`}
                className="text-white ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
              >
                <FontAwesomeIcon className="mr-4" icon={faPlus} />
                Add New Year and Variant
              </Link>
            )}
            {type === "question-number" && (
              <Link
                to={`/admin/question-bank/add-choose-question/add-question-number`}
                className="text-white ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
              >
                <FontAwesomeIcon className="mr-4" icon={faPlus} />
                Add New Question Number
              </Link>
            )}
          </>
        )}
      </div>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
};

export default QuestionBankShowDetails;
