import { useAuthState } from "../../../../MainComponents/GlobalContext";
import HelmetTags from "../../../../MainComponents/HelmetTags";

import useTable from "../../../../MainComponents/Hooks/useTable";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

function Students() {
  const AuthState = useAuthState();
  // const table = useTable("students", "", "", academiclevel, "", academiclevel);
  const table = useTable("students", "", "");

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      <HelmetTags title="Students | Mr.Ahmed Harouny" />
      <div className="w-full flex justify-between items-center gap-5 mb-7">
        <h2 className=" text-start text-2xl font-bold ">Students</h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("request_accept")
          )) && (
          <Link
            to="/admin/students/deleted"
            className=" rounded-lg border-2 border-delete bg-delete px-2 py-1 font-semibold text-bg transition-all duration-300 hover:bg-bg hover:text-delete   active:scale-90 "
          >
            Deleted Students <FontAwesomeIcon className="ml-2" icon={faTrash} />
          </Link>
        )}
      </div>

      <div className="flex items-center gap-4">
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("student_add")
          )) && (
          <Link
            to="/admin/students/add-student"
            className="text-white mb-8 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add Student
          </Link>
        )}
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("student_add")
          )) && (
          <Link
            to="/admin/students/add-bulk-students"
            className="text-white mb-8 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add Bulk Students
          </Link>
        )}
      </div>

      {/* <div className={`relative flex  h-10 min-h-[40px] w-1/2 justify-start  `}>
        <button
          onClick={() => {
            setLeft("left-0");
            setAcademiclevel("9");
          }}
          className="z-10 h-full w-1/4 text-base font-semibold "
        >
          Stage 9
        </button>
        <button
          onClick={() => {
            setLeft("left-1/4");
            setAcademiclevel("10");
          }}
          className="z-10 h-full w-1/4 text-base font-semibold "
        >
          Stage 10
        </button>
        <button
          onClick={() => {
            setLeft("left-2/4");
            setAcademiclevel("11");
          }}
          className="z-10 h-full w-1/4 text-base font-semibold "
        >
          Stage 11
        </button>

        <button
          onClick={() => {
            setLeft("left-3/4");
            setAcademiclevel("12");
          }}
          className="z-10 h-full w-1/4 text-base font-semibold "
        >
          Stage 12
        </button>
        <div
          className={`std-selector absolute top-0 h-full w-1/4 ${left} rounded-md bg-green duration-300`}
        ></div>
      </div> */}

      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default Students;
