import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { useState } from "react";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [sent, setSent] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });

  const onSubmit = (data) => {
    setSent(!sent);
  };
  return (
    <section className="flex flex-col justify-center items-center gap-12 md:gap-6 h-screen">
      <div className="flex flex-col justify-center items-center gap-4">
        <img
          className="h-[55px] w-auto cursor-pointer "
          src="https://api.sciencetoonz.com/uploads/ST%20Transparent.png"
          alt="logo"
          onClick={() => navigate("/")}
        />
        <h1 className="text-2xl font-bold">Reset Password</h1>
        {!sent ? (
          <p className="text-base opacity-80">
            Please enter your mobile number and we will send you the reset
            password code.
          </p>
        ) : (
          ""
        )}
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-center items-start  bg-white p-10  rounded-3xl shadow-lg shadow-blue/50 w-[450px] mx-auto"
      >
        {/** Mobile Number field */}

        {!sent ? (
          <>
            <div className="w-full">
              <div className="  flex flex-col justify-center items-start gap-2 w-full">
                <input
                  className="signin-inputs w-full "
                  type="text"
                  id="mobilenumberreset"
                  placeholder="Mobile Number"
                  name="mobilenumberreset"
                  autoComplete="on"
                  {...register("mobilenumberreset", {
                    required: true,
                    pattern: /^[\d]{11}/,
                    maxLength: 11,
                  })}
                />
              </div>
              {errors.mobilenumberreset && (
                <p className="text-red-500 text-xs pt-4">
                  {errors.mobilenumberreset.type === "required" &&
                    "This field is required."}
                  {errors.mobilenumberreset.type === "pattern" &&
                    " Invalid Mobile Number."}
                  {errors.mobilenumberreset.type === "maxLength" &&
                    " Invalid Mobile Number."}
                </p>
              )}
            </div>
            <input
              className=" submit w-full mt-10 "
              type="submit"
              value="Send Code"
            />
          </>
        ) : (
          <>
            <p className="text-xl font-semibold">
              The code has been sent, Check your mobile
            </p>
            <p
              className="text-sm font-semibold mt-6 underline cursor-pointer"
              onClick={() => navigate("/signin", { replace: true })}
            >
              Return to sign in page
            </p>
          </>
        )}
      </form>
    </section>
  );
}
