function AboutTeacher() {
  return (
    <section className="py-6 bg-red-5 overflow-hidden text-fontBlack">
      <h2 className="text-center text-4xl font-bold mb-12">
        About Mr. Ahmed Harouny
      </h2>
      <div className=" component-container width h-full bg-red-20  flex justify-between items-start md:flex-col">
        <div className="flex-1">
          <div className="img-container-relative-rounded-bottom relative h-full bg-transparent flex justify-center rounded-b-full ">
            <img
              //!teacher img
              className="w-full translate-y-[10px]  z-10  ease-in-out"
              src="../assets/teacher2.png"
              alt="teacher3"
            />
          </div>
        </div>

        <div className="flex-[1.3]">
          <div className="details w-full bg-red-10 flex flex-col items-start h-full  justify-center gap-6 ">
            <div className="detail1 flex justify-start items-center gap-4">
              <p className="text-2xl font-bold sm:text-center">
                Welcome to Science Toonz (www.sciencetoonz.com)!
              </p>
            </div>
            <div className="detail1 flex justify-start items-center gap-4">
              <p className="text-lg font-semibold">
                At Science Toonz, our primary aim is to provide engaging,
                comprehensive, and sufficient materials for IGCSE students in
                the scientific subjects, with a special focus on physics. Our
                offerings include instructional videos and solution guides
                filled with animations that facilitate understanding of the
                subject matter and problem-solving techniques.{" "}
              </p>
            </div>
            <div className="detail1 flex justify-start items-center gap-4">
              <p className="text-lg font-semibold">
                In addition to educational resources, we also offer exam papers
                for students, which we review to provide continuous assessment
                throughout the course.
              </p>
            </div>
            <div className="detail1 flex justify-start items-center gap-4">
              <p className="text-lg font-semibold">
                The concept of Science Toonz originated from its founder, Mr.
                Ahmed Elharouny. Mr. Elharouny gained recognition within the
                IGCSE community for his engaging teaching style and
                incorporation of animations to aid understanding in physics. The
                initiative began with a Facebook page called "IGCSE 4 Arab
                Students," where Mr. Elharouny responded to students' questions
                through video explanations, gradually evolving to providing
                comprehensive video solutions for all questions.
              </p>
            </div>
            <div className="detail1 flex justify-start items-center gap-4">
              <p className="text-lg font-semibold">
                With the onset of the COVID-19 pandemic and the rise of remote
                learning, the culture of online education surged, leading to
                increased trust in Mr. Elharouny as the go-to educator with
                complete materials and videos suitable for online learning.
              </p>
            </div>
            <div className="detail1 flex justify-start items-center gap-4">
              <p className="text-lg font-semibold">
                Our goal is to provide sufficient materials to help IGCSE
                students understand scientific subjects in an engaging,
                beautiful, and organized manner. Mr. Elharouny has taught in
                several Egyptian schools and, upon gaining popularity in Arab
                countries, decided to establish Science Toonz and is currently
                based in the United Arab Emirates.
              </p>
            </div>
            <div className="detail1 flex justify-start items-center gap-4">
              <p className="text-lg font-semibold">
                We aspire to be the leading platform in providing animated
                videos for scientific subjects in the IGCSE curriculum. Our
                services are tailored to Arabic-speaking IGCSE students
                worldwide.
              </p>
            </div>
            <div className="detail1 flex justify-start items-center gap-4">
              <p className="text-lg font-semibold">
                Thank you for choosing Science Toonz as your educational partner
                on your journey to academic excellence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutTeacher;
