import { useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";

const AdminCorrection = () => {
  const [correctionType, setCorrectionType] = useState("answeredStudents");
  const table = useTable(correctionType, "", "");

  return (
    <div className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      <HelmetTags title="Correction | Mr.Ahmed Harouny" />

      <h2 className=" text-start text-2xl font-bold mb-8">Correction</h2>

      <div
        className={`filters flex gap-8  items-center w-1/2 md:w-full md:flex-col md:px-6 h-12 relative mb-6 transition-all duration-200 ease-in-out`}
      >
        <button
          onClick={() => setCorrectionType("answeredStudents")}
          className={`z-10 h-full w-1/4 text-base font-semibold md:w-full ${
            correctionType === "answeredStudents" &&
            "rounded-md bg-green duration-300"
          } `}
        >
          Answered
        </button>
        <button
          onClick={() => setCorrectionType("unAnsweredStudents")}
          className={`z-10 h-full w-1/4 text-base font-semibold  md:w-full ${
            correctionType === "unAnsweredStudents" &&
            "rounded-md bg-green duration-300"
          } `}
        >
          Unanswered
        </button>
      </div>

      <div className="h-fit  w-full">{table}</div>
    </div>
  );
};

export default AdminCorrection;
