import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";
import HelmetTags from "../../../../MainComponents/HelmetTags";

const AddQuestionBankTitle = ({ edit }) => {
  const [values, setValues] = useState(null);
  const { titleID } = useParams();
  const [allLessons, setAllLessons] = useState([]);

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditTitle": {
        return {
          ...state,
          submitAddEditTitle: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditTitle: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_UPATE_TITLE_QUESTION_API
    : process.env.REACT_APP_ADD_QUESTION_BANK_TITLE_API;

  //!--------- add edit Course -------
  const [TitleAddEditSuccess, SessionAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditTitle.flag,
    state.submitAddEditTitle.dependency,
    state.submitAddEditTitle.data,
    true
  );

  //!--------- get the units info for editing -------
  const [LessonInfo, LessonErrors] = useAxios(
    process.env.REACT_APP_GET_ALL_LESSONS_QUESTION_API,
    "GET",
    "GET",
    ""
  );

  useEffect(() => {
    if (LessonInfo) {
      setAllLessons(LessonInfo.data);
    }
  }, [LessonInfo]);

  //!--------- get the Course info for editing -------

  const [TitleInfo, TitleInfoErrors] = useAxios(
    `${process.env.REACT_APP_GET_ALL_TITLES_QUESTION_API}/${titleID}`,
    "GET",
    titleID,
    titleID
  );

  useEffect(() => {
    if (TitleInfo) {
      let temp = TitleInfo.data;
      setValues({
        ...temp,
      });
    }
  }, [TitleInfo]);

  useEffect(() => {
    //!---add actions ----

    if (TitleAddEditSuccess) {
      navigate(-1);
      reset();
    }
  }, [TitleAddEditSuccess]);

  const navigate = useNavigate();

  const defaultValues = TitleInfo?.data;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
    values: defaultValues,
  });

  const onSubmit = (data) => {
    const finalData = {
      ...data,
      id: titleID,
    };

    dispatch({
      type: "setSubmitAddEditTitle",
      payload: {
        flag: "AddEditSession",
        dependency: !state.submitAddEditTitle.dependency,
        data: finalData,
      },
    });
  };
  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Add Title | Mr.Ahmed Harouny"></HelmetTags>{" "}
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-10 text-center text-2xl font-semibold">
          {edit ? "Edit Title" : "Add Title"}
        </h2>

        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          method="post"
          className="flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
        >
          <div className="w-full flex flex-col items-start justify-center gap-2 md:w-full">
            <label className="w-full truncate" htmlFor="lesson_id">
              Lessons
            </label>

            <select
              name="lesson_id"
              id="lesson_id"
              {...register("lesson_id", {
                required: true,
              })}
            >
              {allLessons?.map((lesson) => (
                <option value={lesson.id}>{lesson.name}</option>
              ))}
            </select>

            {errors === "lesson_id" && (
              <p className="text-xs text-red-500">This field is required. </p>
            )}
            {
              //!-------server errors -----

              SessionAddEditErrors &&
                SessionAddEditErrors?.response?.data?.errors?.lesson_id && (
                  <p className=" text-xs text-red-500  ">
                    {SessionAddEditErrors?.response?.data?.errors?.lesson_id[0]}
                  </p>
                )
            }
          </div>

          {/** Name filed */}
          <div className="flex flex-col items-start justify-center  gap-2  w-full">
            <label htmlFor="title">Title</label>
            <input
              className="signin-inputs"
              type="text"
              id="title"
              placeholder="title"
              name="title"
              autoComplete="on"
              {...register("title", {
                required: true,
                //pattern: /^[A-Za-z]+$/,
                maxLength: 80,
                minLength: 3,
              })}
            />
            {errors.title && (
              <p className=" pt-2 text-xs text-red-500  ">
                {errors.title.type === "required" && "This field is required."}
                {errors.title.type === "pattern" &&
                  "Only English letters are allowed."}
                {errors.title.type === "maxLength" &&
                  "Allowed Name max length is 80."}
                {errors.title.type === "minLength" &&
                  "Please enter at least 3 letters."}
              </p>
            )}

            {
              //!-------server errors -----

              SessionAddEditErrors &&
                SessionAddEditErrors?.response?.data?.errors?.title && (
                  <p className=" pt-2 text-xs text-red-500  ">
                    {SessionAddEditErrors?.response?.data?.errors?.title[0]}
                  </p>
                )
            }
          </div>

          {/** submit */}
          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditTitle.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditTitle.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>{edit ? "Confirm Editing The Title" : "Add The Title"}</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default AddQuestionBankTitle;
