import { useState } from "react";
import QuestionModalAnswer from "./QuestionModalAnswer";
import useAxios from "../../MainComponents/Hooks/useAxios";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../MainComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faCircleCheck,
  faCircleXmark,
  faSpinner,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../MainComponents/HelmetTags";
import ModalAnswerPercentageSidebar from "./ModalAnswerPercentageSidebar";

const ModalAnswer = ({ previousHomework, exam }) => {
  const [collapse, setCollapse] = useState(false);
  const [activeQuestionId, setActiveQuestionId] = useState(null);
  const { contentID, ID } = useParams();
  const navigate = useNavigate();

  const api = previousHomework
    ? process.env.REACT_APP_STUDENT_QUIZZ_PREVIOUS_QUESTIONS_MODAL_ANSWER_API
    : exam
    ? process.env.REACT_APP_STUDENT_TRIAL_EXAM_MODAL_ANSWER_API
    : process.env.REACT_APP_STUDENT_QUIZZ_HOMEWORK_MODAL_ANSWER_API;

  const [modalAnswerData, modalAnswerErrors, modalAnswerLoading] = useAxios(
    `${api}/${contentID ? contentID : ID}`,
    "GET",
    "GET",
    ""
  );

  function handleOnClickQuestion(id) {
    handelOpenCollapse();
    setActiveQuestionId(id);
  }

  function handelOpenCollapse() {
    setCollapse(true);
  }

  function handelCloseCollapse() {
    setCollapse(false);
  }

  return (
    <div className="left-response-container">
      <HelmetTags title="Modal Answer | Mr.Ahmed Harouny"></HelmetTags>

      <div className="all-questions flex h-full flex-col items-center overflow-y-auto  bg-light p-[40px] text-light transition-all  duration-300 ease-in-out w-full md:order-2 md:w-full">
        {modalAnswerLoading && <Loader />}

        <div className="flex w-full gap-4 md:flex-col">
          <div className="w-3/4 md:w-full">
            {modalAnswerErrors && (
              <div className="flex justify-center flex-col mt-8">
                <p>{modalAnswerErrors?.response?.data?.message}</p>
                <button
                  onClick={() => navigate(-1)}
                  className="flex justify-center my-6"
                >
                  <div className="collapse-btn top-[96px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-green text- shadow-md shadow-black/40 duration-300 hover:scale-110 hover:text-light">
                    <FontAwesomeIcon
                      className={`transition-all duration-300 ease-in-out hover:scale-110`}
                      icon={faChevronRight}
                    />
                  </div>
                </button>
              </div>
            )}
            {!modalAnswerLoading && !modalAnswerErrors && (
              <div className="flex items-center flex-col">
                <h1 className="text-[20px] font-bold text-secondary text-center ">
                  Modal Answer
                </h1>
                <p className="my-[20px] text-center text-secondary">
                  Click on the question number to see its answer
                </p>

                <div className="response-questions myactual-questions-container my-[40px] w-1/2">
                  {modalAnswerData?.data?.questions?.map((question, index) => (
                    <div
                      key={index}
                      onClick={() => handleOnClickQuestion(index)}
                      className={`single-ques-square  mb-[4px] flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-md text-[18px] font-bold text-[#fff] ${
                        question.is_correct ? "bg-lime-500" : "bg-secondary"
                      }  `}
                    >
                      {index + 1}
                    </div>
                  ))}
                </div>
                <div className="indicators flex w-full items-center justify-center gap-[20px] border-t-2 border-t-light py-[20px] sm:flex-col">
                  <div className="correct flex items-center gap-[8px] justify-self-center text-[18px] text-lime-500">
                    <FontAwesomeIcon icon={faCircleCheck} />
                    <p>Correct Answer</p>
                  </div>
                  <div className="correct flex items-center gap-[8px] justify-self-center text-[18px] text-secondary">
                    <FontAwesomeIcon icon={faCircleXmark} /> <p>Wrong Answer</p>
                  </div>
                  {/* <div className="correct flex items-center gap-[8px] justify-self-center text-[18px] text-amber-400">
                <FontAwesomeIcon icon={faSpinner} spinPulse />{" "}
                <p>Waiting For Correction</p>
              </div> */}
                </div>
              </div>
            )}
            {modalAnswerData?.data.v_model !== 0 && (
              <QuestionModalAnswer
                collapse={collapse}
                onCloseCollapse={handelCloseCollapse}
                activeQuestionId={activeQuestionId}
                question={modalAnswerData?.data.questions[activeQuestionId]}
              />
            )}
          </div>
          <ModalAnswerPercentageSidebar
            percentageInfo={modalAnswerData?.data}
          />
        </div>
      </div>
    </div>
    // <></>
  );
};

export default ModalAnswer;
