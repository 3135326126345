import { useState, useEffect, useReducer } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Col, Row, Popconfirm } from "antd";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";
import { QuestionBankFilter } from "./QuestionBankFilter";

function QuestionBank() {
  const AuthState = useAuthState();
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [reFetch, setReFetch] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditSession": {
        return {
          ...state,
          submitAddEditSession: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditSession: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const [AddFilterSuccess, AddFilterErrors, AddFilterLoading] = useAxios(
    process.env.REACT_APP_FILTER_QUESTION_BANK_BY_CATEGORIY_API,
    "POST",
    state.submitAddEditSession.flag,
    state.submitAddEditSession.dependency,
    state.submitAddEditSession.data,
    false,
    reFetch
  );

  //!--------- delete Question -------
  const [deleteQuestionSuccess] = useAxios(
    process.env.REACT_APP_DELETE_QUESTION_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );

  useEffect(() => {
    //! refetch the data on success delete Question

    if (deleteQuestionSuccess) {
      setReFetch(!reFetch);
    }
  }, [deleteQuestionSuccess, reFetch]);

  function handleDelete(id) {
    setSubmitDelete({
      flag: "deleteQuestion",
      dependency: !submitDelete.dependency,
      data: { id },
    });
  }

  // if (AddFilterLoading) return <Loader />;

  return (
    <section className="h-full w-full overflow-y-auto px-10 py-10 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Question Bank | Mr. Ahmed Harouny"></HelmetTags>
      <div className="mb-12 flex flex-col items-start gap-10">
        <h2 className="w-full text-start text-3xl font-bold">Question Bank</h2>
        <div>
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("questionbank_addcategory")
            )) && (
            <>
              <div className="flex items-center gap-2">
                <Link
                  to="/admin/question-bank/all-units"
                  className="text-white rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
                >
                  Units
                </Link>
                <Link
                  to="/admin/question-bank/all-lessons"
                  className="text-white rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
                >
                  Lessons
                </Link>
                <Link
                  to="/admin/question-bank/all-titles"
                  className="text-white rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
                >
                  Titles
                </Link>
                <Link
                  to="/admin/question-bank/all-years"
                  className="text-white  rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
                >
                  Year and Variant
                </Link>
                {/* <Link
                  to="/admin/question-bank/all-question-number"
                  className="text-white rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
                >
                  Question Number
                </Link> */}
              </div>

              <hr className="my-6" />

              <div className="flex items-center gap-2">
                <Link
                  to="/admin/question-bank/add-choose-question"
                  className="text-white rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
                >
                  <FontAwesomeIcon className="mr-4" icon={faPlus} />
                  Add New Question
                </Link>
                <Link
                  to="/admin/question-bank/exams"
                  className="text-white rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
                >
                  Exams
                </Link>
              </div>
            </>
          )}
        </div>
      </div>

      <QuestionBankFilter
        state={state}
        dispatch={dispatch}
        AddFilterSuccess={AddFilterSuccess}
        AddFilterErrors={AddFilterErrors}
        AddFilterLoading={AddFilterLoading}
      />

      {AddFilterSuccess?.data?.map((question, index) => (
        <div
          key={index}
          className="singleQuestionWrapper mt-8 rounded-3xl bg-white "
        >
          <div className="optionsQuestionWrapper">
            <div className="questionOptions">
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#ffffff" //okButtonProps={{ loading: loading }}
                onConfirm={() => handleDelete(question.key)}
                title="Delete The Question?"
              >
                <button className="deleteQuestionBtn">Delete</button>
              </Popconfirm>
              <Link
                to={`/admin/question-bank/edit-choose-question/${question.key}${search}`}
                className="editQuestionBtn"
              >
                Edit
              </Link>
            </div>
            <div className="categoryQuestionWrapper flex gap-4">
              <p className="font-bold">
                <span>Question: {question?.n} - </span>
                <span> {question.year} - </span>
                <span>{question.qnumber} - </span>
                <span>{question.unit} - </span>
                <span>{question.lesson} - </span>
                <span>{question.title} - </span>
                <span>{question?.difficulty}</span>
              </p>
              {question.question ? (
                <p
                  className="essayTitleWrapper"
                  dangerouslySetInnerHTML={{
                    __html: question.question,
                  }}
                />
              ) : (
                <a href={question.URL}>{question.filename}</a>
              )}
            </div>
          </div>
          {question.is_essay ? (
            ""
          ) : (
            <div className="categoryAnswersWrapper">
              <div
                className={`categorySingleAnswer answer1Wrapper ${
                  question.correct_answer === "answer1" && "correctSingleAnswer"
                }`}
              >
                <p>Answer 1</p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: question.answer1,
                  }}
                />
              </div>
              <div
                className={`categorySingleAnswer answer2Wrapper ${
                  question.correct_answer === "answer2" && "correctSingleAnswer"
                }`}
              >
                <p>Answer 2</p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: question.answer2,
                  }}
                />
              </div>
              <div
                className={`categorySingleAnswer answer3Wrapper ${
                  question.correct_answer === "answer3" && "correctSingleAnswer"
                }`}
              >
                <p>Answer 3</p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: question.answer3,
                  }}
                />
              </div>
              <div
                className={`categorySingleAnswer answer4Wrapper ${
                  question.correct_answer === "answer4" && "correctSingleAnswer"
                }`}
              >
                <p>Answer 4</p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: question.answer4,
                  }}
                />
              </div>
            </div>
          )}
          {question.hint && (
            <div
              dangerouslySetInnerHTML={{
                __html: question?.hint,
              }}
            />
          )}
        </div>
      ))}

      {/* <Row gutter={[20, 20]}>
        {questionsBankCategoriesData?.data?.map((question, i) => (
          <Col xs={24} md={24} lg={12} key={i}>
            <div className=" w-full flex items-center justify-between border-2 border-secondary p-6 rounded-xl ">
              <Link
                to={`/admin/question-bank/sub-catoegory/${question.key}`}
                className="font-bold"
              >
                {question.name}
              </Link>
              <div className="flex items-center gap-2">
                <Link
                  to={`/admin/question-bank/${question.key}/edit`}
                  className="bg-secondary text-white p-2 px-7 rounded-xl border-2 border-secondary hover:bg-transparent duration-300 hover:scale-110 hover:text-secondary "
                >
                  Edit
                </Link>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={() => handleDeleteCategory(question.key)}
                  title="Are you sure you want to delete?"
                >
                  <button className="bg-delete text-white p-2 px-7 rounded-xl border-2 border-delete hover:bg-transparent duration-300 hover:scale-110 hover:text-secondary ">
                    Delete
                  </button>
                </Popconfirm>
              </div>
            </div>
          </Col>
        ))}
      </Row> */}
    </section>
  );
}

export default QuestionBank;
