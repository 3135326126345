import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";
import HelmetTags from "../../../../MainComponents/HelmetTags";

const AddQuestionBankQuestionNumber = ({ edit }) => {
  const [values, setValues] = useState(null);
  const [allUnits, setAllUnits] = useState([]);
  const { lessonID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditSession": {
        return {
          ...state,
          submitAddEditSession: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditSession: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_UPATE_LESSON_QUESTION_API
    : process.env.REACT_APP_ADD_QUESTION_LESSON_API;

  //!--------- add edit Course -------
  const [LessonAddEditSuccess, SessionAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditSession.flag,
    state.submitAddEditSession.dependency,
    state.submitAddEditSession.data,
    true
  );

  //!--------- get the Course info for editing -------
  const [LessonInfo, LessonInfoErrors] = useAxios(
    `${process.env.REACT_APP_GET_ALL_LESSONS_QUESTION_API}/${lessonID}`,
    "GET",
    lessonID,
    lessonID
  );

  useEffect(() => {
    if (LessonInfo) {
      let temp = LessonInfo.data;
      setValues({
        ...temp,
      });
    }
  }, [LessonInfo]);

  //!--------- get the units info for editing -------
  const [UnitsInfo, UnitsErrors] = useAxios(
    process.env.REACT_APP_GET_ALL_UNTIS_QUESTION_API,
    "GET",
    "GET",
    ""
  );

  useEffect(() => {
    if (UnitsInfo) {
      setAllUnits(UnitsInfo.data);
    }
  }, [UnitsInfo, setAllUnits]);

  useEffect(() => {
    //!---add actions ----

    if (LessonAddEditSuccess) {
      navigate(-1);
      reset();
    }
  }, [LessonAddEditSuccess]);

  const navigate = useNavigate();

  const defaultValues = LessonInfo?.data;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
    values: defaultValues,
  });

  const onSubmit = (data) => {
    const finalData = {
      ...data,
    };

    dispatch({
      type: "setSubmitAddEditSession",
      payload: {
        flag: "AddEditSession",
        dependency: !state.submitAddEditSession.dependency,
        data: finalData,
      },
    });
  };
  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags Lesson="Add Lesson | Mr.Ahmed Harouny"></HelmetTags>{" "}
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-10 text-center text-2xl font-semibold">
          {edit ? "Edit Lesson" : "Add Lesson"}
        </h2>

        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          method="post"
          className="flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
        >
          {/** Name filed */}

          <div className="w-full flex flex-col items-start justify-center gap-2 md:w-full">
            <label className="w-full truncate" htmlFor="unit_id">
              Units
            </label>

            <select
              name="unit_id"
              id="unit_id"
              {...register("unit_id", {
                required: true,
              })}
            >
              <option hidden value="">
                unit
              </option>{" "}
              {allUnits?.map((unit) => (
                <option value={unit.id}>{unit.name}</option>
              ))}
            </select>

            {errors === "unit" && (
              <p className="text-xs text-red-500">This field is required. </p>
            )}
            {
              //!-------server errors -----

              SessionAddEditErrors &&
                SessionAddEditErrors?.response?.data?.errors?.unit && (
                  <p className=" text-xs text-red-500  ">
                    {SessionAddEditErrors?.response?.data?.errors?.unit[0]}
                  </p>
                )
            }
          </div>

          <div className="flex flex-col items-start justify-center  gap-2  w-full">
            <label htmlFor="name">Lesson Name</label>
            <input
              className="signin-inputs"
              type="text"
              id="name"
              placeholder="Lesson Name"
              name="name"
              autoComplete="on"
              {...register("name", {
                required: true,
                //pattern: /^[A-Za-z]+$/,
                maxLength: 80,
                minLength: 3,
              })}
            />
            {errors.name && (
              <p className=" pt-2 text-xs text-red-500  ">
                {errors.name.type === "required" && "This field is required."}
                {errors.name.type === "pattern" &&
                  "Only English letters are allowed."}
                {errors.name.type === "maxLength" &&
                  "Allowed Name max length is 80."}
                {errors.name.type === "minLength" &&
                  "Please enter at least 3 letters."}
              </p>
            )}

            {
              //!-------server errors -----

              SessionAddEditErrors &&
                SessionAddEditErrors?.response?.data?.errors?.name && (
                  <p className=" pt-2 text-xs text-red-500  ">
                    {SessionAddEditErrors?.response?.data?.errors?.name[0]}
                  </p>
                )
            }
          </div>

          {/** submit */}
          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditSession.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditSession.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>{edit ? "Confirm Editing The Lesson" : "Add The Lesson"}</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default AddQuestionBankQuestionNumber;
