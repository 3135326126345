import { useEffect } from "react";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { useForm } from "react-hook-form";
import { useReducer } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useParams } from "react-router-dom";

export const SubmitEssayForm = ({ homework, dispatchQuiz }) => {
  const AuthState = useAuthState();
  const { ID, courseID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitEditPhoto": {
        return {
          ...state,
          submitEditPhoto: action.payload,
        };
      }
      case "setUploadedImgSrc": {
        return {
          ...state,
          uploadedImgSrc: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitEditPhoto: {
      flag: "",
      dependency: false,
      data: {},
    },
    uploadedImgSrc: null,
  });

  const submitApi = homework
    ? process.env.REACT_APP_STUDENT_SUBMIT_ESSAY_HOMEWORK_API
    : process.env.REACT_APP_STUDENT_SUBMIT_ESSAY_QUIZ_API;

  const [SubmitEssaySuccess, SubmitEssayErrors, SubmitEssayLoading] = useAxios(
    submitApi,
    "POST",
    state.submitEditPhoto.flag,
    state.submitEditPhoto.dependency,
    state.submitEditPhoto.data,
    true
  );
  useEffect(() => {
    if (SubmitEssaySuccess) {
      URL.revokeObjectURL(state.uploadedImgSrc);
      dispatchQuiz({ type: "setRefetch", payload: true });
    }
  }, [SubmitEssaySuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: "onChange",
  });
  useEffect(() => {
    const subscription = watch((input) => {
      if (input.answer[0]?.name?.length > 0) {
        let imageUrl = URL.createObjectURL(input.answer[0]);
        dispatch({
          type: "setUploadedImgSrc",
          payload: imageUrl,
        });
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);
  const onSubmit = (data) => {
    dispatch({
      type: "setSubmitEditPhoto",
      payload: {
        flag: "SubmitEditPhoto",
        dependency: !state.submitEditPhoto.dependency,
        data: { id: ID, answer: data.answer[0] },
      },
    });
  };
  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50 my-6"
    >
      {/**  Profile Picture  */}
      <div className="w-full flex flex-col gap-4">
        <div className="w-fit ">
          <label htmlFor="answer">Upload Your Answer</label>
        </div>
        <input
          id="answer"
          className="signin-inputs w-full"
          type="file"
          accept="application/pdf"
          name="answer"
          {...register("answer", {
            required: true,
            validate: (value) => !(value[0]?.size > 5000000),
          })}
        />

        {errors.answer && (
          <p className="pt-4 text-xs text-red-500">
            {errors.answer.type === "required" && "This field is required."}
            {errors.answer.type === "validate" && "Max Image Size is 5MB."}
          </p>
        )}
        {
          //!-------Editing server errors -----

          SubmitEssayErrors?.response?.data?.errors &&
            SubmitEssayErrors?.response?.data?.errors?.answer && (
              <p className="pt-4 text-xs text-red-500">
                {SubmitEssayErrors?.response?.data.errors.answer[0]}
              </p>
            )
        }
      </div>

      {/** Submit Button */}

      <button
        disabled={
          !isValid || (state.submitEditPhoto.flag && SubmitEssayLoading)
        }
        className=" submit mt-6 w-full "
        type="submit"
      >
        {state.submitEditPhoto.flag && SubmitEssayLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          "Upload Answer"
        )}
      </button>
    </form>
  );
};
