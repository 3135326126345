import { useEffect, useMemo, useReducer } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";

import QuizHeader from "./component/quizHeader/QuizHeader";
import { DisplayQuestion } from "./component/displayQuestion/DisplayQuestion";
import Question from "./component/displayQuestion/Question";
import QuizFooter from "./component/quizFooter/QuizFooter";
import RightQuestionsSidebar from "./component/quizHeader/RightQuestionsSidebar";
import DisplayAnswers from "./component/displayAnswers/DisplayAnswers";
import QuestionsAnswers from "./component/displayAnswers/QuestionsAnswers";
import PercentageSidebar from "./component/displayAnswers/PercentageSidebar";
import { Loader } from "../../../MainComponents";
import { SubmitEssayForm } from "./component/SubmitEssayForm";

const initialState = {
  loading: true,
  currentQuestionIndex: 0,
  collapse: false,
  questions: [],
  essayQuestions: [],
  quizInfo: {},
  answeredQuestions: [],
  finishQuizData: {},
  numOfSelected: 0,
  isShowAnswers: false,
  refetch: false,
  fetchDependance: "",
  sumbitLoading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "setLoading": {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case "setCollapse": {
      return {
        ...state,
        collapse: !state.collapse,
      };
    }

    case "setQuestions": {
      return {
        ...state,
        questions: action.payload,
      };
    }

    case "setEssayQuestions": {
      return {
        ...state,
        essayQuestions: action.payload,
      };
    }

    case "setQuizInfo": {
      return {
        ...state,
        quizInfo: action.payload,
      };
    }

    case "setCurrentQuestionIndex": {
      return {
        ...state,
        collapse: false,
        currentQuestionIndex: action.payload,
      };
    }

    case "setIsShowAnswers": {
      return {
        ...state,
        isShowAnswers: true,
      };
    }
    case "setSumbitLoading": {
      return {
        ...state,
        sumbitLoading: action.payload,
      };
    }

    case "setIsSelectedAnswer": {
      return {
        ...state,
        questions: state.questions.map((question) =>
          question.id === action.payload.questionId
            ? {
                ...question,
                selected_answer: action.payload.answer,
                is_selected: action.payload.questionId,
                n: action.payload.n,
              }
            : question
        ),
      };
    }

    case "setAnsweredQuestions": {
      // Check if answer already exists
      const answerExists = state.answeredQuestions.some(
        (answer) => answer.id === action.payload.id
      );

      // not exist
      if (!answerExists) {
        return {
          ...state,
          answeredQuestions: [...state.answeredQuestions, action.payload],
        };
      } else {
        // Answer already exists, don't add
        return {
          ...state,
          answeredQuestions: state.answeredQuestions.map((question) =>
            question.id === action.payload.id
              ? {
                  ...question,
                  order: action.payload.order,
                  id: action.payload.id,
                  n: action.payload.n,
                }
              : question
          ),
        };
      }
    }

    case "setFinishQuizData": {
      return {
        ...state,
        finishQuizData: action.payload,
      };
    }

    case "setAllData": {
      return {
        ...initialState,
      };
    }
    case "setRefetch": {
      return {
        ...state,
        refetch: action.payload,
      };
    }

    default:
      throw Error("Unknown action: " + action.type);
  }
}
const QuizNew = ({ homework, exam }) => {
  const { ID, courseID } = useParams();

  const [state, dispatch] = useReducer(reducer, initialState);

  // questions apis
  const previousHomeworkQuestionsApi =
    process.env.REACT_APP_STUDENT_PREVIOUS_HOMEWORK_API;

  const checkApi = homework
    ? process.env.REACT_APP_STUDENT_HOMEWORK_API
    : exam
    ? process.env.REACT_APP_STUDENT_TRIAL_EXAM_SHOW_QUESTIONS_API
    : process.env.REACT_APP_STUDENT_QUIZZ_API;
  // submit apis
  const previousHomeworkFinishApi =
    process.env.REACT_APP_STUDENT_PREVIOUS_HOMEWORK_FINISH_API;
  const checkFinishApi = homework
    ? process.env.REACT_APP_STUDENT_HOMEWORK_SUBMIT_API
    : exam
    ? process.env.REACT_APP_STUDENT_TRIAL_EXAM_SUBMIT_API
    : process.env.REACT_APP_STUDENT_QUIZZ_SUBMIT_API;

  const [quizData, quizErrors, quizLoading] = useAxios(
    `${
      ID ? `${checkApi}/${ID}` : `${previousHomeworkQuestionsApi}/${courseID}`
    }`,
    "GET",
    "GET",
    state.refetch,
    ""
  );

  useEffect(() => {
    dispatch({
      type: "setQuestions",
      payload: quizData
        ? [...quizData?.choosequestions, ...quizData?.essayquestions]
        : [],
    });

    dispatch({
      type: "setEssayQuestions",
      payload: quizData?.essayquestions,
    });

    dispatch({
      type: "setQuizInfo",
      payload: quizData ? quizData.info : {},
    });

    dispatch({ type: "setRefetch", payload: false });
  }, [quizData]);

  useEffect(() => {
    state.questions
      .filter((question) => question.is_selected === 1)
      .map((question) => {
        dispatch({
          type: "setAnsweredQuestions",
          payload: {
            id: question.id,
            order: question.selected_answer,
            n: question.n,
          },
        });

        return null;
      });
  }, [state.questions]);

  useMemo(() => {
    // set axios
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(localStorage.userData).student_token;
  }, []);

  const onFinishQuiz = () => {
    if (state.questions?.length !== state.answeredQuestions?.length) {
      const notAnsweredQuestions = state.questions.filter((question) => {
        return !state.answeredQuestions.some((obj) => obj.id === question.id);
      });
      dispatch({
        type: "setCurrentQuestionIndex",
        payload: notAnsweredQuestions[0].n - 1,
      });
    } else {
      dispatch({ type: "setSumbitLoading", payload: true });

      async function fetchData() {
        try {
          let res = await axios.post(
            courseID ? previousHomeworkFinishApi : checkFinishApi,
            {
              id: ID,
              course_id: state.quizInfo.course_id,
              answers: state.answeredQuestions,
            }
          );
          dispatch({ type: "setFinishQuizData", payload: res.data.data });
          dispatch({ type: "setIsShowAnswers" });
        } catch (error) {
          toast.error(error.response.data.message);
        } finally {
          dispatch({ type: "setSumbitLoading", payload: false });
        }
      }
      fetchData();
    }
  };

  return (
    <section
      className={`flex h-fit  min-h-screen w-full flex-col items-center ${
        state.isShowAnswers ? "pt-[80px]" : "pt-[128px]"
      }`}
    >
      <QuizHeader
        state={state}
        dispatch={dispatch}
        onFinishQuiz={onFinishQuiz}
        quizErrors={quizErrors}
        quizLoading={quizLoading}
        exam={exam}
        quizData={quizData}
      />

      {quizErrors && (
        <div className="flex justify-center flex-col ">
          <p>{quizErrors}</p>
          <Link
            to={exam ? `/exams/${ID}` : `/home/lectures/sessions/${ID}`}
            className="flex justify-center my-6"
          >
            <div className="collapse-btn top-[96px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-green text-light shadow-md shadow-black/40 duration-300 hover:scale-110 hover:text-light">
              <FontAwesomeIcon
                className={`transition-all duration-300 ease-in-out hover:scale-110 ${
                  state.collapse && "rotate-180"
                }`}
                icon={faChevronRight}
              />
            </div>
          </Link>
        </div>
      )}

      {quizLoading && <Loader />}

      {!quizLoading && !quizErrors && (
        <>
          {quizData?.essayquestions?.length > 0 && (
            <main
              className={`w-3/4 m-auto min-h-full min-h-fit flex-col md:gap-[40px] flex max-w-[1700px] justify-center`}
            >
              {quizData?.essayquestions?.map((question, index) => (
                <>
                  <div
                    key={index}
                    className=" flex items-center justify-between p-4 font-bold border-2 border-secondary rounded-xl"
                  >
                    <p className="flex items-center gap-4">
                      {question.filename}{" "}
                      {question.is_uploaded === 1 && (
                        <span className="bg-delete p-2 px-4 rounded-xl text-white">
                          {question.is_corrected === 0
                            ? "Correction in progress"
                            : ""}
                        </span>
                      )}
                    </p>
                    <a href={question.url} target="_blank" rel="noreferrer">
                      <FontAwesomeIcon
                        className="text-3xl"
                        icon={faFileDownload}
                      />
                    </a>
                  </div>
                  {question.is_uploaded !== 1 && (
                    <SubmitEssayForm
                      homework={homework}
                      dispatchQuiz={dispatch}
                    />
                  )}
                </>
              ))}
            </main>
          )}
          {quizData?.choosequestions?.length > 0 && (
            <main
              id="pdf-content"
              className={`w-full min-h-full min-h-fit md:min-h-fit md:flex-col md:gap-[40px] flex max-w-[1700px] justify-center`}
            >
              {!state.isShowAnswers ? (
                <DisplayQuestion state={state}>
                  <Question
                    state={state}
                    dispatch={dispatch}
                    homework={homework}
                    exam={exam}
                  />
                  <QuizFooter
                    state={state}
                    dispatch={dispatch}
                    onFinishQuiz={onFinishQuiz}
                  />
                </DisplayQuestion>
              ) : (
                <DisplayAnswers>
                  <QuestionsAnswers
                    state={state}
                    dispatch={dispatch}
                    exam={exam}
                  />
                  {state?.finishQuizData.v_model !== 0 && (
                    <PercentageSidebar state={state} />
                  )}
                </DisplayAnswers>
              )}

              {state?.finishQuizData.v_model !== 0 && (
                <RightQuestionsSidebar state={state} dispatch={dispatch} />
              )}
            </main>
          )}
        </>
      )}
    </section>
  );
};

export default QuizNew;
