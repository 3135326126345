import {
  faChevronUp,
  faClose,
  faPen,
  faPlus,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "../../../../../MainComponents/GlobalContext";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { SessionType } from "../AdminCourse";
import useAxios from "../../../../../MainComponents/Hooks/useAxios";

const SingleCollabse = ({
  unitID,
  dispatch,
  state,
  leason,
  submitDeleteLesson,
  setSubmitDeleteLesson,
  handleDragEnd,
  onSubmit,
  addContentErrors,
  submitLoading,
  sensors,
  index,
}) => {
  const AuthState = useAuthState();

  function handleDeleteLeason(id) {
    setSubmitDeleteLesson({
      flag: "deleteExam",
      dependency: !submitDeleteLesson.dependency,
      data: { id: id },
    });
  }

  // useEffect(() => {
  //   //! refetch the data on success delete
  //   if (deleteLeasonSuccess) {
  //     dispatch({
  //       type: "setRefetch",
  //       payload: !state.refetch,
  //     });

  //     console.log("removeeeeeeeeeeeeeeeee");
  //   }
  // }, [deleteLeasonSuccess, dispatch, state.refetch]);

  let contentIndex;

  return (
    <div
      className={`collapse-session transition-{height} duration-700 ease-in-out w-full   `}
    >
      {/**
       * //!---session-title-wrapper-border-y ----
       */}
      <div
        onClick={(e) => {
          if (e.target.parentElement) {
            e.target.parentNode.classList.toggle("collapse-session");
          }
        }}
        className="session-title-wrapper cursor-pointer border-y-2 px-4 flex justify-between items-center gap-5 h-16 bg-edit !border-0 w-[90%] m-auto "
      >
        <div className="icon_title flex justify-start gap-5 items-center font-semibold text-lg pointer-events-none">
          <FontAwesomeIcon
            className="chevron-icon transition-all duration-300 ease-in-out"
            icon={faChevronUp}
          />
          <p className="">
            {leason.name} - {leason.visibility ? "Visible" : "Invisible"}
          </p>
        </div>
        <div className="session-details-left">
          <div className="type-options flex justify-center gap-6 items-center">
            {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture_session_update")
            ) ? (
              <Link to={`/admin/content/edit-leason/${unitID}/${leason.id}`}>
                <div className="type-edit-icon w-7 h-7 flex justify-center items-center border-2 rounded-md hover:scale-110 transition-all duration-300 ease-in-out active:scale-90 hover:bg-blue hover:text-white hover:border-blue">
                  <FontAwesomeIcon icon={faPen} />
                </div>
              </Link>
            ) : null}

            {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture_session_delete")
            ) ? (
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#ffffff"
                okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleDeleteLeason(leason.id)} // session.id
                title="Are you sure you want to delete?"
              >
                <button className="type-edit-icon w-7 h-7  flex justify-center items-center border-2 border-delete text-delete rounded-md hover:scale-110 transition-all duration-300 ease-in-out active:scale-90 hover:bg-delete hover:text-white hover:border-delete">
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </Popconfirm>
            ) : null}
          </div>
        </div>
      </div>
      {/**
       * //!---session-content-----
       */}
      <div className="session-content w-full  transition-all duration-700 ease-in-out h-fit p-4   flex flex-col items-center">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={(event) => handleDragEnd(event, index, contentIndex)}
        >
          <SortableContext
            items={leason.content} // session.session
            strategy={verticalListSortingStrategy}
          >
            {/* Show All Leasons hear  */}
            {leason.content?.map((type, index) => {
              let src;
              if (type.type === "video") {
                src =
                  type.platform === "youtube"
                    ? `https://www.youtube.com/embed/${type.vdo_id}`
                    : type.platform === "vimeo"
                    ? `https://player.vimeo.com/video/${type.vdo_id}`
                    : `https://player.vdocipher.com/v2/?otp=${type.otp}&playbackInfo=${type.playbackInfo}`;
              }

              return (
                <div
                  className="w-full"
                  onMouseEnter={() => (contentIndex = index)}
                >
                  <SessionType
                    key={type.id}
                    src={src}
                    currentVideo={state.currentVideo}
                    dispatch={dispatch}
                    state={state}
                    type={type}
                  />
                </div>
              );
            })}
          </SortableContext>
        </DndContext>

        {/* Add Leason Form  */}
        {state.currentForm === leason.id && ( // index
          <form
            onSubmit={(e) => onSubmit(e, unitID, leason.id)} //session.id
            method="post"
            className="flex   w-10/12  flex-col items-start  justify-center  rounded-3xl bg-white px-4 py-5 shadow-lg shadow-blue/50 mt-4"
          >
            {/**
             *  //! name-type
             * */}
            <div className="name-type flex justify-between  w-full   md:flex-col items-start md:justify-start  md:items-center gap-6">
              {/**
               *  //! name
               * */}
              <div className=" flex w-1/2 md:w-full flex-col items-start justify-center gap-2 ">
                <input
                  className="signin-inputs  w-full "
                  type="text"
                  id="name"
                  placeholder="Content Name"
                  name="name"
                  autoComplete="on"
                  value={state.formName}
                  onChange={(e) =>
                    dispatch({
                      type: "setFormName",
                      payload: e.target.value,
                    })
                  }
                />
                {state.formErrors === "name" && (
                  <p className="text-xs text-red-500">
                    This field is required.
                  </p>
                )}
                {
                  //!-------server errors -----

                  addContentErrors &&
                    addContentErrors?.response?.data?.errors?.name && (
                      <p className=" text-xs text-red-500 ">
                        {addContentErrors?.response?.data?.errors?.name[0]}
                      </p>
                    )
                }
              </div>
              {/**
               *  //! type
               * */}
              <div
                className={`flex w-1/2 md:w-full flex-col items-start justify-center  gap-2 `}
              >
                <select
                  placeholder="ddd"
                  name="type"
                  id="type"
                  value={state.formType}
                  onChange={(e) => {
                    dispatch({
                      type: "setFormType",
                      payload: e.target.value,
                    });
                  }}
                >
                  <option hidden disabled value="">
                    Content Type
                  </option>
                  <option value="quiz">Quiz</option>
                  <option value="video">Video</option>
                  <option value="attachment">Attachment</option>
                  <option value="homework">Homework</option>
                </select>

                {state.formErrors === "type" && (
                  <p className="text-xs text-red-500">
                    This field is required.
                  </p>
                )}
                {
                  //!-------server errors -----

                  addContentErrors &&
                    addContentErrors?.response?.data?.errors?.type && (
                      <p className=" text-xs text-red-500">
                        {addContentErrors?.response?.data?.errors?.type[0]}
                      </p>
                    )
                }
              </div>
            </div>
            {/** submit */}
            <button
              disabled={
                state.formName === "" ||
                state.formType === "" ||
                (submitLoading && state.submitAddContent.flag)
              }
              className=" submit mt-4 w-full "
              type="submit"
            >
              {submitLoading && state.submitAddContent.flag ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "Add Content"
              )}
            </button>
          </form>
        )}

        {/* Add Leason  */}
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_add_content")
          )) && (
          <div className="w-full flex justify-evenly items-center mt-4">
            <button
              onClick={() =>
                dispatch({
                  type: "setCurrentForm",
                  payload: leason.id, // index
                })
              }
              className=" rounded-lg border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-bg transition-all duration-300 hover:bg-bg hover:text-secondary   active:scale-90 text-sm "
            >
              Add Content <FontAwesomeIcon className="ml-1" icon={faPlus} />
            </button>
            {state.currentForm === leason.id && ( // index
              <button
                onClick={() => {
                  dispatch({
                    type: "setCurrentForm",
                    payload: null,
                  });
                  dispatch({
                    type: "setFormErrors",
                    payload: "",
                  });
                  dispatch({
                    type: "setFormName",
                    payload: "",
                  });
                  dispatch({
                    type: "setFormType",
                    payload: "",
                  });
                }}
                className=" rounded-lg border-2 border-delete bg-delete px-2 py-1 font-semibold text-bg transition-all duration-300 hover:bg-bg hover:text-delete   active:scale-90 text-sm "
              >
                Cancel
                <FontAwesomeIcon className="ml-1" icon={faClose} />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleCollabse;
