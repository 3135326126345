import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useReducer, useState } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../MainComponents/HelmetTags.jsx";
import useAxios from "../../../MainComponents/Hooks/useAxios.jsx";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Register({ edit, studentInfo }) {
  const [countrySelected, setCountrySelected] = useState("20");
  const [countryParentSelected, setCountryParentSelected] = useState("20");
  const [mobile, setMobile] = useState("");
  const [parentMobile, setParentMobile] = useState("");

  const [values, setValues] = useState(null);
  const { studentID } = useParams();
  const navigate = useNavigate();
  const [hide, setHide] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
    values,
  });

  function reducer(state, action) {
    switch (action.type) {
      case "setGroups": {
        return {
          ...state,
          groups: action.payload,
        };
      }
      case "setSelectedState": {
        return {
          ...state,
          selectedState: action.payload,
        };
      }
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setHide": {
        return {
          ...state,
          hide: !state.hide,
        };
      }
      case "setSubmitLoading": {
        return {
          ...state,
          submitLoading: action.payload,
        };
      }

      case "setSubmitEditStudent": {
        return {
          ...state,
          submitEditStudent: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    hide: false,
    groups: [],
    selectedState: "",
    errors: {},
    submitLoading: false,
    noCode: false,
    submitEditStudent: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  // const [GetAllCourseSuccsess, GetAllCourseErrors, GetAllCourseLoading] =
  //   useAxios(process.env.REACT_APP_ADMIN_STUDENT_COURSE_API, "GET", "GET", "");

  const [StudentEditSuccess, StudentEditErrors, StudentEditLoading] = useAxios(
    process.env.REACT_APP_EDIT_STUDENT_INFO_API,
    "POST",
    state.submitEditStudent.flag,
    state.submitEditStudent.dependency,
    state.submitEditStudent.data,
    true
  );

  useEffect(() => {
    if (StudentEditSuccess) {
      reset();
      navigate(-1);
    }
  }, [StudentEditSuccess]);

  useEffect(() => {
    if (studentInfo) {
      dispatch({
        type: "setSelectedState",
        payload: studentInfo?.data?.studentinfo?.selected_state,
      });
      setValue("governorate", studentInfo?.data?.studentinfo?.governorate);
      setMobile(studentInfo?.data?.studentinfo.mobile);
      setParentMobile(studentInfo?.data?.studentinfo.parent_mobile);
      setCountrySelected(studentInfo?.data?.studentinfo.mobile_key);
      setCountryParentSelected(studentInfo?.data?.studentinfo.mobileparent_key);
      //setValue("stage", studentInfo?.data?.studentinfo?.stage);
      // delete studentInfo?.data?.studentinfo?.governorate;
      //delete studentInfo?.data?.studentinfo?.stage;
      setValues(studentInfo.data.studentinfo);
    }
  }, [studentInfo]);

  // console.log(values);

  const onSubmit = (data) => {
    const studentData = edit
      ? {
          ...data,
          id: studentID,
          mobile: mobile,
          parent_mobile: parentMobile,
          mobile_key: countrySelected,
          mobileparent_key: countryParentSelected,
        }
      : {
          ...data,
          mobile: mobile,
          parent_mobile: parentMobile,
          mobile_key: countrySelected,
          mobileparent_key: countryParentSelected,
        };

    if (edit) {
      dispatch({
        type: "setSubmitEditStudent",
        payload: {
          flag: "AddEditStudent",
          dependency: !state.submitEditStudent.dependency,
          data: studentData,
        },
      });
    } else {
      dispatch({
        type: "setSubmitLoading",
        payload: true,
      });

      async function postData() {
        try {
          const res = await axios.post(
            process.env.REACT_APP_ADD_STUDENT_API,
            studentData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );

          const data = await res.data;
          toast.success(data.message);
          dispatch({
            type: "setSubmitLoading",
            payload: false,
          });
          !edit && navigate(-1);
          reset();
        } catch (error) {
          dispatch({
            type: "setErrors",
            payload: error.response.data.errors,
          });
          dispatch({
            type: "setSubmitLoading",
            payload: false,
          });
          toast.error(
            "An error has happened while creating a new account, please revise your information!"
          );
        }
      }
      postData();
    }
  };

  return (
    <section className=" w-full flex min-h-screen flex-col items-center justify-center gap-12 py-12 md:gap-6 ">
      <HelmetTags
        title={`${edit ? "Edit" : "Add"} New Student | Mr.Ahmed Harouny`}
        description={`${edit ? "Edit" : "Add"} New Student | Mr.Ahmed Harouny`}
        index
      ></HelmetTags>

      <form
        encType="multipart/form-data"
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
      >
        {/* Serial  */}
        <div className=" flex w-full flex-col items-start justify-center gap-2  md:w-full">
          <label htmlFor="serial">Serial</label>
          <input
            className="signin-inputs  w-full "
            type="number"
            id="serial"
            placeholder="Serial"
            name="serial"
            autoComplete="on"
            {...register("serial", {
              required: true,
            })}
          />
          {errors.serial && (
            <p className="pt-2 text-xs text-red-500">
              {errors.serial.type === "required" && "This field is required."}
              {errors.serial.type === "pattern" &&
                " Only Arabic letters are allowed (Spaces not Allowed)."}
              {errors.serial.type === "maxLength" &&
                "Available name max length is 25."}
              {errors.serial.type === "minLength" &&
                " Please enter at least 3 letters."}
            </p>
          )}
          {
            //!-------server errors -----

            state.errors && state.errors.serial && (
              <p className="pt-2 text-xs text-red-500">
                {state.errors.serial[0]}
              </p>
            )
          }
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.errors?.name_arabic && (
                <p className="pt-2 text-xs text-red-500">
                  {StudentEditErrors?.response?.data.errors.name_arabic[0]}
                </p>
              )
          }
        </div>

        {/** Name filed */}
        <div className="flex w-full items-start justify-between gap-16 md:gap-6 md:flex-col ">
          {/** First Name filed */}
          <div className=" flex w-1/3 flex-col items-start justify-center gap-2  md:w-full">
            <label htmlFor="first_name"> First Name</label>
            <input
              className="signin-inputs w-full "
              type="text"
              id="first_name"
              placeholder="First Name"
              name="first_name"
              autoComplete="on"
              {...register("first_name", {
                required: true,
                pattern: /^[A-Za-z]+$/,
                maxLength: 25,
                minLength: 3,
              })}
            />
            {errors.first_name && (
              <p className="pt-2 text-xs text-red-500">
                {errors.first_name.type === "required" &&
                  "This field is required."}
                {errors.first_name.type === "pattern" &&
                  " Only English letters are allowed (Spaces not Allowed)."}
                {errors.first_name.type === "maxLength" &&
                  "Available name max length is 25."}
                {errors.first_name.type === "minLength" &&
                  " Please enter at least 3 letters."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.first_name && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.first_name[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.first_name && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.first_name[0]}
                  </p>
                )
            }
          </div>
          {/** Second Name filed */}
          <div className=" flex w-1/3 flex-col items-start justify-center gap-2  md:w-full">
            <label htmlFor="second_name">Fathers Name</label>
            <input
              className="signin-inputs  w-full "
              type="text"
              id="second_name"
              placeholder="Fathers Name"
              name="second_name"
              autoComplete="on"
              {...register("second_name", {
                required: true,
                pattern: /^[A-Za-z]+$/,
                maxLength: 25,
                minLength: 3,
              })}
            />
            {errors.second_name && (
              <p className="pt-2 text-xs text-red-500">
                {errors.second_name.type === "required" &&
                  "This field is required."}
                {errors.second_name.type === "pattern" &&
                  " Only English letters are allowed (Spaces not Allowed)."}
                {errors.second_name.type === "maxLength" &&
                  "Available name max length is 25."}
                {errors.second_name.type === "minLength" &&
                  " Please enter at least 3 letters."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.second_name && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.second_name[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.second_name && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.second_name[0]}
                  </p>
                )
            }
          </div>

          {/** Third Name filed */}

          <div className=" flex w-1/3 flex-col items-start justify-center gap-2  md:w-full">
            <label htmlFor="third_name">Last Name</label>
            <input
              className="signin-inputs w-full "
              type="text"
              id="third_name"
              placeholder="Last Name"
              name="third_name"
              autoComplete="on"
              {...register("third_name", {
                required: true,
                pattern: /^[A-Za-z]+$/,
                maxLength: 25,
                minLength: 3,
              })}
            />
            {errors.third_name && (
              <p className="pt-2 text-xs text-red-500">
                {errors.third_name.type === "required" &&
                  "This field is required."}
                {errors.third_name.type === "pattern" &&
                  " Only English letters are allowed (Spaces not Allowed)."}
                {errors.third_name.type === "maxLength" &&
                  "Available name max length is 25."}
                {errors.third_name.type === "minLength" &&
                  " Please enter at least 3 letters."}
              </p>
            )}

            {
              //!-------server errors -----

              state.errors && state.errors.third_name && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.third_name[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.third_name && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.third_name[0]}
                  </p>
                )
            }
          </div>
        </div>

        <div className="flex w-full flex-col items-start justify-center gap-2 md:order-1 md:w-full">
          <label htmlFor="username">Class</label>
          <select
            name="class"
            id="class"
            {...register("class", { required: true })}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
          </select>

          {errors.class && (
            <p className="text-xs text-red-500 ">
              {errors.class.class === "required" && "This field is required."}
            </p>
          )}
          {
            //!-------server errors -----

            StudentEditErrors &&
              StudentEditErrors?.response?.data?.errors?.class && (
                <p className="w-full text-end text-xs text-red-500  ">
                  {StudentEditErrors?.response?.data?.errors?.class[0]}
                </p>
              )
          }
        </div>

        <div className=" flex w-full flex-col items-start justify-center gap-2  md:w-full">
          <label htmlFor="name_arabic">Arabic Name</label>
          <input
            className="signin-inputs  w-full "
            type="text"
            id="name_arabic"
            placeholder="Arabic Name"
            name="name_arabic"
            autoComplete="on"
            {...register("name_arabic", {
              required: true,
              pattern: /^[\u0600-\u06FF\s]+$/,
              maxLength: 25,
              minLength: 3,
            })}
          />
          {errors.name_arabic && (
            <p className="pt-2 text-xs text-red-500">
              {errors.name_arabic.type === "required" &&
                "This field is required."}
              {errors.name_arabic.type === "pattern" &&
                " Only Arabic letters are allowed."}
              {errors.name_arabic.type === "maxLength" &&
                "Available name max length is 25."}
              {errors.name_arabic.type === "minLength" &&
                " Please enter at least 3 letters."}
            </p>
          )}
          {
            //!-------server errors -----

            state.errors && state.errors.name_arabic && (
              <p className="pt-2 text-xs text-red-500">
                {state.errors.name_arabic[0]}
              </p>
            )
          }
          {
            //!-------Editing server errors -----

            StudentEditErrors?.response?.data?.errors &&
              StudentEditErrors?.response?.data?.errors?.name_arabic && (
                <p className="pt-2 text-xs text-red-500">
                  {StudentEditErrors?.response?.data.errors.name_arabic[0]}
                </p>
              )
          }
        </div>

        {/** Username and Email fields */}
        <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-6">
          {/** Email field */}

          <div
            className={`flex  w-full  flex-col items-start justify-center  gap-2 md:w-full`}
          >
            <label htmlFor="email"> Email</label>

            <input
              className="signin-inputs  w-full "
              type="text"
              id="email"
              placeholder="Email"
              name="email"
              autoComplete="on"
              {...register("email", {
                required: true,
                pattern: /^[A-z][A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
            />
            {errors.email && (
              <p className="pt-2 text-xs text-red-500">
                {errors.email.type === "required" && "This field is required."}
                {errors.email.type === "pattern" && " Invalid Email Address."}
              </p>
            )}
            {
              //!-------server errors -----
              state.errors && state.errors.email && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.email[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.email && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.email[0]}
                  </p>
                )
            }
          </div>
        </div>

        {/** Password and Father's Job fileds */}
        {!edit && (
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-6">
            {/** Password  */}

            <div className="w-1/2 md:w-full">
              <div className="relative flex flex-col items-start justify-center gap-2 ">
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  className="signin-inputs w-full"
                  type={`${hide ? "text" : "password"}`}
                  placeholder="Password"
                  name="password"
                  autoComplete="on"
                  {...register("password", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                  })}
                />
                {hide ? (
                  <FontAwesomeIcon
                    onClick={() => setHide(!hide)}
                    className="absolute right-2 bottom-2 h-4 w-4 cursor-pointer"
                    icon={faEye}
                  />
                ) : (
                  <FontAwesomeIcon
                    onClick={() => setHide(!hide)}
                    className="absolute right-2 bottom-2 h-4 w-4 cursor-pointer"
                    icon={faEyeSlash}
                  />
                )}
              </div>
              {errors.password && (
                <p className="pt-4 text-xs text-red-500">
                  {errors.password.type === "required" &&
                    "This field is required."}
                  {errors.password.type === "maxLength" &&
                    "password Max length is 25 char."}
                  {errors.password.type === "minLength" &&
                    "Password Min length is 6 char."}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.password && (
                  <p className="pt-2 text-xs text-red-500">
                    {state.errors.password[0]}
                  </p>
                )
              }
            </div>

            {/** Confirm Password  */}

            <div className="w-1/2 md:w-full">
              <div className="relative flex flex-col items-start justify-center gap-2 ">
                <label htmlFor="password_confirmation">Confirm Password</label>
                <input
                  id="password_confirmation"
                  className="signin-inputs w-full"
                  type={`${hide ? "text" : "password"}`}
                  placeholder="Confirm Password"
                  name="password_confirmation"
                  autoComplete="on"
                  {...register("password_confirmation", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                    validate: (value) => value === getValues("password"),
                  })}
                />
                {hide ? (
                  <FontAwesomeIcon
                    onClick={() => setHide(!hide)}
                    className="absolute right-2 bottom-2 h-4 w-4 cursor-pointer"
                    icon={faEye}
                  />
                ) : (
                  <FontAwesomeIcon
                    onClick={() => setHide(!hide)}
                    className="absolute right-2 bottom-2 h-4 w-4 cursor-pointer"
                    icon={faEyeSlash}
                  />
                )}
              </div>
              {errors.password_confirmation && (
                <p className="pt-4 text-xs text-red-500">
                  {errors.password_confirmation.type === "required" &&
                    "This field is required."}
                  {errors.password_confirmation.type === "maxLength" &&
                    "password_confirmation Max length is 25 char."}
                  {errors.password_confirmation.type === "minLength" &&
                    "Password Min length is 6 char."}
                  {errors.password_confirmation.type === "validate" &&
                    "Passwords don't match."}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.password_confirmation && (
                  <p className="pt-2 text-xs text-red-500">
                    {state.errors.password_confirmation[0]}
                  </p>
                )
              }
            </div>
          </div>
        )}

        {/** student and parents mobile numbers fields */}
        <div className="flex w-full items-start justify-between gap-16 md:gap-6 md:flex-col ">
          {/** Student Mobile field */}

          <div className="w-1/2 md:w-full">
            <div className=" relative flex w-full flex-col items-start justify-center gap-2">
              <label htmlFor="mobile">Student Mobile</label>
              <div className="w-full">
                {/* <ReactFlagsSelect
                  selected={countrySelected}
                  onSelect={(code) => setCountrySelected(code)}
                  className="p-0"
                /> */}

                <PhoneInput
                  country={"253"}
                  value={countrySelected}
                  onChange={(phone) => setCountrySelected(phone)}
                  className="flex items-center signin-inputs w-full ml-0 !pl-2 gap-4 "
                  inputProps={{
                    name: "mobile",
                    value: mobile,
                    onChange: (e) => setMobile(e.target.value),
                    placeholder: "1234567891",
                    className: "ml-[50px] border-0 outline-0",
                    required: true,
                  }}
                />
                {/* <input
                    className=" outline-0"
                    type="number"
                    inputMode="numeric"
                    id="mobile"
                    placeholder="Student Mobile"
                    name="mobile"
                    min="1"
                    autoComplete="on"
                    {...register("mobile", {
                      required: true,
                      pattern: /^[\d]{11}/,
                      // maxLength: 11,
                      min: 1,
                    })}
                  /> */}
              </div>
            </div>
            {errors.mobile && (
              <p className="pt-4 text-xs text-red-500">
                {errors.mobile.type === "required" && "This field is required."}
                {errors.mobile.type === "pattern" && " Invalid Mobile Number."}
                {errors.mobile.type === "maxLength" &&
                  " Invalid Mobile Number."}
                {errors.mobile.type === "min" && " Invalid Mobile Number."}
                {errors.mobile.type === "validate" &&
                  "Please, Don't Enter the Same Mobile Number."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.mobile && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.mobile[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.mobile && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.mobile[0]}
                  </p>
                )
            }
          </div>
          {/** Father's Mobile field */}

          <div className="w-1/2 md:w-full">
            <div className=" relative flex w-full flex-col items-start justify-center gap-2">
              <label htmlFor="parent_mobile">Parents Number</label>

              <PhoneInput
                country={"eg"}
                value={countryParentSelected}
                onChange={(phone) => setCountryParentSelected(phone)}
                className="flex items-center signin-inputs w-full ml-0 !pl-2 gap-4 "
                inputProps={{
                  name: "parent_mobile",
                  value: parentMobile,
                  onChange: (e) => setParentMobile(e.target.value),
                  placeholder: "1234567891",
                  className: "ml-[50px] border-0 outline-0",
                  required: true,
                }}
              />
            </div>
            {errors.parent_mobile && (
              <p className="pt-4 text-xs text-red-500">
                {errors.parent_mobile.type === "required" &&
                  "This field is required."}
                {errors.parent_mobile.type === "pattern" &&
                  " Invalid Mobile Number."}
                {errors.parent_mobile.type === "maxLength" &&
                  " Invalid Mobile Number."}
                {errors.parent_mobile.type === "min" &&
                  " Invalid Mobile Number."}
                {errors.parent_mobile.type === "validate" &&
                  "Please, Don't Enter the Same Mobile Number."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.parent_mobile && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.parent_mobile[0]}
                </p>
              )
            }
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.parent_mobile && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.parent_mobile[0]}
                  </p>
                )
            }
          </div>
        </div>

        {/** Stage  filed */}
        <div className="flex w-full items-end justify-between gap-16 md:flex-col md:items-start md:gap-6 ">
          {/** Stage Level  */}

          <div className=" flex w-1/2 flex-col items-start justify-center gap-2 md:w-full">
            <label htmlFor="stage">Year</label>
            <select
              name="stage"
              id="stage"
              {...register("stage", { required: true })}
            >
              <option hidden></option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
            {errors.stage && (
              <p className="pt-2 text-xs text-red-500">
                {errors.stage.type === "required" && "This field is required."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.stage && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.stage[0]}
                </p>
              )
            }{" "}
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.stage && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.stage[0]}
                  </p>
                )
            }
          </div>

          {/* Gender  */}
          <div className=" flex w-1/2 flex-col items-start justify-center gap-2 md:w-full">
            <label htmlFor="gender">Gender</label>
            <select
              name="gender"
              id="gender"
              {...register("gender", { required: true })}
            >
              <option hidden></option>
              <option value="0">Male</option>
              <option value="1">Famale</option>
            </select>
            {errors.gender && (
              <p className="pt-2 text-xs text-red-500">
                {errors.gender.type === "required" && "This field is required."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.gender && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.gender[0]}
                </p>
              )
            }{" "}
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.gender && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.gender[0]}
                  </p>
                )
            }
          </div>
        </div>

        {/** School and Activation Code fields */}
        <div className="flex w-full items-start justify-between gap-12 md:flex-col  md:gap-6">
          {/** School  */}
          <div
            className={` flex w-full flex-col items-start justify-center gap-2 md:w-full`}
          >
            <label htmlFor="school">School Name</label>
            <input
              className="signin-inputs  w-full"
              type="text"
              id="school"
              placeholder="School"
              name="school"
              autoComplete="on"
              {...register("school", {
                required: true,
                //pattern: /^[A-Za-z]+$/,
                maxLength: 80,
                minLength: 4,
              })}
            />
            {errors.school && (
              <p className="pt-2 text-xs text-red-500">
                {errors.school.type === "required" && "This field is required."}
                {errors.school.type === "pattern" &&
                  " Only English letters are allowed (Spaces not Allowed)."}
                {errors.school.type === "maxLength" &&
                  "Available name max length is 80."}
                {errors.school.type === "minLength" &&
                  " Please enter at least 4 letters."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.school && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.school[0]}
                </p>
              )
            }{" "}
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.school && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.school[0]}
                  </p>
                )
            }
          </div>
        </div>

        <div className="flex w-full items-start justify-between gap-12 md:flex-col  md:gap-6">
          {/** total fees  */}
          <div
            className={` flex w-full flex-col items-start justify-center gap-2 md:w-full`}
          >
            <label htmlFor="total_fees">Total Fees</label>
            <input
              className="signin-inputs  w-full"
              type="number"
              id="total_fees"
              placeholder="Total Fees"
              name="total_fees"
              autoComplete="on"
              {...register("total_fees", {
                required: false,
                //pattern: /^[A-Za-z]+$/,
                maxLength: 80,
                // minLength: 4,
              })}
            />
            {errors.total_fees && (
              <p className="pt-2 text-xs text-red-500">
                {errors.total_fees.type === "required" &&
                  "This field is required."}
                {errors.total_fees.type === "pattern" &&
                  " Only English letters are allowed (Spaces not Allowed)."}
                {errors.total_fees.type === "maxLength" &&
                  "Available name max length is 80."}
                {errors.total_fees.type === "minLength" &&
                  " Please enter at least 4 letters."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.total_fees && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.total_fees[0]}
                </p>
              )
            }{" "}
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.total_fees && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.total_fees[0]}
                  </p>
                )
            }
          </div>
        </div>

        {/** installment */}
        <div className="flex w-full items-start justify-between gap-12 md:flex-col  md:gap-6">
          <div
            className={` flex w-1/3 flex-col items-start justify-center gap-2 md:w-full`}
          >
            <label htmlFor="first_installment">First installment</label>
            <input
              className="signin-inputs  w-full"
              type="number"
              id="first_installment"
              placeholder="First Installment"
              name="first_installment"
              autoComplete="on"
              {...register("first_installment", {
                required: false,
                //pattern: /^[A-Za-z]+$/,
                maxLength: 80,
              })}
            />
            {errors.first_installment && (
              <p className="pt-2 text-xs text-red-500">
                {errors.first_installment.type === "required" &&
                  "This field is required."}
                {errors.first_installment.type === "pattern" &&
                  " Only English letters are allowed (Spaces not Allowed)."}
                {errors.first_installment.type === "maxLength" &&
                  "Available name max length is 80."}
                {errors.first_installment.type === "minLength" &&
                  " Please enter at least 4 letters."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.first_installment && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.first_installment[0]}
                </p>
              )
            }{" "}
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors
                  ?.first_installment && (
                  <p className="pt-2 text-xs text-red-500">
                    {
                      StudentEditErrors?.response?.data.errors
                        .first_installment[0]
                    }
                  </p>
                )
            }
          </div>
          <div
            className={` flex w-1/3 flex-col items-start justify-center gap-2 md:w-full`}
          >
            <label htmlFor="second_installment">Second installment</label>
            <input
              className="signin-inputs  w-full"
              type="number"
              id="second_installment"
              placeholder="Second Installment"
              name="second_installment"
              autoComplete="on"
              {...register("second_installment", {
                required: false,
                //pattern: /^[A-Za-z]+$/,
                maxLength: 80,
              })}
            />
            {errors.second_installment && (
              <p className="pt-2 text-xs text-red-500">
                {errors.second_installment.type === "required" &&
                  "This field is required."}
                {errors.second_installment.type === "pattern" &&
                  " Only English letters are allowed (Spaces not Allowed)."}
                {errors.second_installment.type === "maxLength" &&
                  "Available name max length is 80."}
                {errors.second_installment.type === "minLength" &&
                  " Please enter at least 4 letters."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.second_installment && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.second_installment[0]}
                </p>
              )
            }{" "}
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors
                  ?.second_installment && (
                  <p className="pt-2 text-xs text-red-500">
                    {
                      StudentEditErrors?.response?.data.errors
                        .second_installment[0]
                    }
                  </p>
                )
            }
          </div>
          <div
            className={` flex w-1/3 flex-col items-start justify-center gap-2 md:w-full`}
          >
            <label htmlFor="third_installment">Third installment</label>
            <input
              className="signin-inputs  w-full"
              type="number"
              id="thirdinstallment"
              placeholder="Third Installment"
              name="third_installment"
              autoComplete="on"
              {...register("third_installment", {
                required: false,
              })}
            />
            {errors.third_installment && (
              <p className="pt-2 text-xs text-red-500">
                {errors.third_installment.type === "required" &&
                  "This field is required."}
                {errors.third_installment.type === "pattern" &&
                  " Only English letters are allowed (Spaces not Allowed)."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.third_installment && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.third_installment[0]}
                </p>
              )
            }{" "}
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors
                  ?.third_installment && (
                  <p className="pt-2 text-xs text-red-500">
                    {
                      StudentEditErrors?.response?.data.errors
                        .third_installment[0]
                    }
                  </p>
                )
            }
          </div>
        </div>

        {/* Courses */}
        {/* <div className="flex w-full items-end justify-between gap-16 md:flex-col md:items-start md:gap-6 ">
          <div className=" flex w-full flex-col items-start justify-center gap-2 md:w-full">
            <label htmlFor="course_id">Course</label>
            <select
              name="course_id"
              id="course_id"
              {...register("course_id", { required: true })}
            >
              <option hidden></option>
              {GetAllCourseSuccsess?.data?.map((course, index) => (
                <option key={index} value={course.key}>
                  {course.name}
                </option>
              ))}
            </select>
            {errors.course_id && (
              <p className="pt-2 text-xs text-red-500">
                {errors.course_id.type === "required" &&
                  "This field is required."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.course_id && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.course_id[0]}
                </p>
              )
            }{" "}
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.course_id && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.course_id[0]}
                  </p>
                )
            }
          </div>
        </div> */}

        {/** notes  */}
        <div className="flex w-full items-start justify-between gap-12 md:flex-col  md:gap-6">
          <div
            className={` flex w-full flex-col items-start justify-center gap-2 md:w-full`}
          >
            <label htmlFor="notes">Notes</label>
            <textarea
              className="signin-inputs  w-full !h-[80px]"
              type="text"
              id="notes"
              placeholder="notes"
              name="notes"
              autoComplete="on"
              {...register("notes", {
                required: false,
                //pattern: /^[A-Za-z]+$/,
                maxLength: 80,
                minLength: 4,
              })}
            />
            {errors.notes && (
              <p className="pt-2 text-xs text-red-500">
                {errors.notes.type === "required" && "This field is required."}
                {errors.notes.type === "pattern" &&
                  " Only English letters are allowed (Spaces not Allowed)."}
                {errors.notes.type === "maxLength" &&
                  "Available name max length is 80."}
                {errors.notes.type === "minLength" &&
                  " Please enter at least 4 letters."}
              </p>
            )}
            {
              //!-------server errors -----

              state.errors && state.errors.notes && (
                <p className="pt-2 text-xs text-red-500">
                  {state.errors.notes[0]}
                </p>
              )
            }{" "}
            {
              //!-------Editing server errors -----

              StudentEditErrors?.response?.data?.errors &&
                StudentEditErrors?.response?.data?.errors?.notes && (
                  <p className="pt-2 text-xs text-red-500">
                    {StudentEditErrors?.response?.data.errors.notes[0]}
                  </p>
                )
            }
          </div>
        </div>

        {/** Submit Button */}

        <button
          disabled={!isValid || state.submitLoading}
          className=" submit mt-6 w-full "
          type="submit"
        >
          {state.submitLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : edit ? (
            "Edit Student"
          ) : (
            "Add Student"
          )}
        </button>
      </form>
    </section>
  );
}

export default Register;
