import AboutTeacher from "./Components/AboutTeacher";
import HelmetTags from "../../MainComponents/HelmetTags.jsx";
import Navbar from "../../MainComponents/Navbar";
import Footer from "../../MainComponents/Footer";

function AboutUsPage() {
  return (
    <>
      <HelmetTags title="Mr.Ahmed Harouny" index></HelmetTags>
      <Navbar />
      <main className="pt-20 min-h-screen w-full overflow-x-hidden">
        {/* <Hero /> */}
        <AboutTeacher />
      </main>
      <Footer />
    </>
  );
}

export default AboutUsPage;
