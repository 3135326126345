import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

function AdminAddEditSubject({ edit }) {
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);
  const { subjectID } = useParams();
  const [values, setValues] = useState(null);

  const navigate = useNavigate();

  const [GetSubjectInfo, GetSubjectInfoErrors, GetSubjectInfoLoading] =
    useAxios(
      `${process.env.REACT_APP_ADMIN_SUBJECT_INFO_API}/${subjectID}`,
      "GET",
      "GET",
      subjectID,
      subjectID
    );

  useEffect(() => {
    if (GetSubjectInfo) setValues(GetSubjectInfo?.data);
  }, [GetSubjectInfo]);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  const [AddSubjectSuccess, AddSubjectErrors, AddSubjectLoading] = useAxios(
    edit
      ? process.env.REACT_APP_ADMIN_EDIT_SUBJECT_API
      : process.env.REACT_APP_ADMIN_ADD_SUBJECT_API,
    "POST",
    formFlag,
    formDependency,
    formValues
  );

  const onSubmit = (formData) => {
    const finalData = edit ? { ...formData, id: subjectID } : { ...formData };
    setFormValues(finalData);
    setFormFlag("EditAssistantname");
    setFormDependency(true);
  };

  useEffect(() => {
    if (AddSubjectSuccess) {
      toast.success("Created Successfully");
      setFormFlag("");
      setFormDependency(false);
      reset();
      navigate(-1);
    }
  }, [AddSubjectSuccess, navigate, reset]);

  useEffect(() => {
    if (AddSubjectErrors) {
      toast.error(AddSubjectErrors.message);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [AddSubjectErrors]);

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      <HelmetTags
        title={`${edit ? "Edit" : "Add"} Subject | Mr.Ahmed Harouny`}
      />
      <div className="w-full flex justify-between items-center gap-5 mb-7">
        <h2 className=" text-start text-2xl font-bold ">
          {edit ? "Edit " : "Add "} Subject
        </h2>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-center items-start  bg-white p-16  rounded-3xl shadow-lg shadow-blue/50 w-3/4 my-6 mx-auto gap-6"
      >
        {/** Name  */}

        <div className="w-full">
          <div className="relative flex flex-col justify-center items-start gap-2 ">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              className="signin-inputs w-full"
              type="name"
              placeholder="name"
              name="name"
              autoComplete="on"
              {...register("name", {
                required: true,
                maxLength: 255,
                minLength: 3,
              })}
            />
          </div>
          {errors.name && (
            <p className="text-red-500 text-xs pt-4">
              {errors.name.type === "required" && "This field is required."}
              {errors.name.type === "maxLength" &&
                "name Max length is 25 char."}
              {errors.name.type === "minLength" && "name Min length is 6 char."}
            </p>
          )}
        </div>

        <button disabled={AddSubjectLoading} className="submit">
          {AddSubjectLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            `${edit ? "Edit " : "Add "} Subject`
          )}
        </button>
      </form>
    </section>
  );
}

export default AdminAddEditSubject;
