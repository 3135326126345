import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { useAuthState } from "../../../../MainComponents/GlobalContext";

function AddEditChooseQuestion({ edit, exam, id, lectureQuestion }) {
  const AuthState = useAuthState();
  const { categoryID, questionID, examID } = useParams();

  let QuestionDataApi = lectureQuestion
    ? `${process.env.REACT_APP_QUIZ_HOMEWORK_QUESTION_INFO_API}${questionID}`
    : `${process.env.REACT_APP_QUESTION_INFO_API}${questionID}`;
  const [QuestionDataSuccess, QuestionErrors, loading] = useAxios(
    QuestionDataApi,
    "GET",
    edit
  );

  // get all categories
  const [AllCategoriesSuccess, AllCategoriesErrors, AllCategoriesLoading] =
    useAxios(
      process.env.REACT_APP_GET_ALL_QUESTION_BANK_CATEGORIES_API,
      "GET",
      "GET",
      ""
    );

  const [question, setQuestion] = useState("");
  const [answer1, setAnswer1] = useState("<h2></h2> <h2>A</h2>");
  const [answer2, setAnswer2] = useState("<h2></h2> <h2>B</h2>");
  const [answer3, setAnswer3] = useState("<h2></h2> <h2>C</h2>");
  const [answer4, setAnswer4] = useState("<h2></h2> <h2>D</h2>");
  const [hint, setHint] = useState("");
  const [correct_answer, setCorrectAnswer] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [vdo_id, setVideoId] = useState("");
  const [platform, setPlatform] = useState("");
  const [degree, setDegree] = useState("");
  const [year, setYear] = useState("");
  const [questionNumber, setQuestionNumber] = useState("");
  const [title, setTitle] = useState("");
  const [unit, setUnit] = useState("");
  const [lesson, setLesson] = useState("");

  useEffect(() => {
    if (question !== "") {
      setErrors("");
    } else if (answer1 !== "") {
      setErrors("");
    } else if (answer2 !== "") {
      setErrors("");
    } else if (answer3 !== "") {
      setErrors("");
    } else if (answer4 !== "") {
      setErrors("");
    } else if (difficulty !== "") {
      setErrors("");
    } else if (correct_answer !== "") {
      setErrors("");
    } else if (degree !== "") {
      setErrors("");
    } else if (unit !== "") {
      setErrors("");
    } else if (questionNumber !== "") {
      setErrors("");
    } else if (lesson !== "") {
      setErrors("");
    } else if (year !== "") {
      setErrors("");
    } else if (title !== "") {
      setErrors("");
    } else if (vdo_id !== "" && platform !== "") {
      setErrors("");
    }
  }, [
    question,
    answer1,
    answer2,
    answer3,
    answer4,
    difficulty,
    correct_answer,
    degree,
    vdo_id,
    platform,
    unit,
    questionNumber,
    lesson,
    year,
    title,
  ]);

  const [errors, setErrors] = useState("");
  const navigate = useNavigate();

  const [submitQuestion, setSubmitQuestion] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  let api = edit
    ? process.env.REACT_APP_EDIT_QUESTION_API
    : process.env.REACT_APP_ADD_QUESTION_BANK_QUESTION_API;

  const [addEditQuestionSuccess, addEditQuestionErrors, submitLoading] =
    useAxios(
      api,
      "POST",
      submitQuestion.flag,
      submitQuestion.dependency,
      submitQuestion.data,
      true
    );

  useEffect(() => {
    if (QuestionDataSuccess?.data) {
      setQuestion(QuestionDataSuccess?.data?.question);
      setAnswer1(QuestionDataSuccess?.data?.answer1);
      setAnswer2(QuestionDataSuccess?.data?.answer2);
      setAnswer3(QuestionDataSuccess?.data?.answer3);
      setAnswer4(QuestionDataSuccess?.data?.answer4);
      setHint(
        QuestionDataSuccess?.data?.hint !== null
          ? QuestionDataSuccess?.data?.hint
          : ""
      );
      setDifficulty(QuestionDataSuccess?.data?.difficulty);
      setCorrectAnswer(QuestionDataSuccess?.data?.correct_answer);
      setDegree(QuestionDataSuccess?.data?.degree);
      setYear(QuestionDataSuccess?.data?.year_id);
      setUnit(QuestionDataSuccess?.data?.unit_id);
      setLesson(QuestionDataSuccess?.data?.lesson_id);
      setTitle(QuestionDataSuccess?.data?.title_id);
      setQuestionNumber(QuestionDataSuccess?.data?.qnumber_id);
    }
  }, [QuestionDataSuccess]);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (question === "") {
      setErrors("question");
      toast.error("Please Add Question");
    } else if (answer1 === "") {
      setErrors("answer1");
      toast.error("Please Add Answer1");
    } else if (answer2 === "") {
      setErrors("answer2");
      toast.error("Please Add Answer2");
    } else if (answer3 === "") {
      setErrors("answer3");
      toast.error("Please Add Answer3");
    } else if (answer4 === "") {
      setErrors("answer4");
      toast.error("Please Add Answer4");
    } else if (correct_answer === "") {
      setErrors("correct_answer");
      toast.error("Please Add Correct Answer");
    } else if (platform && !vdo_id) {
      setErrors("vdo_id");
      toast.error("Please Add both the Platform and the Video Id.");
    } else if (!platform && vdo_id) {
      setErrors("platform");
      toast.error("Please Add both the Platform and the Video Id.");
    } else if (degree === "" || Number(degree) < 1) {
      setErrors("degree");
      toast.error("Please Add Degree");
    } else if (unit === "") {
      setErrors("unit");
      toast.error("Please Add Unit");
    } else if (questionNumber === "" || Number(questionNumber) < 1) {
      setErrors("questionNumber");
      toast.error("Please Add Question Number");
    } else if (lesson === "") {
      setErrors("lesson");
      toast.error("Please Add lesson");
    } else if (year === "") {
      setErrors("year");
      toast.error("Please Add year");
    } else if (title === "") {
      setErrors("title");
      toast.error("Please Add title");
    } else {
      const data = exam
        ? {
            question: question,
            answer1: answer1,
            answer2: answer2,
            answer3: answer3,
            answer4: answer4,
            correct_answer: correct_answer,
            difficulty: difficulty,
            hint: hint || null,
            degree: degree,
            content_id: id,
            exam_id: examID,
            questionNumber: questionNumber,
            year_id: year,
            unit_id: unit,
            lesson_id: lesson,
            title_id: title,
            qnumber_id: questionNumber,
          }
        : {
            question: question,
            answer1: answer1,
            answer2: answer2,
            answer3: answer3,
            answer4: answer4,
            hint: hint,
            degree: degree,
            correct_answer: correct_answer,
            difficulty: difficulty || null,
            id: edit ? questionID : categoryID,
            questionNumber: questionNumber,
            year_id: year,
            unit_id: unit,
            lesson_id: lesson,
            title_id: title,
            qnumber_id: questionNumber,
          };
      setSubmitQuestion({
        flag: "addEditQuestionRequest",
        dependency: !submitQuestion.dependency,
        data: data,
      });
    }
  };
  useEffect(() => {
    //! reset the form on success submit

    if (addEditQuestionSuccess) {
      setQuestion("");
      setAnswer1("");
      setAnswer2("");
      setAnswer3("");
      setAnswer4("");
      setDifficulty("");
      setCorrectAnswer("");
      setPlatform("");
      setVideoId("");
      setHint("");
      setDegree("");
      setErrors("");
      navigate(-1);
    }
  }, [addEditQuestionSuccess]);

  return (
    <section
      className={`flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light py-20`}
    >
      <HelmetTags title="Question Bank | Mr. Ahmed Harouny"></HelmetTags>
      <form
        method="post"
        onSubmit={handleSubmit}
        className="form-container  flex flex-col items-center "
      >
        <h2 className={` text-center text-xl mb-20 font-semibold`}>
          {edit
            ? "Please fill in the field to edit the question"
            : "Please fill in the field to add the question"}
        </h2>
        {/**
         * //!------question-----
         */}
        <div className=" question mb-10 flex w-[900px] flex-col items-start gap-2 alg:w-[700px] amd:w-[500px] sm:w-[300px]">
          <div className="mb-3 w-full flex justify-between items-center gap-5 md:flex-col md:items-center">
            <h2 className=" text-xl font-semibold">Question</h2>
            <div className=" bubble flex w-1/2 flex-col items-start justify-center gap-2 md:w-full ">
              <div className="flex w-full items-center justify-end gap-10">
                <label className="visibility-switch">
                  <input
                    className=""
                    id="bubble"
                    name="bubble"
                    type="checkbox"
                    defaultChecked={true}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setAnswer1("<h2></h2> <h2>A</h2>");
                        setAnswer2("<h2></h2> <h2>B</h2>");
                        setAnswer3("<h2></h2> <h2>C</h2>");
                        setAnswer4("<h2></h2> <h2>D</h2>");
                      } else {
                        setAnswer1("");
                        setAnswer2("");
                        setAnswer3("");
                        setAnswer4("");
                      }
                    }}
                  />
                  <span className="visibility-slider">
                    <svg
                      className="slider-icon"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="m4 16.5 8 8 16-16"></path>
                    </svg>
                  </span>
                </label>
                <div>
                  <label
                    className="w-full cursor-pointer truncate"
                    htmlFor="bubble"
                  >
                    Bubble Sheet
                  </label>
                </div>
              </div>
            </div>
          </div>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",

              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={question}
            onChange={(event, editor) => {
              const data = editor.getData();
              setQuestion(data);
            }}
          />
          {errors === "question" && (
            <p className="text-xs text-red-500 ">Please fill in this field</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.question && (
                <p className="text-xs text-red-500  ">
                  {addEditQuestionErrors?.response?.data?.errors?.question[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------answer 1-----
         */}
        <div className=" answer1 my-10 flex w-[900px] flex-col items-start gap-2 alg:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-xl font-semibold">Answer 1</h2>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",
              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={answer1}
            onChange={(event, editor) => {
              const data = editor.getData();
              setAnswer1(data);
            }}
          />
          {errors === "answer1" && (
            <p className="text-xs text-red-500 ">Please fill in this field</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.answer1 && (
                <p className="text-xs text-red-500  ">
                  {addEditQuestionErrors?.response?.data?.errors?.answer1[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------answer 2-----
         */}
        <div className=" answer1 my-10 flex w-[900px] flex-col items-start gap-2 alg:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-xl font-semibold">Answer 2</h2>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",
              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={answer2}
            onChange={(event, editor) => {
              const data = editor.getData();
              setAnswer2(data);
            }}
          />
          {errors === "answer2" && (
            <p className="text-xs text-red-500 ">Please fill in this field</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.answer2 && (
                <p className="text-xs text-red-500  ">
                  {addEditQuestionErrors?.response?.data?.errors?.answer2[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------answer 3-----
         */}
        <div className=" answer1 my-10 flex w-[900px] flex-col items-start gap-2 alg:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-xl font-semibold">Answer 3</h2>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",
              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={answer3}
            onChange={(event, editor) => {
              const data = editor.getData();
              setAnswer3(data);
            }}
          />
          {errors === "answer3" && (
            <p className="text-xs text-red-500 ">Please fill in this field</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.answer3 && (
                <p className="text-xs text-red-500  ">
                  {addEditQuestionErrors?.response?.data?.errors?.answer3[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------answer 4-----
         */}
        <div className=" answer1 my-10 flex w-[900px] flex-col items-start gap-2 alg:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-xl font-semibold">Answer 4 </h2>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",
              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={answer4}
            onChange={(event, editor) => {
              const data = editor.getData();
              setAnswer4(data);
            }}
          />
          {errors === "answer4" && (
            <p className="text-xs text-red-500 ">Please fill in this field</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.answer4 && (
                <p className="text-xs text-red-500  ">
                  {addEditQuestionErrors?.response?.data?.errors?.answer4[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------hint-----
         */}
        <div className=" answer1 my-10 flex w-[900px] flex-col items-start gap-2 alg:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-xl font-semibold">Hint</h2>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",
              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={hint}
            onChange={(event, editor) => {
              const data = editor.getData();
              setHint(data);
            }}
          />
          {errors === "hint" && (
            <p className="text-xs text-red-500 ">Please fill in this field</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.hint && (
                <p className="text-xs text-red-500  ">
                  {addEditQuestionErrors?.response?.data?.errors?.hint[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!---------right answer & difficulty
         */}

        <div className="w-full flex items-center gap-5 md:flex-col">
          <div
            className={`my-5 flex w-1/2 flex-col items-start justify-center gap-2  md:w-full `}
          >
            <label className="w-full truncate" htmlFor="correct_answer">
              Correct Answer{" "}
            </label>
            <select
              value={correct_answer}
              onChange={(e) => setCorrectAnswer(e.target.value)}
              name="correct_answer"
              id="correct_answer"
            >
              <option hidden disabled value="">
                Correct Answer
              </option>{" "}
              <option value="answer1">Answer 1</option>
              <option value="answer2">Answer 2</option>
              <option value="answer3">Answer 3</option>
              <option value="answer4">Answer 4</option>
            </select>

            {errors === "correct_answer" && (
              <p className="text-xs text-red-500 ">Please fill in this field</p>
            )}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors
                  ?.correct_answer && (
                  <p className="text-xs text-red-500  ">
                    {
                      addEditQuestionErrors?.response?.data?.errors
                        ?.correct_answer[0]
                    }
                  </p>
                )
            }
          </div>
          <div
            className={`my-5 flex w-1/2 md:w-full flex-col items-start justify-center gap-2  md:w-full `}
          >
            {/* //!------difficulty ----- */}
            <label className="w-full truncate" htmlFor="difficulty">
              Difficulty{" "}
            </label>
            <select
              value={difficulty}
              onChange={(e) => setDifficulty(e.target.value)}
              name="difficulty"
              id="difficulty"
            >
              <option hidden disabled value="">
                Difficulty
              </option>{" "}
              <option value="beginner">Beginner</option>
              <option value="normal">Normal</option>
              <option value="medium">Medium</option>
              <option value="professional">Professional</option>
              <option value="expert">Expert</option>
            </select>

            {errors === "difficulty" && (
              <p className="text-xs text-red-500 ">Please fill in this field</p>
            )}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.difficulty && (
                  <p className="text-xs text-red-500  ">
                    {
                      addEditQuestionErrors?.response?.data?.errors
                        ?.difficulty[0]
                    }
                  </p>
                )
            }
          </div>
        </div>
        <div className="w-full flex items-center gap-5 md:flex-col">
          <div className="mb-5 flex w-1/2 sm:w-full flex-col items-start justify-center gap-2">
            <label className="w-full truncate" htmlFor="degree">
              Degree
            </label>
            <input
              className="signin-inputs w-full "
              type="number"
              // inputMode="numeric"
              min={1}
              value={degree}
              onChange={(e) => setDegree(e.target.value)}
              id="degree"
              placeholder="000"
              step=".01"
              name="degree"
              autoComplete="on"
            />

            {errors === "degree" && (
              <p className="text-xs text-red-500">This field is required. </p>
            )}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.degree && (
                  <p className=" text-xs text-red-500  ">
                    {addEditQuestionErrors?.response?.data?.errors?.degree[0]}
                  </p>
                )
            }
          </div>
          <div className="w-1/2  sm:w-full flex  items-end gap-4 mb-5">
            <div className="flex w-full  flex-col items-start justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="unit">
                Unit
              </label>
              <select
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                name="unit"
                id="unit"
              >
                <option hidden disabled value="">
                  Unit
                </option>{" "}
                {AllCategoriesSuccess?.data.units.map((unit) => (
                  <option value={unit.id} key={unit.id}>
                    {unit.name}
                  </option>
                ))}
              </select>

              {errors === "unit" && (
                <p className="text-xs text-red-500">This field is required. </p>
              )}
              {
                //!-------server errors -----

                addEditQuestionErrors &&
                  addEditQuestionErrors?.response?.data?.errors?.unit && (
                    <p className=" text-xs text-red-500  ">
                      {addEditQuestionErrors?.response?.data?.errors?.unit[0]}
                    </p>
                  )
              }
            </div>
            {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("questionbank_addcategory")
            ) ? (
              <Link
                to="/admin/question-bank/add-choose-question/add-unit"
                className="w-1/4 submit mt-6 flex items-center justify-center "
              >
                <p>Add Unit</p>
              </Link>
            ) : null}
          </div>
        </div>

        <div className="w-full flex items-center gap-5 md:flex-col">
          <div className="flex w-1/2  sm:w-full  items-end gap-4 mb-5">
            <div className="w-full flex flex-col items-start justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="questionNumber">
                Question Number{" "}
              </label>
              <select
                value={questionNumber}
                onChange={(e) => setQuestionNumber(e.target.value)}
                name="questionNumber"
                id="questionNumber"
              >
                <option hidden disabled value="">
                  question number{" "}
                </option>{" "}
                {AllCategoriesSuccess?.data.qnumbers.map((qnumber) => (
                  <option value={qnumber.id} key={qnumber.id}>
                    {qnumber.qnumber}
                  </option>
                ))}
              </select>

              {errors === "questionNumber" && (
                <p className="text-xs text-red-500">This field is required. </p>
              )}
              {
                //!-------server errors -----

                addEditQuestionErrors &&
                  addEditQuestionErrors?.response?.data?.errors
                    ?.questionNumber && (
                    <p className=" text-xs text-red-500  ">
                      {
                        addEditQuestionErrors?.response?.data?.errors
                          ?.questionNumber[0]
                      }
                    </p>
                  )
              }
            </div>
            {/* {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("questionbank_addcategory")
            ) ? (
              <Link
                to="/admin/question-bank/add-choose-question/add-question-number"
                className="w-1/4 submit mt-6 flex items-center justify-center "
              >
                <p>Add Question Number</p>
              </Link>
            ) : null} */}
          </div>
          <div className="flex w-1/2  sm:w-full items-end gap-4 mb-5">
            <div className="w-full flex flex-col items-start justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="lesson">
                Lessons
              </label>
              <select
                value={lesson}
                onChange={(e) => setLesson(e.target.value)}
                name="lesson"
                id="lesson"
              >
                <option hidden disabled value="">
                  Lesson
                </option>{" "}
                {AllCategoriesSuccess?.data?.units
                  ?.find((findUnit) => findUnit.id === +unit)
                  ?.lessons?.map((lesson) => (
                    <option value={lesson.id} key={lesson.id}>
                      {lesson.name}
                    </option>
                  ))}
              </select>

              {errors === "lesson" && (
                <p className="text-xs text-red-500">This field is required. </p>
              )}
              {
                //!-------server errors -----

                addEditQuestionErrors &&
                  addEditQuestionErrors?.response?.data?.errors?.lesson && (
                    <p className=" text-xs text-red-500  ">
                      {addEditQuestionErrors?.response?.data?.errors?.lesson[0]}
                    </p>
                  )
              }
            </div>
            {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("questionbank_addcategory")
            ) ? (
              <Link
                to="/admin/question-bank/add-choose-question/add-lesson"
                className="w-1/4 submit mt-6 flex items-center justify-center "
              >
                <p>Add Lesson</p>
              </Link>
            ) : null}
          </div>
        </div>

        <div className="w-full flex items-center gap-5 md:flex-col">
          <div className="flex w-1/2  sm:w-full items-end gap-4 mb-5">
            <div className="w-full flex flex-col items-start justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="year">
                Year and Variant
              </label>

              <select
                value={year}
                onChange={(e) => setYear(e.target.value)}
                name="year"
                id="year"
              >
                <option hidden disabled value="">
                  year
                </option>{" "}
                {AllCategoriesSuccess?.data.years.map((year) => (
                  <option value={year.id} key={year.id}>
                    {year.year}
                  </option>
                ))}
              </select>

              {errors === "year" && (
                <p className="text-xs text-red-500">This field is required. </p>
              )}
              {
                //!-------server errors -----

                addEditQuestionErrors &&
                  addEditQuestionErrors?.response?.data?.errors?.year && (
                    <p className=" text-xs text-red-500  ">
                      {addEditQuestionErrors?.response?.data?.errors?.year[0]}
                    </p>
                  )
              }
            </div>
            {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("questionbank_addcategory")
            ) ? (
              <Link
                to="/admin/question-bank/add-choose-question/add-year"
                className="w-1/4 submit mt-6 flex items-center justify-center "
              >
                <p>Add Year</p>
              </Link>
            ) : null}
          </div>
          <div className="mb-5 flex w-1/2 items-end justify-center gap-2 md:w-full">
            <div className="w-full flex items-start flex-col justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="title">
                Titles
              </label>
              <select
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                name="title"
                id="title"
              >
                <option hidden disabled value="">
                  Title
                </option>{" "}
                {AllCategoriesSuccess?.data?.units
                  ?.find((findUnit) => findUnit.id === +unit)
                  ?.lessons?.find((findLesson) => findLesson.id === +lesson)
                  ?.titles.map((title) => (
                    <option value={title.id} key={title.id}>
                      {title.title}
                    </option>
                  ))}
              </select>

              {errors === "title" && (
                <p className="text-xs text-red-500">This field is required. </p>
              )}
              {
                //!-------server errors -----

                addEditQuestionErrors &&
                  addEditQuestionErrors?.response?.data?.errors?.title && (
                    <p className=" text-xs text-red-500  ">
                      {addEditQuestionErrors?.response?.data?.errors?.title[0]}
                    </p>
                  )
              }
            </div>
            {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("questionbank_addcategory")
            ) ? (
              <Link
                to="/admin/question-bank/add-choose-question/add-title"
                className="w-1/4 submit mt-6 flex items-center justify-center "
              >
                <p>Add Title</p>
              </Link>
            ) : null}
          </div>
          {/* lessson */}
        </div>

        <button
          disabled={submitLoading && submitQuestion.flag}
          className=" submit mt-6 w-full "
          type="submit"
        >
          {submitLoading && submitQuestion.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <p> {edit ? "Edit Question" : "Add Question"}</p>
          )}
        </button>
      </form>
    </section>
  );
}

export default AddEditChooseQuestion;
