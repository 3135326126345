import { useState } from "react";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import HelmetTags from "../../../../MainComponents/HelmetTags";

import useTable from "../../../../MainComponents/Hooks/useTable";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

function AdminClasses() {
  const AuthState = useAuthState();
  const [academiclevel, setAcademiclevel] = useState("9");
  const [left, setLeft] = useState("left-0");
  const table = useTable("students", "", "", academiclevel, "", academiclevel);

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      <HelmetTags title="Students | Mr.Ahmed Harouny" />
      <div className="w-full flex justify-between items-center gap-5 my-7">
        <h2 className=" text-start text-2xl font-bold ">Classes</h2>
      </div>

      <div
        className={`relative flex  h-10 min-h-[40px]   xs:w-full w-1/2 justify-start  `}
      >
        <button
          onClick={() => {
            setLeft("left-0");
            setAcademiclevel("answred");
          }}
          className="z-10 h-full w-1/3 text-base font-semibold "
        >
          Answred
        </button>
        <button
          onClick={() => {
            setLeft("left-1/3");
            setAcademiclevel("un-answred");
          }}
          className="z-10 h-full w-1/3 text-base font-semibold "
        >
          Un Answred
        </button>
        <button
          onClick={() => {
            setLeft("left-2/3");
            setAcademiclevel("students");
          }}
          className="z-10 h-full w-1/3 text-base font-semibold "
        >
          Students
        </button>

        <div
          className={`std-selector absolute top-0 h-full w-1/3 ${left} rounded-md bg-green duration-300`}
        ></div>
      </div>

      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default AdminClasses;
