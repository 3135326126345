import { useAuthState } from "../../../../MainComponents/GlobalContext";
import HelmetTags from "../../../../MainComponents/HelmetTags";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Col, Popconfirm, Row } from "antd";
import { Loader } from "../../../../MainComponents";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useEffect, useState } from "react";

function QuestionBankExams() {
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [AllExamsData, AllExamsDataErrors, AllExamsLoading] = useAxios(
    process.env.REACT_APP_GET_ALL_EXAMS_QUESTION_BANK_API,
    "GET",
    "GET",
    reFetch
  );

  const [deleteQuestionSuccess] = useAxios(
    process.env.REACT_APP_DELETE_EXAM_QUESTION_BANK_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );

  useEffect(() => {
    //! refetch the data on success delete Question

    if (deleteQuestionSuccess) {
      setReFetch(!reFetch);
    }
  }, [deleteQuestionSuccess]);

  function handleDeleteExam(id) {
    setSubmitDelete({
      flag: "deleteQuestion",
      dependency: !submitDelete.dependency,
      data: { id },
    });
  }

  if (AllExamsLoading) return <Loader />;

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      <HelmetTags title="Exams | Mr.Ahmed Harouny" />
      <div className="w-full flex justify-between items-center gap-5 mb-7">
        <h2 className=" text-start text-2xl font-bold ">Exams</h2>
      </div>

      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("student_add")
        )) && (
        <Link
          to="/admin/question-bank/add-exam"
          className="text-white mb-8 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
        >
          <FontAwesomeIcon className="mr-4" icon={faPlus} />
          Add Exam
        </Link>
      )}

      {AllExamsDataErrors && (
        <p className="w-full my-12 text-center font-bold">
          {AllExamsDataErrors}
        </p>
      )}

      <Row gutter={[20, 20]} className="w-full">
        {AllExamsData?.data?.map((question, i) => (
          <Col xs={24} md={24} lg={12} key={i}>
            <div className=" w-full flex items-center justify-between border-2 border-secondary p-6 rounded-xl ">
              <Link
                to={`/admin/question-bank/${question.key}/exam-questions`}
                className="font-bold"
              >
                {question.name}
              </Link>
              <div className="flex items-center gap-2">
                <Link
                  to={`/admin/question-bank/edit-exam/${question.key}`}
                  className="bg-secondary text-white p-2 px-7 rounded-xl border-2 border-secondary hover:bg-transparent duration-300 hover:scale-110 hover:text-secondary "
                >
                  Edit
                </Link>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={() => handleDeleteExam(question.key)}
                  title="Are you sure you want to delete?"
                >
                  <button className="bg-delete text-white p-2 px-7 rounded-xl border-2 border-delete hover:bg-transparent duration-300 hover:scale-110 hover:text-secondary ">
                    Delete
                  </button>
                </Popconfirm>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </section>
  );
}

export default QuestionBankExams;
