import { useState, useEffect } from "react";
import useAxios from "../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../MainComponents/HelmetTags";

import Loader from "../../../MainComponents/Loader.jsx";

import StudentCoursesCard from "./components/StudentCoursesCard.jsx";

function StudentCourses({ teacher }) {
  const [coursesData, setCoursesData] = useState([]);
  const [allCoursesData, allCoursesErrors, loading] = useAxios(
    process.env.REACT_APP_STUDENT_MY_COURSES_API,
    "GET",
    "GET"
  );

  if (loading) return <Loader />;

  return (
    <section className="h-auto width">
      <HelmetTags title={`My Courses | Mr.Ahmed Harouny`} />

      <h1 className="course-name text-center text-3xl font-bold mt-12">
        My Courses
      </h1>

      <div
        className={`courses grid-auto-fit width ${
          teacher && "sss:justify-items-start"
        } mt-10 `}
      >
        {allCoursesData?.data?.map((course, index) => {
          return (
            <StudentCoursesCard key={index} course={course} teacher={teacher} />
          );
        })}
        {allCoursesData?.data?.length === 0 && <p>There are No Courses Yet.</p>}
      </div>
    </section>
  );
}

export default StudentCourses;
