import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SessionCollapse = ({ unit, isLesson }) => {
  return (
    <button
      onClick={(e) => {
        if (e.target.parentElement) {
          e.target.parentElement.classList.toggle("collapse-session");
        }
      }}
      className={`session-title-wrapper w-full cursor-pointer border-y-2 px-4 flex justify-between items-center gap-5 h-16 ${
        isLesson ? "bg-accent" : "bg-delete"
      } text-white disabled:opacity-50 `}
    >
      <div className="icon_title flex justify-start gap-5 items-center font-semibold text-lg pointer-events-none">
        <FontAwesomeIcon
          className="chevron-icon transition-all duration-300 ease-in-out"
          icon={faChevronUp}
        />
        <p className="">{unit.name} </p>
      </div>
    </button>
  );
};

export default SessionCollapse;
