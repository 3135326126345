import { useEffect, useReducer, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../../../MainComponents/HelmetTags.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import OfferQuestionsTable from "./OfferQuestionsTable.jsx";

function DynamicCoursesForm({ refetch, setRefetch, stage, id }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    control,
  } = useForm({
    defaultValues: { courses: [{ course_id: "" }] },
    mode: "onTouched",
  });

  const { fields, append, remove } = useFieldArray({
    name: "courses",
    control,
  });

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddCoursesToOffer": {
        return {
          ...state,
          submitAddCoursesToOffer: action.payload,
        };
      }
      case "SetCoursesFilteredByStage": {
        return {
          ...state,
          coursesFilteredByStage: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddCoursesToOffer: {
      flag: "",
      dependency: false,
      data: {},
    },
    coursesFilteredByStage: [],
  });
  //!--------- add Courses To Offer -------

  const [AddCoursesToOfferSuccess, AddCoursesToOfferErrors, submitLoading] =
    useAxios(
      process.env.REACT_APP_ADD_COURSES_TO_OFFER_API,
      "POST",
      state.submitAddCoursesToOffer.flag,
      state.submitAddCoursesToOffer.dependency,
      state.submitAddCoursesToOffer.data,
      true
    );

  useEffect(() => {
    //!---add actions ----
    if (AddCoursesToOfferSuccess) {
      reset();
      setRefetch(!refetch);
    }
  }, [AddCoursesToOfferSuccess]);

  const onSubmitCoursesToOffer = (data) => {
    const finalData = data?.courses?.map((course) => ({
      offer_id: id,
      ...course,
    }));
    dispatch({
      type: "setSubmitAddCoursesToOffer",
      payload: {
        flag: "Quiz",
        dependency: !state.submitAddCoursesToOffer.dependency,
        data: { offer_id: id, courses: finalData },
      },
    });
  };
  //!--------- courses for the offer form

  const [AllCoursesSuccess] = useAxios(
    process.env.REACT_APP_ADMIN_COURSES_TAB_API,
    "GET",
    "GET"
  );

  useEffect(() => {
    if (AllCoursesSuccess) {
      let tempFiltered = AllCoursesSuccess?.data?.filter(
        (course) => course.stage === stage
      );

      dispatch({
        type: "SetCoursesFilteredByStage",
        payload: tempFiltered,
      });
    }
  }, [AllCoursesSuccess]);

  return (
    <form
      onSubmit={handleSubmit(onSubmitCoursesToOffer)}
      method="post"
      className="flex w-3/4 max-w-xl flex-col items-center  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50 my-14"
    >
      {fields.map((field, index) => {
        return (
          <div
            key={field.id}
            className="flex w-full items-start justify-center gap-10  "
          >
            {/**
             * //!-----------course
             */}
            <div
              className={`flex w-fit flex-col items-start justify-center gap-2  md:w-full `}
            >
              <label
                className="w-full truncate"
                htmlFor={`course_id_${index + 1}`}
              >
                Course{" "}
              </label>
              <select
                name={`course_id_${index + 1}`}
                id={`course_id_${index + 1}`}
                {...register(`courses.${index}.course_id`, {
                  required: {
                    value: true,
                    message: "course id is required",
                  },
                })}
              >
                {state.coursesFilteredByStage?.map((course) => (
                  <option key={course.key} value={course.key}>
                    {course.title}
                  </option>
                ))}
              </select>
              {errors?.courses?.[index]?.course_id && (
                <p className="text-xs text-red-500 ">
                  {errors?.courses?.[index]?.course_id.type === "required" &&
                    "This field is required."}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                AddCoursesToOfferErrors &&
                  AddCoursesToOfferErrors?.response?.data?.errors?.courses?.[
                    index
                  ]?.course_id && (
                    <p className=" text-xs text-red-500  ">
                      {
                        AddCoursesToOfferErrors?.response?.data?.errors
                          ?.courses?.[index]?.course_id[0]
                      }
                    </p>
                  )
              }
            </div>{" "}
            {/**
             * //!-----------delete
             */}
            <div className="delete-set my-auto">
              <button
                onClick={() => remove(index)}
                className={`  group mt-4 rounded-md pt-2 pb-1 px-[6px] duration-300 hover:bg-secondary hover:text-bg   active:scale-90 ${
                  index === 0 && "pointer-events-none opacity-0"
                }`}
              >
                <FontAwesomeIcon
                  className=" text-xl duration-300 group-hover:text-light group-active:scale-90"
                  icon={faTrash}
                />
              </button>
            </div>
          </div>
        );
      })}
      <button
        onClick={() => append({ course_id: "" })}
        className="ml-4 rounded-xl border-2 border-blue bg-blue px-2 py-1 font-semibold text-bg hover:bg-bg transition-all duration-300 hover:bg-light hover:text-blue   active:scale-90 "
      >
        Add Course
        <FontAwesomeIcon className="ml-2" icon={faPlus} />
      </button>
      {/** submit */}

      <button
        disabled={
          !isValid || (submitLoading && state.submitAddCoursesToOffer.flag)
        }
        className=" submit mt-6 w-full "
        type="submit"
      >
        {submitLoading && state.submitAddCoursesToOffer.flag ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          <p>Add courses</p>
        )}
      </button>
    </form>
  );
}

function AddEditOffer({ edit }) {
  const [values, setValues] = useState({});
  const [refetch, setRefetch] = useState(false);
  const navigate = useNavigate();
  const { offerID } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    values,
    mode: "onChange",
  });

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditOffer": {
        return {
          ...state,
          submitAddEditOffer: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditOffer: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_COURSES_OFFER_API
    : process.env.REACT_APP_ADMIN_ADD_COURSES_OFFER_API;
  //!--------- add edit Offer -------

  const [AddEditOfferSuccess, AddEditOfferErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditOffer.flag,
    state.submitAddEditOffer.dependency,
    state.submitAddEditOffer.data,
    true
  );

  useEffect(() => {
    //!---add actions ----
    if (AddEditOfferSuccess) {
      !edit && reset();
      edit ? window.location.reload() : navigate(-1);
    }
  }, [AddEditOfferSuccess]);

  const onSubmit = (data) => {
    const finalData = offerID
      ? {
          ...data,
          visibility: data?.visibility === true ? 1 : 0,
          id: offerID,
        }
      : data;

    dispatch({
      type: "setSubmitAddEditOffer",
      payload: {
        flag: "AddEditOffer",
        dependency: !state.submitAddEditOffer.dependency,
        data: finalData,
      },
    });
  };

  //!--------- get offer info for checking if it has set previously  -------

  const [getOfferInfoSuccess] = useAxios(
    `${process.env.REACT_APP_ADMIN_COURSES_OFFER_INFO_API}${offerID}`,
    "GET",
    offerID,
    refetch
  );

  useEffect(() => {
    if (getOfferInfoSuccess) {
      setValues({
        ...getOfferInfoSuccess?.data?.offer,
        visibility:
          getOfferInfoSuccess?.data?.offer?.visibility === 1 ? true : false,
      });
    }
  }, [getOfferInfoSuccess]);

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Offers | Mr.Ahmed Harouny"></HelmetTags>{" "}
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-10 text-center text-2xl font-semibold">
          {offerID ? "Edit Offer" : "Add Offer"}
        </h2>

        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          method="post"
          className="flex w-3/4 max-w-xl flex-col items-center  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
        >
          <div className="flex  w-full items-center justify-between gap-16 md:flex-col md:gap-10 ">
            {/** Name filed */}

            <div className="name w-1/2 flex flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="name">Name</label>
              <input
                className="signin-inputs    "
                type="text"
                id="name"
                placeholder="Name"
                name="name"
                autoComplete="on"
                {...register("name", {
                  required: true,
                  //pattern: /^[A-Za-z]+$/,
                  maxLength: 80,
                  minLength: 3,
                })}
              />
              {errors.name && (
                <p className=" pt-2 text-xs text-red-500  ">
                  {errors.name.type === "required" && "This field is required."}
                  {errors.name.type === "pattern" &&
                    "Only English letters are allowed."}
                  {errors.name.type === "maxLength" &&
                    "Allowed Name max length is 80."}
                  {errors.name.type === "minLength" &&
                    "Please enter at least 3 letters."}
                </p>
              )}

              {
                //!-------server errors -----

                AddEditOfferErrors &&
                  AddEditOfferErrors?.response?.data?.errors?.name && (
                    <p className=" pt-2 text-xs text-red-500  ">
                      {AddEditOfferErrors?.response?.data?.errors?.name[0]}
                    </p>
                  )
              }
            </div>
            {edit && (
              <div className=" visibilityy flex w-1/2 flex-col items-end justify-center gap-2 md:w-full ">
                <div className="flex w-full items-center justify-end md:justify-center gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="visibility"
                      name="visibility"
                      {...register("visibility", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="visibility"
                    >
                      Visibility
                    </label>
                  </div>
                </div>

                {errors.visibility && (
                  <p
                    className="text-xs text-red-500
 "
                  >
                    {errors.visibility.type === "required" &&
                      "This field is required."}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  AddEditOfferErrors &&
                    AddEditOfferErrors?.response?.data?.errors?.visibility && (
                      <p
                        className="text-xs text-red-500
  "
                      >
                        {
                          AddEditOfferErrors?.response?.data?.errors
                            ?.visibility[0]
                        }
                      </p>
                    )
                }
              </div>
            )}
          </div>
          <div className="flex  w-full items-center justify-between gap-16 md:flex-col md:gap-10 ">
            <div
              className={`flex w-1/2 flex-col items-start justify-center gap-2 md:order-2  md:w-full `}
            >
              <label htmlFor="stage">Stage</label>
              <select
                name="stage"
                id="stage"
                {...register("stage", { required: true })}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>

              {errors.stage && (
                <p className="pt-2 text-xs text-red-500 ">
                  {errors.stage.type === "required" &&
                    "This field is required."}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                AddEditOfferErrors &&
                  AddEditOfferErrors?.response?.data?.errors?.stage && (
                    <p className=" pt-2 text-xs text-red-500  ">
                      {AddEditOfferErrors?.response?.data?.errors?.stage[0]}
                    </p>
                  )
              }
            </div>

            <div className="  flex w-1/2  flex-col items-start justify-center gap-2 md:w-full">
              <label htmlFor="price">Price</label>
              <input
                className="signin-inputs w-full "
                type="number"
                inputMode="numeric"
                min={1}
                id="price"
                placeholder="000"
                name="price"
                autoComplete="on"
                {...register("price", {
                  required: true,
                  valueAsNumber: true,
                  min: 1,
                })}
              />

              {errors.price && (
                <p className="pt-2 text-xs text-red-500 ">
                  {errors.price.type === "required" &&
                    "This field is required."}{" "}
                  {errors.price.type === "min" && "Minimum Number is 1."}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                AddEditOfferErrors &&
                  AddEditOfferErrors?.response?.data?.errors?.price && (
                    <p className=" pt-2 text-xs text-red-500  ">
                      {AddEditOfferErrors?.response?.data?.errors?.price[0]}
                    </p>
                  )
              }
            </div>
          </div>

          {/** submit */}

          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditOffer.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditOffer.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>{offerID ? "Confirm Editing The Offer" : "Add The Offer"}</p>
            )}
          </button>
        </form>

        {edit && (
          <DynamicCoursesForm
            stage={getOfferInfoSuccess?.data?.offer?.stage}
            id={getOfferInfoSuccess?.data?.offer?.key}
            refetch={refetch}
            setRefetch={setRefetch}
          />
        )}

        {edit && getOfferInfoSuccess?.data?.courses_offer?.length > 0 && (
          <div className=" flex w-full flex-col items-center gap-2 md:items-center px-10">
            <h2 className="text-center text-xl font-semibold">
              All Courses Information{" "}
            </h2>
            {/*
             */}
            <OfferQuestionsTable
              data={getOfferInfoSuccess?.data?.courses_offer}
              refetch={refetch}
              setRefetch={setRefetch}
            />
          </div>
        )}
      </div>
    </section>
  );
}

export default AddEditOffer;
