import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { QuestionBankFilter } from "./QuestionBankFilter";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useReducer, useState } from "react";
import { Checkbox } from "antd";
import Loader from "../../../../MainComponents/Loader";

const QuestionBankExamQuestion = () => {
  const AuthState = useAuthState();
  const { examID } = useParams();
  const [allSelectedQuestion, setAllSelectedQuestion] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const [ExamInfo, ExamInfoErrors, ExamInfoLoading] = useAxios(
    `${process.env.REACT_APP_GET_ALL_EXAMS_QUESTION_BANK_API}/${examID}`,
    "GET",
    examID,
    examID
  );

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditSession": {
        return {
          ...state,
          submitAddEditSession: action.payload,
        };
      }
      case "setSubmitAddFilteredQuestionsSession": {
        return {
          ...state,
          submitAddFilterdQuestionsSession: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditSession: {
      flag: "",
      dependency: false,
      data: {},
    },
    submitAddFilterdQuestionsSession: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  //   save filtered questions
  const [
    AddFilteredQuestionsSuccess,
    AddFilteredQuestionsErrors,
    AddFilteredQuestionsLoading,
  ] = useAxios(
    `${process.env.REACT_APP_ADD_EXAM_FILTERED_QUESTIONS_QUESTION_BANK_API}/${examID}`,
    "POST",
    state.submitAddFilterdQuestionsSession.flag,
    state.submitAddFilterdQuestionsSession.dependency,
    state.submitAddFilterdQuestionsSession.data,
    true
  );

  //   add filter
  const [AddFilterSuccess, AddFilterErrors, AddFilterLoading] = useAxios(
    `${process.env.REACT_APP_ADD_FILTERED_QUESTIONS_QUESTION_BANK_API}/${examID}`,
    "POST",
    state.submitAddEditSession.flag,
    state.submitAddEditSession.dependency,
    state.submitAddEditSession.data,
    false
  );

  const handleSelectQuestion = (e, id) => {
    if (e.target.checked) {
      setAllSelectedQuestion((questions) => [
        ...questions,
        { id, random_answers: 1 },
      ]);
    } else {
      setAllSelectedQuestion((questions) => [
        ...questions.filter((currentQuestion) => currentQuestion.id !== id),
      ]);
    }
  };

  const handleSelectRandomAnswers = (e, id) => {
    setAllSelectedQuestion((questions) => [
      ...questions.filter((question) => question?.id !== id),
      {
        id: allSelectedQuestion.find((curr) => curr?.id === id).id,
        random_answers: e.target.checked ? 1 : 0,
      },
    ]);
  };

  const handleSelectAll = () => {
    setSelectAllChecked(!selectAllChecked);

    if (!selectAllChecked) {
      // If "Select All" is checked, select all questions
      const allQuestionIds = AddFilterSuccess.data.map((question) => ({
        id: question.key,
      }));
      setAllSelectedQuestion(allQuestionIds);
    } else {
      // If "Deselect All" is checked, deselect all questions
      setAllSelectedQuestion([]);
    }
  };

  const handleSaveSelectedQuestions = () => {
    const finalData = {
      questions: allSelectedQuestion,
    };

    dispatch({
      type: "setSubmitAddFilteredQuestionsSession",
      payload: {
        flag: "submitAddFilteredQuestionsSession",
        dependency: !state.submitAddFilterdQuestionsSession.dependency,
        data: finalData,
      },
    });
  };

  if (ExamInfoLoading) {
    return <Loader />;
  }
  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      <HelmetTags title="Exam Question | Mr.Ahmed Harouny" />
      <div className="w-full flex justify-between items-center gap-5 mb-7">
        <h2 className=" text-start text-2xl font-bold ">
          Exam: {ExamInfo?.data?.name}
        </h2>
      </div>

      <div className="flex items-center gap-5">
        <Link
          to={`/admin/question-bank/${examID}/all-current-questions`}
          className="text-white mb-8 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
        >
          All Current Questions
        </Link>
        {/* {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("student_add")
        )) && ( */}
        {/* <Link
          to={`/admin/question-bank/${examID}/add-fixed-question`}
          className="text-white mb-8 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
        >
          <FontAwesomeIcon className="mr-4" icon={faPlus} />
          Add Fixed Question
        </Link> */}
        {/* )} */}
      </div>

      <div className="w-full">
        <QuestionBankFilter
          state={state}
          dispatch={dispatch}
          AddFilterSuccess={AddFilterSuccess}
          AddFilterErrors={AddFilterErrors}
          AddFilterLoading={AddFilterLoading}
        />
      </div>

      {AddFilterSuccess?.data?.length > 0 && (
        <div className="w-full flex items-center justify-end gap-2  mt-8">
          <button
            onClick={handleSelectAll}
            className="text-white mb-8 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
          >
            {selectAllChecked ? "Deselect All" : "Select All"}
          </button>
          {allSelectedQuestion.length > 0 && (
            <button
              onClick={handleSaveSelectedQuestions}
              disabled={AddFilteredQuestionsLoading}
              className="text-white mb-8 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
            >
              Save
            </button>
          )}
        </div>
      )}

      {AddFilterSuccess?.data?.map((question, index) => (
        <div
          key={index}
          className="singleQuestionWrapper mt-8 rounded-3xl w-full bg-white"
        >
          <div className="optionsQuestionWrapper justify-between">
            <div className="flex items-center gap-3 flex-col">
              <Checkbox
                onChange={(e) => handleSelectQuestion(e, question.key)}
                checked={
                  allSelectedQuestion.find(
                    (currentQuestion) => currentQuestion.id === question.key
                  )
                    ? true
                    : false
                }
              >
                Select Question
              </Checkbox>
              <Checkbox
                onChange={(e) => handleSelectRandomAnswers(e, question.key)}
                defaultChecked={true}
                disabled={allSelectedQuestion.length === 0}
              >
                Random Answers
              </Checkbox>
            </div>
            <div className="categoryQuestionWrapper !w-3/4 !md:w-full">
              {question.question ? (
                <p
                  className="essayTitleWrapper"
                  dangerouslySetInnerHTML={{
                    __html: question.question,
                  }}
                />
              ) : (
                <a href={question.URL}>{question.filename}</a>
              )}
            </div>
          </div>
          {question.is_essay ? (
            ""
          ) : (
            <div className="categoryAnswersWrapper">
              <div
                className={`categorySingleAnswer answer1Wrapper ${
                  question.correct_answer === "answer1" && "correctSingleAnswer"
                }`}
              >
                <p>Answer A</p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: question.answer1,
                  }}
                />
              </div>
              <div
                className={`categorySingleAnswer answer2Wrapper ${
                  question.correct_answer === "answer2" && "correctSingleAnswer"
                }`}
              >
                <p>Answer B</p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: question.answer2,
                  }}
                />
              </div>
              <div
                className={`categorySingleAnswer answer3Wrapper ${
                  question.correct_answer === "answer3" && "correctSingleAnswer"
                }`}
              >
                <p>Answer C</p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: question.answer3,
                  }}
                />
              </div>
              <div
                className={`categorySingleAnswer answer4Wrapper ${
                  question.correct_answer === "answer4" && "correctSingleAnswer"
                }`}
              >
                <p>Answer D</p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: question.answer4,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      ))}
    </section>
  );
};

export default QuestionBankExamQuestion;
