import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Link, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import Loader from "../../../../MainComponents/Loader.jsx";
import { Popconfirm } from "antd";
import { useEffect, useState } from "react";

const QuestionBankExamAllCurrentQuestion = () => {
  const { examID } = useParams();
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [reFetch, setReFetch] = useState(false);

  //!--------- delete Question -------
  const [deleteQuestionSuccess] = useAxios(
    process.env.REACT_APP_DELETE_CURRETN_QUESTIONS_QUESTION_BANK_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );

  function handleDelete(id, type) {
    setSubmitDelete({
      flag: "deleteQuestion",
      dependency: !submitDelete.dependency,
      data: { id, type, exam_id: examID },
    });
  }

  const [
    AllCurrentQuestionsSucsses,
    AllCurrentQuestionsErrors,
    AllCurrentQuestionsLoading,
  ] = useAxios(
    `${process.env.REACT_APP_GET_ALL_CURRETN_QUESTIONS_QUESTION_BANK_API}/${examID}`,
    "GET",
    "GET",
    deleteQuestionSuccess
  );

  if (AllCurrentQuestionsLoading) {
    return <Loader />;
  }

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      <HelmetTags title="All Current Questions | Mr.Ahmed Harouny" />
      <div className="w-full flex justify-between items-center gap-5 mb-7">
        <h2 className=" text-start text-2xl font-bold ">
          All Current Questions
        </h2>
      </div>

      {AllCurrentQuestionsSucsses?.data?.map((question, index) => (
        <div
          key={index}
          className="singleQuestionWrapper mt-8 rounded-3xl w-full"
        >
          <div className="optionsQuestionWrapper">
            <div className="questionOptions">
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#ffffff" //okButtonProps={{ loading: loading }}
                onConfirm={() => handleDelete(question.key, question.type)}
                title="Delete The Question?"
              >
                <button className="deleteQuestionBtn">
                  {question.type === "fixed" ? "Delete" : "Remove"}
                </button>
              </Popconfirm>
              <Link
                to={`/admin/question-bank/edit-choose-question/${question.key}`}
                className="editQuestionBtn"
              >
                Edit
              </Link>
            </div>
            <div className="categoryQuestionWrapper">
              {question.question ? (
                <p
                  className="essayTitleWrapper"
                  dangerouslySetInnerHTML={{
                    __html: question.question,
                  }}
                />
              ) : (
                <a href={question.URL}>{question.filename}</a>
              )}
            </div>
          </div>
          {question.is_essay ? (
            ""
          ) : (
            <div className="categoryAnswersWrapper">
              <div
                className={`categorySingleAnswer answer1Wrapper ${
                  question.correct_answer === "answer1" && "correctSingleAnswer"
                }`}
              >
                <p>Answer 1</p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: question.answer1,
                  }}
                />
              </div>
              <div
                className={`categorySingleAnswer answer2Wrapper ${
                  question.correct_answer === "answer2" && "correctSingleAnswer"
                }`}
              >
                <p>Answer 2</p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: question.answer2,
                  }}
                />
              </div>
              <div
                className={`categorySingleAnswer answer3Wrapper ${
                  question.correct_answer === "answer3" && "correctSingleAnswer"
                }`}
              >
                <p>Answer 3</p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: question.answer3,
                  }}
                />
              </div>
              <div
                className={`categorySingleAnswer answer4Wrapper ${
                  question.correct_answer === "answer4" && "correctSingleAnswer"
                }`}
              >
                <p>Answer 4</p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: question.answer4,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      ))}
      {AllCurrentQuestionsSucsses?.data.length === 0 && (
        <p className="w-full text-center text-lg pt-6">
          There is no questions yet!!
        </p>
      )}
    </section>
  );
};

export default QuestionBankExamAllCurrentQuestion;
