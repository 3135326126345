import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox } from "antd";

export const QuestionBankAddExam = ({ edit }) => {
  const [values, setValues] = useState(null);
  const [enableDuration, setEnableDuration] = useState(false);
  const [randomQuestions, setRandomQuestions] = useState(false);
  const { examID } = useParams();
  const navigate = useNavigate();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditSession": {
        return {
          ...state,
          submitAddEditSession: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditSession: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_EDIT_EXAM_QUESTION_BANK_API
    : process.env.REACT_APP_ADD_EXAM_QUESTION_BANK_API;

  //!--------- add edit Course -------
  const [ExamAddEditSuccess, ExamAddEditErrors, ExamAddEditLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditSession.flag,
    state.submitAddEditSession.dependency,
    state.submitAddEditSession.data,
    true
  );

  //!--------- get the Course info for editing -------

  const [ExamInfo, ExamInfoErrors] = useAxios(
    `${process.env.REACT_APP_GET_ALL_EXAMS_QUESTION_BANK_API}/${examID}`,
    "GET",
    examID,
    examID
  );

  useEffect(() => {
    if (ExamInfo) {
      let temp = ExamInfo.data;
      setValues({ ...temp });
      if (temp.duration) {
        setEnableDuration(true);
      }
    }
  }, [ExamInfo]);

  useEffect(() => {
    //!---add actions ----

    if (ExamAddEditSuccess) {
      navigate(-1);
      reset();
    }
  }, [ExamAddEditSuccess]);

  const defaultValues = ExamInfo?.data;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
    values: defaultValues,
  });

  const onSubmit = (data) => {
    const finalData = {
      ...data,
      id: examID,
      duration: enableDuration ? data.duration : null,
      random_questions: randomQuestions ? 1 : 0,
    };

    dispatch({
      type: "setSubmitAddEditSession",
      payload: {
        flag: "AddEditSession",
        dependency: !state.submitAddEditSession.dependency,
        data: finalData,
      },
    });
  };

  const handleChangeDuration = (e) => {
    setEnableDuration(e.target.checked);
  };

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      <HelmetTags
        title={`${edit ? "Edit Exam" : "Add Exam"} | Mr.Ahmed Harouny`}
      />
      <div className="w-full flex justify-between items-center gap-5 mb-7">
        <h2 className=" text-start text-2xl font-bold ">
          {edit ? "Edit Exam" : "Add Exam"}
        </h2>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
        method="post"
        className="flex w-3/4 mx-auto flex-col items-start  justify-center gap-4 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
      >
        {/** Name filed */}
        <div className="w-full flex items-center gap-2 flex-col">
          <div
            className={`mb-5 flex w-full md:w-full flex-col items-start justify-center gap-2  md:w-full `}
          >
            <label className="w-full truncate" htmlFor="name">
              Exam Name
            </label>
            <input
              className="signin-inputs  w-full "
              type="text"
              id="name"
              placeholder="Exam Name"
              name="name"
              autoComplete="on"
              {...register("name", {
                required: true,
              })}
            />
            {errors.name && (
              <p className="text-xs text-red-500">
                {errors.name.type === "required" && "This field is required."}
                {errors.name.type === "pattern" &&
                  " Only Arabic letters are allowed (Spaces not Allowed)."}
                {errors.name.type === "maxLength" &&
                  "Available name max length is 25."}
                {errors.name.type === "minLength" &&
                  " Please enter at least 3 letters."}
              </p>
            )}
            {
              //!-------Editing server errors -----

              ExamAddEditErrors?.response?.data?.errors &&
                ExamAddEditErrors?.response?.data?.errors?.name_arabic && (
                  <p className="text-xs text-red-500">
                    {ExamAddEditErrors?.response?.data.errors.name_arabic[0]}
                  </p>
                )
            }
          </div>
          <div
            className={`mb-5 flex w-full md:w-full flex-col items-start justify-center gap-2  md:w-full `}
          >
            <label className="w-full truncate" htmlFor="Duration">
              Duration
            </label>
            <input
              className="signin-inputs  w-full disabled:opacity-50"
              type="number"
              id="duration"
              placeholder="Duration"
              name="duration"
              autoComplete="on"
              disabled={!enableDuration}
              {...register("duration", {
                required: false,
              })}
            />
            {errors.duration && (
              <p className="text-xs text-red-500">
                {errors.duration.type === "required" &&
                  "This field is required."}
              </p>
            )}
            {
              //!-------Editing server errors -----

              ExamAddEditErrors?.response?.data?.errors &&
                ExamAddEditErrors?.response?.data?.errors?.duration && (
                  <p className="text-xs text-red-500">
                    {ExamAddEditErrors?.response?.data.errors.duration[0]}
                  </p>
                )
            }
          </div>
          <div className="w-full flex items-center gap-5 ">
            <div
              className={`mb-5 flex w-full md:w-full flex-col items-start justify-center gap-2  md:w-1/3 `}
            >
              <label className="w-full truncate" htmlFor="grade1">
                Grade 1
              </label>
              <input
                className="signin-inputs  w-full "
                type="number"
                id="grade1"
                placeholder="grade1"
                name="grade1"
                autoComplete="on"
                {...register("grade1", {
                  required: false,
                  min: 1,
                })}
              />
              {errors.grade1 && (
                <p className="text-xs text-red-500">
                  {errors.grade1.type === "required" &&
                    "This field is required."}
                </p>
              )}
              {errors.grade1 && errors.grade1.type === "min" && (
                <p className="text-xs text-red-500">Grade must be at least 1</p>
              )}
              {
                //!-------Editing server errors -----

                ExamAddEditErrors?.response?.data?.errors &&
                  ExamAddEditErrors?.response?.data?.errors?.grade1 && (
                    <p className="text-xs text-red-500">
                      {ExamAddEditErrors?.response?.data.errors.grade1[0]}
                    </p>
                  )
              }
            </div>
            <div
              className={`mb-5 flex w-full md:w-full flex-col items-start justify-center gap-2  md:w-1/3 `}
            >
              <label className="w-full truncate" htmlFor="grade2">
                Grade 2
              </label>
              <input
                className="signin-inputs  w-full "
                type="number"
                id="grade2"
                placeholder="grade2"
                name="grade2"
                autoComplete="on"
                {...register("grade2", {
                  required: false,
                  min: 1,
                })}
              />
              {errors.grade2 && (
                <p className="text-xs text-red-500">
                  {errors.grade2.type === "required" &&
                    "This field is required."}
                </p>
              )}
              {errors.grade2 && errors.grade2.type === "min" && (
                <p className="text-xs text-red-500">Grade must be at least 1</p>
              )}
              {
                //!-------Editing server errors -----

                ExamAddEditErrors?.response?.data?.errors &&
                  ExamAddEditErrors?.response?.data?.errors?.grade2 && (
                    <p className="text-xs text-red-500">
                      {ExamAddEditErrors?.response?.data.errors.grade2[0]}
                    </p>
                  )
              }
            </div>
            <div
              className={`mb-5 flex w-full md:w-full flex-col items-start justify-center gap-2  md:w-1/3 `}
            >
              <label className="w-full truncate" htmlFor="grade3">
                Grade 3
              </label>
              <input
                className="signin-inputs  w-full "
                type="number"
                id="grade3"
                placeholder="grade3"
                name="grade3"
                autoComplete="on"
                {...register("grade3", {
                  required: false,
                  min: 1,
                })}
              />
              {errors.grade3 && (
                <p className="text-xs text-red-500">
                  {errors.grade3.type === "required" &&
                    "This field is required."}
                </p>
              )}
              {errors.grade3 && errors.grade3.type === "min" && (
                <p className="text-xs text-red-500">Grade must be at least 1</p>
              )}
              {
                //!-------Editing server errors -----

                ExamAddEditErrors?.response?.data?.errors &&
                  ExamAddEditErrors?.response?.data?.errors?.grade3 && (
                    <p className="text-xs text-red-500">
                      {ExamAddEditErrors?.response?.data.errors.grade3[0]}
                    </p>
                  )
              }
            </div>
          </div>
          <div className="w-full flex items-center gap-5 ">
            <div
              className={`mb-5 flex w-full md:w-full flex-col items-start justify-center gap-2  md:w-1/3 `}
            >
              <label className="w-full truncate" htmlFor="grade4">
                Grade 4
              </label>
              <input
                className="signin-inputs  w-full "
                type="number"
                id="grade4"
                placeholder="grade4"
                name="grade4"
                autoComplete="on"
                {...register("grade4", {
                  required: false,
                  min: 1,
                })}
              />
              {errors.grade4 && (
                <p className="text-xs text-red-500">
                  {errors.grade4.type === "required" &&
                    "This field is required."}
                </p>
              )}
              {errors.grade4 && errors.grade4.type === "min" && (
                <p className="text-xs text-red-500">Grade must be at least 1</p>
              )}
              {
                //!-------Editing server errors -----

                ExamAddEditErrors?.response?.data?.errors &&
                  ExamAddEditErrors?.response?.data?.errors?.grade4 && (
                    <p className="text-xs text-red-500">
                      {ExamAddEditErrors?.response?.data.errors.grade4[0]}
                    </p>
                  )
              }
            </div>
            <div
              className={`mb-5 flex w-full md:w-full flex-col items-start justify-center gap-2  md:w-1/3 `}
            >
              <label className="w-full truncate" htmlFor="grade5">
                Grade 5
              </label>
              <input
                className="signin-inputs  w-full "
                type="number"
                id="grade5"
                placeholder="grade5"
                name="grade5"
                autoComplete="on"
                {...register("grade5", {
                  required: false,
                  min: 1,
                })}
              />
              {errors.grade5 && (
                <p className="text-xs text-red-500">
                  {errors.grade5.type === "required" &&
                    "This field is required."}
                </p>
              )}
              {errors.grade5 && errors.grade5.type === "min" && (
                <p className="text-xs text-red-500">Grade must be at least 1</p>
              )}
              {
                //!-------Editing server errors -----

                ExamAddEditErrors?.response?.data?.errors &&
                  ExamAddEditErrors?.response?.data?.errors?.grade5 && (
                    <p className="text-xs text-red-500">
                      {ExamAddEditErrors?.response?.data.errors.grade5[0]}
                    </p>
                  )
              }
            </div>
            <div
              className={`mb-5 flex w-full md:w-full flex-col items-start justify-center gap-2  md:w-1/3 `}
            >
              <label className="w-full truncate" htmlFor="grade6">
                Grade 6
              </label>
              <input
                className="signin-inputs  w-full "
                type="number"
                id="grade6"
                placeholder="grade6"
                name="grade6"
                autoComplete="on"
                {...register("grade6", {
                  required: false,
                  min: 1,
                })}
              />
              {errors.grade6 && (
                <p className="text-xs text-red-500">
                  {errors.grade6.type === "required" &&
                    "This field is required."}
                </p>
              )}
              {errors.grade6 && errors.grade6.type === "min" && (
                <p className="text-xs text-red-500">Grade must be at least 1</p>
              )}
              {
                //!-------Editing server errors -----

                ExamAddEditErrors?.response?.data?.errors &&
                  ExamAddEditErrors?.response?.data?.errors?.grade6 && (
                    <p className="text-xs text-red-500">
                      {ExamAddEditErrors?.response?.data.errors.grade6[0]}
                    </p>
                  )
              }
            </div>
          </div>
          <div className="w-full flex items-center gap-5 ">
            <div
              className={`mb-5 flex w-full md:w-full flex-col items-start justify-center gap-2  md:w-1/3 `}
            >
              <label className="w-full truncate" htmlFor="grade7">
                Grade 7
              </label>
              <input
                className="signin-inputs  w-full "
                type="number"
                id="grade7"
                placeholder="grade7"
                name="grade7"
                autoComplete="on"
                {...register("grade7", {
                  required: false,
                  min: 1,
                })}
              />
              {errors.grade7 && (
                <p className="text-xs text-red-500">
                  {errors.grade7.type === "required" &&
                    "This field is required."}
                </p>
              )}
              {errors.grade7 && errors.grade7.type === "min" && (
                <p className="text-xs text-red-500">Grade must be at least 1</p>
              )}
              {
                //!-------Editing server errors -----

                ExamAddEditErrors?.response?.data?.errors &&
                  ExamAddEditErrors?.response?.data?.errors?.grade7 && (
                    <p className="text-xs text-red-500">
                      {ExamAddEditErrors?.response?.data.errors.grade7[0]}
                    </p>
                  )
              }
            </div>
            <div
              className={`mb-5 flex w-full md:w-full flex-col items-start justify-center gap-2  md:w-1/3 `}
            >
              <label className="w-full truncate" htmlFor="grade8">
                Grade 8
              </label>
              <input
                className="signin-inputs  w-full "
                type="number"
                id="grade8"
                placeholder="grade8"
                name="grade8"
                autoComplete="on"
                {...register("grade8", {
                  required: false,
                  min: 8,
                })}
              />
              {errors.grade8 && (
                <p className="text-xs text-red-500">
                  {errors.grade8.type === "required" &&
                    "This field is required."}
                </p>
              )}
              {errors.grade8 && errors.grade8.type === "min" && (
                <p className="text-xs text-red-500">Grade must be at least 1</p>
              )}
              {
                //!-------Editing server errors -----

                ExamAddEditErrors?.response?.data?.errors &&
                  ExamAddEditErrors?.response?.data?.errors?.grade8 && (
                    <p className="text-xs text-red-500">
                      {ExamAddEditErrors?.response?.data.errors.grade8[0]}
                    </p>
                  )
              }
            </div>
            <div
              className={`mb-5 flex w-full md:w-full flex-col items-start justify-center gap-2  md:w-1/3 `}
            >
              <label className="w-full truncate" htmlFor="grade9">
                Grade 9
              </label>
              <input
                className="signin-inputs  w-full "
                type="number"
                id="grade9"
                placeholder="grade9"
                name="grade9"
                autoComplete="on"
                {...register("grade9", {
                  required: false,
                  min: 1,
                })}
              />
              {errors.grade9 && (
                <p className="text-xs text-red-500">
                  {errors.grade9.type === "required" &&
                    "This field is required."}
                </p>
              )}
              {errors.grade9 && errors.grade9.type === "min" && (
                <p className="text-xs text-red-500">Grade must be at least 1</p>
              )}
              {
                //!-------Editing server errors -----

                ExamAddEditErrors?.response?.data?.errors &&
                  ExamAddEditErrors?.response?.data?.errors?.grade9 && (
                    <p className="text-xs text-red-500">
                      {ExamAddEditErrors?.response?.data.errors.grade9[0]}
                    </p>
                  )
              }
            </div>
          </div>
          <div
            className={`mb-5 flex w-full md:w-full flex-col items-start justify-center gap-2  md:w-full `}
          >
            {ExamInfo?.data && (
              <Checkbox
                defaultChecked={ExamInfo?.data.duration}
                onChange={handleChangeDuration}
              >
                Enable Duration
              </Checkbox>
            )}

            {!edit && (
              <Checkbox
                defaultChecked={ExamInfo?.data.duration}
                onChange={handleChangeDuration}
              >
                Enable Duration
              </Checkbox>
            )}
          </div>
          <div
            className={`mb-5 flex w-full md:w-full flex-col items-start justify-center gap-2  md:w-full `}
          >
            {ExamInfo?.data && (
              <Checkbox
                defaultChecked={ExamInfo?.data.random_questions}
                onChange={(e) => setRandomQuestions(e.target.checked)}
              >
                Random questions
              </Checkbox>
            )}
            {!edit && (
              <Checkbox onChange={(e) => setRandomQuestions(e.target.checked)}>
                Random questions
              </Checkbox>
            )}
          </div>
        </div>

        {/** submit */}

        <button
          disabled={
            !isValid || (ExamAddEditLoading && state.submitAddEditSession.flag)
          }
          className=" submit mt-6 w-full "
          type="submit"
        >
          {ExamAddEditLoading && state.submitAddEditSession.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <p> {edit ? "Edit Exam" : "Add Exam"}</p>
          )}
        </button>
      </form>
    </section>
  );
};

export default QuestionBankAddExam;
