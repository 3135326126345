import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";

function DeletedStudents() {
  const table = useTable("deletedStudents");

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title="DeletedStudents | Mr.Ahmed Harouny"></HelmetTags>{" "}
      <h2 className="mb-7 text-2xl font-bold">Deleted Students</h2>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default DeletedStudents;
