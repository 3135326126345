import DropDownMenu from "./DropDownMenu";
import { NavLink, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuthState } from "./GlobalContext.jsx";

function Navbar() {
  const AuthState = useAuthState();
  const [scroll, setScroll] = useState(false);

  const activeLink = ({ isActive }) => ({
    background: isActive
      ? "linear-gradient(94deg, rgba(3, 65, 221,1) 0%, rgba(3, 65, 221,1) 100%)"
      : "",
    backgroundSize: isActive ? "100% 3px" : "",
    backgroundRepeat: isActive ? "no-repeat" : "",
    backgroundPosition: isActive ? "left bottom" : "",
  });

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 40) {
        setScroll(true);
      } else setScroll(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={`fixed w-full h-20 flex items-center z-50 `}>
      <nav
        className={`navWidth  duration-500 flex justify-between items-center   ${
          scroll ? "bg-delete rounded-full translate-y-3 " : "bg-yellow/0"
        }`}
      >
        <Link
          className="cursor-pointer flex justify-center items-center "
          to="/"
        >
          <img
            //!logo
            className="h-[55px] w-auto "
            src="https://api.sciencetoonz.com/uploads/ST%20Transparent.png"
            alt="logo"
          />
        </Link>

        <div
          //!desktop menu items
          className="md:hidden"
        >
          <ul className="  text-lg flex justify-between items-center  lg:gap-7 gap-12 font-semibold  ">
            {AuthState.userData?.student_token && (
              <>
                <li>
                  <NavLink
                    className={`underline-gradient ${scroll && "text-white"}`}
                    to="/my-courses"
                    style={activeLink}
                  >
                    My Courses
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`underline-gradient ${scroll && "text-white"}`}
                    to="/all-courses"
                    style={activeLink}
                  >
                    All Courses
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>

        {AuthState.userData?.student_token ? (
          <DropDownMenu scroll={scroll} />
        ) : (
          <>
            <div className="flex justify-center items-center gap-4 ">
              <NavLink to="/about-us" className={`${scroll && "text-white"}`}>
                About Us{" "}
              </NavLink>
              <Link
                to="/signin"
                className="sign sm:text-base sm:w-24 sm:h-10 lg:text-base lg:w-24 lg:h-10"
              >
                Sign In{" "}
              </Link>
            </div>
          </>
        )}
      </nav>
    </div>
  );
}

export default Navbar;
